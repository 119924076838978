import React, { useEffect, createContext, useContext } from "react"
import { Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { FavoritesRoute, PrimaryRoutes, ShareRoute } from "dataset/routes"
import ThemeProvider from "./theme/ThemeProvider"
import Nav from "components/Nav"
import { useStore } from "state/store"
import useScreenSaver from "utils/hooks/useScreenSaver"
import { useQuery } from "react-query"
import { getApartments } from "services/requests"
import apartmentsFn from "utils/apartmentsFn"
import { useErrorContext } from "errors/ErrorProvider"
import useDidMountEffect from "throp/useDidMountEffect"
import { Preferences } from "@capacitor/preferences"

import { Globals } from "@react-spring/shared"

// Globals.assign({
//   frameLoop: "always",
// })

export default function App() {
  const { data, isLoading, error, isError } = useQuery("apartments", getApartments)
  const { setError } = useErrorContext()

  const { setApartments, setMinPrice, setMaxPrice, setPriceRange } = useStore(state => ({
    setApartments: state.setApartments,
    setMinPrice: state.setMinPrice,
    setMaxPrice: state.setMaxPrice,
    setPriceRange: state.setPriceRange,
    apartments: state.apartments,
  }))

  useDidMountEffect(() => {
    if (isError) {
      setError(error)
    }
  }, [isError, setError, error])

  const saveApartmentsToLocal = async apartments => {
    localStorage.setItem("nineElmsApartments", JSON.stringify(apartments))

    await Preferences.set({
      key: "nineElmsApartments",
      value: JSON.stringify(apartments),
    })
  }

  const fetchApartmentsFromLocal = async () => {
    try {
      const { value } = await Preferences.get({ key: "nineElmsApartments" })

      const apartments = JSON.parse(value)
      console.log("FROM LOCAL", apartments)

      if (apartments) {
        apartmentsFn.setPrices(apartments, setMinPrice, setMaxPrice, setPriceRange)
        setApartments(apartments)
      } else {
        console.log("No apartments found in local storage")
      }
    } catch (err) {
      console.error("Error fetching apartments from local storage:", err)
    }
  }

  const processFetchedApartments = async apartments => {
    if (apartments) {
      apartmentsFn.setPrices(apartments, setMinPrice, setMaxPrice, setPriceRange)
      setApartments(apartments)
      saveApartmentsToLocal(apartments)
    }
  }

  useEffect(() => {
    if (isLoading) return

    async function handleAsyncData() {
      if (data) {
        processFetchedApartments(data.allApartments)
      } else {
        await fetchApartmentsFromLocal()
      }
    }

    handleAsyncData()
  }, [data, isLoading])

  useScreenSaver(1000 * 60 * 20)

  return (
    <ThemeProvider>
      <Nav />
      <Routes>
        <Route path={FavoritesRoute.path} element={FavoritesRoute.element} />
        <Route path={ShareRoute.path} element={ShareRoute.element} />
        {PrimaryRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </ThemeProvider>
  )
}
