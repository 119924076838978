import React from "react"

import { useTexture } from "@react-three/drei"
import { to, useSpring, useTransition } from "@react-spring/core"
import { a } from "@react-spring/three"
import { useRef } from "react"

import Concierge from "./Assets/concierge.glb"
import Outside from "./Assets/outside.glb"
import Residence from "./Assets/residence.glb"
import Amenities from "./Assets/amenities.glb"
import Culinary from "./Assets/culinary.glb"
import Culture from "./Assets/culture.glb"
import Retail from "./Assets/retail.glb"

import { galleryIndex } from "dataset/gallery3D"
import { useStore } from "state/store"
import { Vector3 } from "three"
import { useGLTF } from "utils/hooks/useGLTF"
import { useEffect } from "react"
import { useState } from "react"
import { sortBy } from "lodash"

let temp = {
  0: {
    0: Concierge,
    1: Outside,
    2: Residence,
  },
  1: {
    0: Amenities,
    1: Culinary,
    2: Culture,
    3: Retail,
  },
}

const tabNameIndex = {
  0: "nineelms",
  1: "lifestyle",
}

//https://discourse.threejs.org/t/how-to-improve-texture-first-render-performance/10436

function GalleryMesh() {
  const tabObj = useStore(s => s.tabObj)

  const { nodes } = useGLTF(temp[tabObj.first][tabObj.second])
  const nodesArr = Object.values(nodes)
  let mapsList = Object.values(galleryIndex[tabNameIndex[tabObj.first]][tabObj.second])
  let maps = useTexture(mapsList)

  maps.map(m => (m.flipY = false))

  const [activeStuff, setActiveStuff] = useState()

  useEffect(() => {
    let holder = []

    sortBy(nodesArr, e => {
      return e.name.split("$")[2]
    })
    nodesArr.reverse().forEach((n, i) => holder.push({ node: n, map: maps[i] }))

    setActiveStuff(holder)
  }, [tabObj])

  const transition = useTransition(activeStuff, {
    from: { opacity: 0 },
    // enter: { opacity: 1 },
    enter: item => next =>
      next({ opacity: 1, delay: Number(item.node.name.split("$")[2] * 60 + 300) }),
    leave: item => next =>
      next({ opacity: 0, delay: Number(item.node.name.split("$")[2] * 60 + 100) }),
    // leave: { opacity: 0 },
  })

  return transition(({ opacity }, item) => (
    <group position={[0, 0, -0.1]} rotation={[Math.PI / 2, 0, 0]}>
      <MeshElement opacity={opacity} item={item} />
    </group>
  ))
}

function MeshElement({ item, opacity }) {
  let mesh = item.node
  let geom = mesh.geometry
  let map = item.map

  let boudningBox = geom.boundingBox
  let minX = new Vector3(boudningBox.min.x, 0, 0)
  let maxX = new Vector3(boudningBox.max.x, 0, 0)
  let minZ = new Vector3(0, 0, boudningBox.min.z)
  let maxZ = new Vector3(0, 0, boudningBox.max.z)

  const xDist = minX.distanceTo(maxX)
  const zDist = minZ.distanceTo(maxZ)

  let scaleFactor = xDist < zDist ? 0.37 : 0.5

  const activeImage = useStore(s => s.activeImage)
  const setActiveImage = useStore(s => s.setActiveImage)

  let sub = mesh.name.split("$")[1]
  let id = mesh.name.split("$")[2]

  let thisId = `${sub}${id}`

  const full = activeImage === thisId

  const dist = geom.boundingSphere.center
  const meshScale = (1 / geom.boundingSphere.radius) * scaleFactor

  const { scale, pos, opac } = useSpring({
    scale: full ? meshScale : 1,
    pos: full ? [dist.x * -1 * meshScale, 0.05, dist.z * -1 * meshScale - 0.01] : [0, 0.02, 0],
    // : active
    // ? [0, 0.02, 0]
    // : [0, 0, 0],
    opac: activeImage ? (full ? opacity : 0.1) : opacity,
  })

  return (
    <a.mesh
      scale={scale}
      position={pos}
      onClick={e => {
        e.stopPropagation()
        if (full) {
          setActiveImage(null)
        } else setActiveImage(thisId)
        // else active && setActiveImage(thisId)
      }}
      geometry={geom}
      rotation={[0, 0, 0]}>
      <a.meshBasicMaterial
        toneMapped={false}
        transparent={true}
        opacity={opac}
        // opacity={opac}
        //
        map={map}
        //
      />
    </a.mesh>
  )
}

export default GalleryMesh
