export const apartmentPolygons = {
  oneToSix: [
    {
      index: "01",
      points: [
        200, 61.400001525878906, 199.89999389648438, 170.1999969482422, 228.5, 186.39999389648438,
        369.20001220703125, 186.3000030517578, 398.1000061035156, 172, 398.1000061035156,
        61.400001525878906,
      ],
      textPos: {
        x: 275,
        y: 135,
      },
    },
    {
      index: "02",
      points: [
        383.8999938964844, 211.6999969482422, 541.5999755859375, 120.0999984741211,
        507.79998779296875, 61.400001525878906, 398.1000061035156, 61.400001525878906,
        398.1000061035156, 172, 369.20001220703125, 186.3000030517578,
      ],
      textPos: {
        x: 430,
        y: 135,
      },
    },
    {
      index: "04",
      points: [
        358.70001220703125, 304.5, 428.20001220703125, 343.6000061035156, 431.6000061035156,
        337.6000061035156, 470.6000061035156, 359.79998779296875, 522.7000122070312,
        270.20001220703125, 469.1000061035156, 239.8000030517578, 450.3999938964844, 272.5,
        395.20001220703125, 241.1999969482422,
      ],
      textPos: {
        x: 410,
        y: 310,
      },
    },
    {
      index: "05",
      points: [
        326.70001220703125, 360.20001220703125, 326.6000061035156, 391.79998779296875,
        421.1000061035156, 445.1000061035156, 470.6000061035156, 359.79998779296875,
        431.6000061035156, 337.6000061035156, 428.20001220703125, 343.6000061035156,
        358.70001220703125, 304.5,
      ],
      textPos: {
        x: 370,
        y: 380,
      },
    },
    {
      index: "08",
      points: [
        77.9000015258789, 272.8999938964844, 171.3000030517578, 219.8000030517578,
        199.3000030517578, 237, 262.79998779296875, 346.20001220703125, 155.60000610351562,
        407.6000061035156,
      ],
      textPos: {
        x: 130,
        y: 310,
      },
    },
    {
      index: "06",
      points: [
        297.5, 360.29998779296875, 326.6000061035156, 360.29998779296875, 326.6000061035156,
        391.79998779296875, 421.1000061035156, 445.1000061035156, 366.20001220703125,
        539.5999755859375, 298.29998779296875, 539.5999755859375,
      ],
      textPos: {
        x: 320,
        y: 460,
      },
    },
    {
      index: "03",
      points: [
        395.20001220703125, 241.1999969482422, 450.3999938964844, 272.5, 469.1000061035156,
        239.8000030517578, 522.7000122070312, 270.20001220703125, 575.5999755859375,
        179.10000610351562, 541.5999755859375, 120.0999984741211, 383.8999938964844,
        211.6999969482422, 398.1000061035156, 236.1999969482422,
      ],
      textPos: {
        x: 460,
        y: 220,
      },
    },
    {
      index: "07",
      points: [
        262.79998779296875, 346.20001220703125, 271, 360.29998779296875, 297.5, 360.29998779296875,
        298.29998779296875, 539.5999755859375, 231.8000030517578, 539.5999755859375,
        155.60000610351562, 407.6000061035156,
      ],
      textPos: {
        x: 230,
        y: 460,
      },
    },
    {
      index: "09",
      points: [
        57.20000076293945, 120.9000015258789, 214.3000030517578, 211, 199.3000030517578, 237,
        171.3000030517578, 219.8000030517578, 77.9000015258789, 272.8999938964844,
        23.700000762939453, 179.10000610351562,
      ],
      textPos: {
        x: 90,
        y: 220,
      },
    },
    {
      index: "10",
      points: [
        91.5, 61.400001525878906, 57.20000076293945, 120.9000015258789, 214.3000030517578, 211,
        228.5, 186.39999389648438, 199.89999389648438, 170.1999969482422, 200.10000610351562,
        61.400001525878906,
      ],
      textPos: {
        x: 120,
        y: 135,
      },
    },
  ],
  sevenToEight: [
    {
      index: "01",
      points: [
        200.3000030517578, 61, 200.1999969482422, 169.8000030517578, 228.89999389648438, 186,
        369.6000061035156, 185.89999389648438, 398.3999938964844, 171.5, 398.3999938964844, 61,
      ],
      textPos: {
        x: 275,
        y: 135,
      },
    },
    {
      index: "07",
      points: [
        78.19999694824219, 272.5, 171.60000610351562, 219.39999389648438, 199.60000610351562, 236.5,
        263.1000061035156, 345.70001220703125, 155.89999389648438, 407.1000061035156,
      ],
      textPos: {
        x: 140,
        y: 320,
      },
    },
    {
      index: "04",
      points: [
        454.1000061035156, 284, 390.8999938964844, 248.8000030517578, 326.1000061035156,
        361.29998779296875, 326.1000061035156, 390.20001220703125, 421.29998779296875,
        444.8999938964844, 471, 359.29998779296875, 500, 309.5,
      ],
      textPos: {
        x: 385,
        y: 350,
      },
    },
    {
      index: "09",
      points: [
        91.80000305175781, 61, 57.5, 120.5, 214.6999969482422, 210.60000610351562,
        228.89999389648438, 186, 200.1999969482422, 169.8000030517578, 200.39999389648438, 61,
      ],
      textPos: {
        x: 120,
        y: 135,
      },
    },
    {
      index: "08",
      points: [
        57.5, 120.5, 214.6999969482422, 210.60000610351562, 199.60000610351562, 236.5,
        171.60000610351562, 219.39999389648438, 78.19999694824219, 272.5, 24, 178.6999969482422,
      ],
      textPos: {
        x: 70,
        y: 205,
      },
    },
    {
      index: "06",
      points: [
        263.1000061035156, 345.70001220703125, 271.3999938964844, 359.79998779296875,
        297.8999938964844, 359.79998779296875, 298.70001220703125, 539, 232.1999969482422, 539,
        155.89999389648438, 407.1000061035156,
      ],
      textPos: {
        x: 210,
        y: 460,
      },
    },
    {
      index: "05",
      points: [
        297.8999938964844, 359.79998779296875, 327, 359.79998779296875, 327, 391.29998779296875,
        421.5, 444.6000061035156, 366.6000061035156, 539, 298.70001220703125, 539,
      ],
      textPos: {
        x: 320,
        y: 460,
      },
    },
    {
      index: "03",
      points: [
        500, 309.5, 390.8999938964844, 248.8000030517578, 398.3999938964844, 235.8000030517578,
        384.20001220703125, 211.1999969482422, 542, 119.69999694824219, 576, 178.6999969482422,
        523.0999755859375, 269.70001220703125,
      ],
      textPos: {
        x: 470,
        y: 205,
      },
    },
    {
      index: "02",
      points: [
        384.20001220703125, 211.1999969482422, 542, 119.69999694824219, 508.20001220703125, 61,
        398.3999938964844, 61, 398.3999938964844, 171.5, 369.6000061035156, 185.89999389648438,
      ],
      textPos: {
        x: 425,
        y: 135,
      },
    },
  ],
  nineToNineteen: [
    {
      index: "07",
      points: [
        57.5, 121.30000305175781, 214.6999969482422, 211.39999389648438, 199.60000610351562,
        237.39999389648438, 171.60000610351562, 220.1999969482422, 78.19999694824219,
        273.29998779296875, 24, 179.5,
      ],
      textPos: {
        x: 80,
        y: 210,
      },
    },
    {
      index: "02",
      points: [
        397.3999938964844, 234.8000030517578, 384.20001220703125, 212, 369.6000061035156,
        186.6999969482422, 398.3999938964844, 172.3000030517578, 398.3999938964844,
        61.70000076293945, 508.20001220703125, 61.70000076293945, 542, 120.4000015258789, 576,
        179.5, 521.5, 273.29998779296875, 425.20001220703125, 218.3000030517578,
      ],
      textPos: {
        x: 450,
        y: 180,
      },
    },
    {
      index: "06",
      points: [
        78.19999694824219, 273.29998779296875, 171.60000610351562, 220.1999969482422,
        199.60000610351562, 237.39999389648438, 263.1000061035156, 346.6000061035156,
        155.89999389648438, 408,
      ],
      textPos: {
        x: 140,
        y: 320,
      },
    },
    {
      index: "03",
      points: [
        397.3999938964844, 234.8000030517578, 425.20001220703125, 218.3000030517578, 521.5,
        273.29998779296875, 442.3999938964844, 409.6000061035156, 335.1000061035156,
        346.6000061035156,
      ],
      textPos: {
        x: 390,
        y: 320,
      },
    },
    {
      index: "04",
      points: [
        297.8999938964844, 360.70001220703125, 298.70001220703125, 540, 366.6000061035156, 540,
        442.3999938964844, 409.6000061035156, 335.1000061035156, 346.6000061035156, 327,
        360.70001220703125,
      ],
      textPos: {
        x: 330,
        y: 450,
      },
    },
    {
      index: "01",
      points: [
        369.6000061035156, 186.6999969482422, 245.10000610351562, 186.6999969482422,
        244.39999389648438, 61.70000076293945, 398.3999938964844, 61.70000076293945,
        398.3999938964844, 172.3000030517578,
      ],
      textPos: {
        x: 290,
        y: 135,
      },
    },
    {
      index: "08",
      points: [
        57.5, 121.30000305175781, 91.80000305175781, 61.70000076293945, 244.39999389648438,
        61.70000076293945, 245.10000610351562, 186.6999969482422, 228.89999389648438,
        186.8000030517578, 214.6999969482422, 211.39999389648438,
      ],
      textPos: {
        x: 140,
        y: 135,
      },
    },
    {
      index: "05",
      points: [
        263.1000061035156, 346.6000061035156, 271.3999938964844, 360.70001220703125,
        297.8999938964844, 360.70001220703125, 298.70001220703125, 540, 232.1999969482422, 540,
        155.89999389648438, 408,
      ],
      textPos: {
        x: 200,
        y: 450,
      },
    },
  ],
  twentyToTwentyOne: [
    {
      index: "01",
      points: [
        369.6000061035156, 186.5, 245.10000610351562, 186.5, 244.39999389648438, 61.400001525878906,
        398.3999938964844, 61.400001525878906, 398.3999938964844, 172.10000610351562,
      ],
      textPos: {
        x: 290,
        y: 135,
      },
    },
    {
      index: "07",
      points: [
        57.5, 121.0999984741211, 91.80000305175781, 61.400001525878906, 244.39999389648438,
        61.400001525878906, 245.10000610351562, 186.5, 228.89999389648438, 186.60000610351562,
        214.6999969482422, 211.1999969482422,
      ],
      textPos: {
        x: 130,
        y: 135,
      },
    },
    {
      index: "06",
      points: [
        57.5, 121, 214.6999969482422, 211.1999969482422, 199.60000610351562, 237.1999969482422,
        206.39999389648438, 248.89999389648438, 99.4000015258789, 309.29998779296875, 24,
        179.3000030517578,
      ],
      textPos: {
        x: 80,
        y: 225,
      },
    },
    {
      index: "05",
      points: [
        271.3999938964844, 360.6000061035156, 271.3999938964844, 393, 178.6999969482422,
        447.3999938964844, 99.4000015258789, 309.29998779296875, 206.39999389648438,
        248.89999389648438,
      ],
      textPos: {
        x: 160,
        y: 360,
      },
    },
    {
      index: "02",
      points: [
        398.3999938964844, 236.39999389648438, 384.20001220703125, 211.8000030517578,
        369.6000061035156, 186.5, 398.3999938964844, 172.10000610351562, 398.3999938964844,
        61.400001525878906, 508.20001220703125, 61.400001525878906, 542, 120.19999694824219, 576,
        179.3000030517578, 521.5, 273.1000061035156, 425.20001220703125, 218.10000610351562,
      ],
      textPos: {
        x: 450,
        y: 170,
      },
    },
    {
      index: "03",
      points: [
        398.3999938964844, 236.39999389648438, 425.20001220703125, 218.10000610351562, 521.5,
        273.1000061035156, 421.5, 445.5, 326.70001220703125, 393.29998779296875, 327,
        360.6000061035156,
      ],
      textPos: {
        x: 370,
        y: 360,
      },
    },
    {
      index: "04",
      points: [
        327, 360.6000061035156, 326.70001220703125, 393.29998779296875, 421.5, 445.5,
        366.6000061035156, 540, 232.1999969482422, 540, 178.6999969482422, 447.3999938964844,
        271.3999938964844, 393, 271.3999938964844, 360.6000061035156,
      ],
      textPos: {
        x: 270,
        y: 480,
      },
    },
  ],
  twentyToTwentyTwo: [
    {
      index: "01",
      points: [229, 187.8, 199.8, 173.8, 199.2, 62.9, 398.5, 63.3, 400.7, 171.5, 372.3, 187.6],
      textPos: {
        x: 270,
        y: 135,
      },
    },
    {
      index: "02",
      points: [0],
      textPos: {
        x: -1000,
        y: -1000,
      },
    },
    {
      index: "03",
      points: [398.4, 236.4, 428.2, 218.9, 521.5, 273.1, 421.5, 445.5, 326.7, 393.3, 327, 360.6],
      textPos: {
        x: 380,
        y: 335,
      },
    },
    {
      index: "04",
      points: [271.5,392.5, 271.5,360.6, 326.5,360.6, 326.5,392.5, 421.5,445.5, 366.6,540, 232.1,540, 178,447, 271.5,392.5],
      textPos: {
        x: 267.5,
        y: 480,
      },
    },
    {
      index: "05",
      points: [271.1, 358.6, 271.4, 393, 177.7, 446.9, 78.4, 274.1, 171, 218.1, 199.2, 234.7],
      textPos: {
        x: 140,
        y: 345,
      },
    },
  ],
}
