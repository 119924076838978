// import Gallery from "./components/Gallery"
import styled from "styled-components/macro"
import GallerySubTab from "./components/GallerySubTab"
import GalleryTab from "./components/GalleryTab"
import FilmGallery from "./components/VideoGallery"
import GalleryScene from "./components/WebglGallery/GalleryScene"

export default function GalleryLayout() {
  return (
    <Holder>
      <GallerySubTab />
      <GalleryScene />
      <FilmGallery />
      <GalleryTab />
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
