import { APART_PROPS } from "dataset/finder"
import create from "zustand"

export const useStore = create((set, get) => ({
  // Gallery
  testShow: true,
  setTestShow: v => set({ testShow: v }),

  activeImage: null,
  setActiveImage: v => set({ activeImage: v }),

  tabObj: { first: 0, second: 0 },
  setTabObject: v => set({ tabObj: v }),

  tabIndex: 0,
  setTabIndex: v => set({ tabIndex: v }),

  // tabSubIndex: "concierge",
  tabSubIndex: 0,
  setTabSubIndex: v => set({ tabSubIndex: v }),

  showImage: null,
  setShowImage: v => set({ showImage: v }),

  //Global
  fullScreenViews: false,
  setFullScreenViews: v => set({ fullScreenViews: false }),

  isPortrait: true,
  setIsPortrait: v => set({ isPortrait: v }),
  screenRatio: 1,
  setScreenRatio: v => set({ screenRatio: v }),

  activePoiLevel: null,
  asetActivePoiLevel: v => set({ activePoiLevel: v }),

  //Nav
  navBarVisible: false,
  setNavBarVisible: v => set({ navBarVisible: v }),
  navMenuVisible: false,
  setNavMenuVisible: v => set({ navMenuVisible: v }),
  visibleText: false,
  setVisibleText: v => set({ visibleText: v }),

  //Pages
  currentPage: false,
  setCurrentPage: v => set({ currentPage: v }),

  //Splash
  splashVisible: true,
  setSplashVisible: v => set({ splashVisible: v }),

  //Apartment Finder
  panoViewerActive: false,
  setPanoViewerActive: v => set({ panoViewerActive: v }),

  interacted: false,
  setInteracted: v => set({ interacted: v }),

  rotation: { deg: 0 },
  setRotation: v => set({ rotation: v }),

  modelsLoaded: false,
  setModelsLoaded: v => set({ modelsLoaded: v }),

  availableIDs: [],
  setAvailableIDs: v => set({ availableIDs: v }),

  filterVisible: true,
  setFilterVisible: v => set({ filterVisible: v }),
  apartments: [],
  setApartments: v => set({ apartments: v }),
  activeFloor: null,
  setActiveFloor: v => set({ activeFloor: v }),

  activeElementID: null,
  setActiveElementID: v => set({ activeElementID: v }),

  fullCardOpen: false,
  setFullCardOpen: v => set({ fullCardOpen: v }),

  activeApartment: null,
  setActiveApartment: v => set({ activeApartment: v }),

  litApartments: [],
  setLitApartments: v => set({ litApartments: v }),

  minPrice: 0,
  setMinPrice: v => set({ minPrice: v }),
  maxPrice: 0,
  setMaxPrice: v => set({ maxPrice: v }),
  priceRange: [0, 0],
  setPriceRange: v => set({ priceRange: v }),
  levelRange: [1, 21],
  setLevelRange: v => set({ levelRange: v }),

  bedroomCounts: [],
  toggleBedroomCounts: count =>
    set(state => ({
      bedroomCounts: count.length
        ? state.bedroomCounts.includes(count)
          ? state.bedroomCounts.filter(c => count !== c)
          : [...state.bedroomCounts, count]
        : [],
    })),

  type: [],
  toggleType: count =>
    set(state => ({
      type: count.length
        ? state.type.includes(count)
          ? state.type.filter(c => count !== c)
          : [...state.type, count]
        : [],
    })),

  selectedAspects: [],
  toggleAspects: count =>
    set(state => ({
      selectedAspects: count.length
        ? state.selectedAspects.includes(count)
          ? state.selectedAspects.filter(c => count !== c)
          : [...state.selectedAspects, count]
        : [],
    })),

  resetFilter: () =>
    set({
      priceRange: [get().minPrice, get().maxPrice],
      levelRange: [1, 21],
      bedroomCounts: [],
      selectedAspects: [],
      type: [],
    }),

  // Favorites
  favorites: [],
  setFavourites: favorites => set(() => ({ favorites })),
  toggleFavourites: apartment =>
    set(state => ({
      favorites: state.favorites.some(e => e[APART_PROPS.plot] === apartment[APART_PROPS.plot])
        ? state.favorites.filter(c => apartment[APART_PROPS.plot] !== c[APART_PROPS.plot])
        : [...state.favorites, apartment],
    })),

  //Location
  activeLocationTabIndex: 0,
  setActiveLocationTabIndex: v => set({ activeLocationTabIndex: v }),

  //POI
  activePOI: null,
  setActivePOI: v => set({ activePOI: v }),

  //Active Item
  activeItemIndex: null,
  setActiveItemIndex: v => set({ activeItemIndex: v }),

  //Showhome
  homeState: 0,
  setHomeState: v => set({ homeState: v }),

  tourIndex: 0,
  setTourIndex: v => set({ tourIndex: v }),

  //Change Map
  mapChangeClick: false,
  setMapChangeClick: v => set({ mapChangeClick: v }),

  //Gallery
  gallerySubSection: "concierge",
  setGallerySubSection: v => set({ gallerySubSection: v }),

  refreshPage: false,
  setRefreshPage: v => set({ refreshPage: v }),
}))
