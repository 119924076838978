import React from "react"

function Context() {
  return (
    <g transform="matrix(3.4 0 0 3.4 -88.4 -20.4)">
      <path
        fill="none"
        stroke="#4A3F38"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="m-43.87 33.09 8.83 11.31 19.89-9.08-6.46-9.98L-17 14.03l70.65 23.33 3.21 8.15-41.6 28.72 2.95 6.97-49.57 28.92-2.94-2.21-18.78 17.46-4.05-3.54-33.5 25.73M93.82 201.49l31.54-1.52 7.54 23L245.32 94.96s-20.48-17.79-20.48-19.26-4-6.25-4-6.25l-8.1-6.27-45.06-19.91-1.33 4.61-30.7-14.8L95.66 45.5S72.99 57.4 70.81 59.25C68.63 61.1 48.9 72.56 48.9 72.56l1.75 3.13-17.24 15.98-47.09 30.9 36.74 37.61-7.79 4.18 53.37 51.42 25.19-14.3"
      />
      <path
        fill="none"
        stroke="#4A3F38"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="m34.24 91.67 22.82 43.18h10.53l11.7 14.68-10.66 6.89 25.19 45.07M57.56 265.21l19.69-25.69-113.39-110.21-32.19 21M-21.54-1.62s25.7 12.47 53.39 17.74c27.69 5.27 84.68 3.71 100.63 4.49 15.95.78 54 12.41 69.34 19.48 15.34 7.07 57.07 34.14 59.52 34.14s33.14 16.19 33.14 16.19"
      />
      {"{/*"}
      <path
        fill="none"
        stroke="#4A3F38"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="m207.9 61.11-8.21 13.35-83.88-26.25v-8.87"
      />
      {"*/}"}
      <path
        d="M127.72 207.48s12.65-22.97 15.67-27.65c3.02-4.68 9.49-4.68 9.49-4.68l27.21-42.29.93-5.49s-.93-4.8-3.64-5.53c-2.71-.74 36.09-58.18 36.09-58.18"
        fill="none"
        stroke="#4A3F38"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        fill="none"
        stroke="#ab8062"
        strokeDasharray="0 0 0 2.16"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.74}
        d="m51.89 15.11-.39-7.27"
      />
      <path
        fill="none"
        stroke="#ab8062"
        strokeMiterlimit={10}
        strokeWidth={0.74}
        d="m49.02 9.95 2.44-2.91 2.73 2.64"
      />
      <path
        fill="none"
        stroke="#ab8062"
        strokeDasharray="0 0 0 2.16"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.74}
        d="m178.56 15.26 2.74-5.5"
      />
      <path
        fill="none"
        stroke="#ab8062"
        strokeMiterlimit={10}
        strokeWidth={0.74}
        d="m182.53 12.21-.9-3.08-3.01 1.13"
      />
      <path
        fill="none"
        stroke="#ab8062"
        strokeDasharray="0 0 0 2.16"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.74}
        d="m85.49 41.38-.14 5.44"
      />
      <path
        fill="none"
        stroke="#ab8062"
        strokeMiterlimit={10}
        strokeWidth={0.74}
        d="m87.33 45.39-2 2.04-1.88-2.14"
      />
      <g fill="#fff">
        <path d="M174.76 17.56v1.77c0 .67.3.95.69.95.44 0 .73-.29.73-.95v-1.77h.39v1.74c0 .92-.48 1.29-1.13 1.29-.61 0-1.07-.35-1.07-1.27v-1.76h.39ZM177.72 20.31c0-.16.11-.28.27-.28s.26.12.26.28-.1.28-.27.28c-.16 0-.26-.12-.26-.28ZM179.39 20.07c.17.11.42.19.69.19.39 0 .62-.21.62-.51 0-.28-.16-.44-.56-.59-.49-.17-.79-.42-.79-.85 0-.46.38-.81.96-.81.3 0 .53.07.66.15l-.11.31c-.1-.05-.3-.14-.57-.14-.41 0-.56.24-.56.45 0 .28.18.42.59.58.5.19.76.44.76.88 0 .46-.34.86-1.04.86-.29 0-.6-.08-.76-.19l.1-.32ZM182.23 20.31c0-.16.11-.28.27-.28s.26.12.26.28-.1.28-.27.28c-.16 0-.26-.12-.26-.28Z" />
      </g>
      <g fill="#fff">
        <path d="M169.28 24.45h-1.16v1.08h1.29v.32h-1.68v-2.98h1.61v.32h-1.23v.94h1.16v.32ZM173.18 24.54c-.02-.42-.05-.92-.04-1.29h-.01c-.1.35-.23.72-.38 1.13l-.53 1.45h-.29l-.48-1.42a16 16 0 0 1-.35-1.16c0 .37-.03.87-.06 1.32l-.08 1.28h-.37l.21-2.98h.49l.51 1.44c.12.37.23.7.3 1h.01c.08-.3.18-.63.31-1l.53-1.44h.49l.19 2.98h-.38l-.08-1.31ZM174.97 22.91c.17-.04.43-.06.7-.06.38 0 .63.07.82.22.16.12.25.29.25.53 0 .29-.19.54-.5.65.28.08.62.31.62.76 0 .26-.1.45-.25.6-.21.19-.54.28-1.03.28-.27 0-.47-.02-.6-.04v-2.93Zm.39 1.22h.35c.41 0 .65-.21.65-.5 0-.35-.27-.49-.65-.49-.18 0-.28.01-.34.03v.96Zm0 1.43c.08.01.19.02.32.02.4 0 .77-.15.77-.58 0-.41-.35-.58-.77-.58h-.32v1.14ZM178.61 24.91l-.31.94h-.4l1.01-2.98h.46l1.02 2.98h-.41l-.32-.94h-1.06Zm.98-.3-.29-.86c-.07-.19-.11-.37-.16-.54-.04.18-.09.36-.15.54l-.29.86h.9ZM181.53 25.38c.17.11.42.19.69.19.39 0 .62-.21.62-.51 0-.28-.16-.44-.56-.59-.49-.17-.79-.42-.79-.85 0-.46.38-.81.96-.81.3 0 .53.07.66.15l-.11.31c-.1-.05-.3-.14-.57-.14-.41 0-.56.24-.56.45 0 .28.18.42.59.58.5.19.76.44.76.88 0 .46-.34.86-1.04.86-.29 0-.6-.08-.76-.19l.1-.32ZM184.47 25.38c.17.11.42.19.69.19.39 0 .62-.21.62-.51 0-.28-.16-.44-.56-.59-.49-.17-.79-.42-.79-.85 0-.46.38-.81.96-.81.3 0 .53.07.66.15l-.11.31c-.1-.05-.3-.14-.57-.14-.41 0-.56.24-.56.45 0 .28.18.42.59.58.5.19.76.44.76.88 0 .46-.34.86-1.04.86-.29 0-.6-.08-.76-.19l.1-.32ZM188.12 25.85v-1.27l-.94-1.72h.44l.42.82c.12.23.2.41.3.62.08-.2.19-.39.3-.62l.43-.82h.44l-1 1.71v1.27h-.39Z" />
      </g>
      <g fill="#fff">
        <path d="M106.13 26.69c.18-.03.42-.06.73-.06.37 0 .65.09.82.24.16.14.26.35.26.61s-.08.47-.23.63c-.2.21-.53.32-.9.32-.11 0-.22 0-.3-.03v1.17h-.38v-2.9Zm.38 1.41c.08.02.19.03.31.03.46 0 .74-.22.74-.63s-.27-.57-.69-.57c-.17 0-.29.01-.36.03v1.14ZM111.69 28.09c0 1.01-.61 1.54-1.36 1.54s-1.32-.6-1.32-1.49c0-.93.58-1.54 1.36-1.54s1.32.61 1.32 1.48Zm-2.27.05c0 .63.34 1.19.94 1.19s.94-.55.94-1.22c0-.58-.3-1.19-.94-1.19s-.94.58-.94 1.22ZM112.92 29.58v-2.93h.41l.94 1.48c.22.34.39.65.53.95-.04-.4-.04-.75-.04-1.21v-1.23h.36v2.93h-.38l-.93-1.49c-.2-.33-.4-.66-.55-.98h-.01c.02.37.03.73.03 1.21v1.25h-.36ZM117.07 26.97h-.89v-.32h2.17v.32h-.9v2.61h-.38v-2.61ZM121.8 28.09c0 1.01-.61 1.54-1.36 1.54s-1.32-.6-1.32-1.49c0-.93.58-1.54 1.36-1.54s1.32.61 1.32 1.48Zm-2.27.05c0 .63.34 1.19.94 1.19s.94-.55.94-1.22c0-.58-.3-1.19-.94-1.19s-.94.58-.94 1.22ZM123.02 29.58v-2.93h.41l.94 1.48c.22.34.39.65.53.95-.04-.4-.04-.75-.04-1.21v-1.23h.36v2.93h-.38l-.93-1.49c-.2-.33-.4-.66-.55-.98h-.01c.02.37.03.73.03 1.21v1.25h-.36ZM128.2 26.69c.19-.04.47-.06.73-.06.4 0 .67.07.85.24.15.13.23.33.23.56 0 .39-.24.64-.55.75.23.09.36.3.43.6.1.41.17.69.23.8h-.39c-.05-.08-.11-.33-.2-.7-.09-.4-.24-.56-.59-.57h-.36v1.27h-.38v-2.89Zm.38 1.33h.39c.4 0 .66-.22.66-.56 0-.38-.27-.54-.67-.55-.18 0-.31.02-.37.03v1.07ZM133.79 28.09c0 1.01-.61 1.54-1.36 1.54s-1.32-.6-1.32-1.49c0-.93.58-1.54 1.36-1.54s1.32.61 1.32 1.48Zm-2.27.05c0 .63.34 1.19.94 1.19s.94-.55.94-1.22c0-.58-.3-1.19-.94-1.19s-.94.58-.94 1.22ZM135.42 28.66l-.3.92h-.39l1-2.93h.46l1 2.93h-.4l-.31-.92h-1.04Zm.96-.3-.29-.84c-.07-.19-.11-.37-.15-.54-.04.17-.09.35-.15.53l-.29.85h.88ZM138.35 26.69c.23-.04.5-.06.8-.06.54 0 .93.13 1.19.37.26.24.41.58.41 1.05s-.15.87-.42 1.14c-.27.27-.73.42-1.3.42-.27 0-.5-.01-.69-.04v-2.88Zm.38 2.59c.1.02.23.02.38.02.81 0 1.25-.45 1.25-1.24 0-.69-.39-1.13-1.19-1.13-.2 0-.34.02-.44.04v2.31Z" />
      </g>
      <g fill="#fff">
        <path d="M27.56 41.07V38.4h.38l.86 1.35c.2.31.35.6.48.87-.03-.36-.04-.69-.04-1.1V38.4h.33v2.67h-.35l-.85-1.36c-.19-.3-.37-.6-.5-.89h-.01c.02.34.03.66.03 1.11v1.14h-.33ZM31.19 38.39v2.67h-.34v-2.67h.34ZM32.47 41.07V38.4h.38l.86 1.35c.2.31.35.6.48.87-.03-.36-.04-.69-.04-1.1V38.4h.33v2.67h-.35l-.85-1.36c-.19-.3-.37-.6-.5-.89h-.01c.02.34.03.66.03 1.11v1.14h-.33ZM37.14 39.81H36.1v.96h1.16v.29h-1.5v-2.67h1.44v.29h-1.1v.85h1.04v.29ZM41.2 39.81h-1.04v.96h1.16v.29h-1.5v-2.67h1.44v.29h-1.1v.85h1.04v.29ZM42.44 38.39h.34v2.38h1.14v.29h-1.49v-2.67ZM47.24 39.89c-.02-.37-.04-.82-.04-1.16h-.01c-.09.31-.2.65-.34 1.02l-.47 1.3h-.26l-.43-1.27c-.13-.38-.23-.72-.31-1.04 0 .33-.03.78-.05 1.18l-.07 1.15h-.33l.19-2.67h.44l.46 1.29c.11.33.2.62.27.9h.01c.07-.27.16-.56.28-.9l.48-1.29h.44l.17 2.67h-.34l-.07-1.17ZM48.8 40.65c.15.1.38.17.62.17.35 0 .56-.19.56-.46 0-.25-.14-.39-.5-.53-.44-.16-.71-.38-.71-.76 0-.42.34-.73.86-.73.27 0 .47.06.59.13l-.09.28c-.09-.05-.27-.13-.51-.13-.37 0-.5.22-.5.4 0 .25.16.37.53.52.45.17.68.39.68.79 0 .41-.31.77-.94.77-.26 0-.54-.08-.68-.17l.09-.29Z" />
      </g>
      <g fill="#fff">
        <path d="M33.93 43.19c.17-.03.38-.05.66-.05.34 0 .59.08.75.22.15.13.23.32.23.56s-.07.43-.21.57c-.18.19-.48.29-.82.29-.1 0-.2 0-.28-.02v1.07h-.34v-2.64Zm.34 1.29c.08.02.17.03.29.03.42 0 .67-.2.67-.57s-.25-.52-.63-.52c-.15 0-.27.01-.33.03v1.04ZM36.81 44.99l-.28.84h-.36l.91-2.67h.42l.91 2.67h-.37l-.29-.84h-.95Zm.88-.27-.26-.77c-.06-.17-.1-.33-.14-.49-.04.16-.08.32-.13.48l-.26.77h.8ZM39.48 43.19c.17-.04.42-.05.66-.05.37 0 .61.07.77.22.14.12.21.3.21.51 0 .35-.22.59-.5.68.21.08.33.27.39.55.09.37.15.63.21.73h-.36c-.04-.08-.1-.31-.18-.64-.08-.37-.22-.51-.54-.52h-.33v1.16h-.34v-2.64Zm.34 1.22h.35c.37 0 .6-.2.6-.51 0-.35-.25-.5-.61-.5-.17 0-.29.02-.34.03v.98ZM42.29 43.16h.34v1.29h.01c.07-.1.14-.2.21-.29l.82-1h.43l-.97 1.13 1.04 1.54h-.41l-.88-1.31-.25.29v1.02h-.34v-2.67Z" />
      </g>
      <g fill="#fff">
        <path d="M45.51 21.43c.18-.04.44-.06.68-.06.38 0 .62.07.8.22.14.12.22.31.22.52 0 .36-.23.61-.52.7.21.09.34.28.4.57.09.38.16.65.21.76h-.37c-.04-.08-.11-.31-.19-.66-.08-.38-.23-.52-.55-.54h-.34v1.19h-.36v-2.72Zm.36 1.26h.36c.38 0 .62-.21.62-.52 0-.36-.26-.51-.63-.52-.17 0-.29.02-.35.03v1.01ZM48.76 21.4v2.76h-.36V21.4h.36ZM50.68 24.15l-.9-2.76h.38l.43 1.36c.12.37.22.71.29 1.03.08-.32.19-.67.31-1.03l.47-1.36h.38l-.99 2.76h-.39ZM54.48 22.86h-1.07v.99h1.19v.3h-1.55v-2.76h1.49v.3h-1.13v.87h1.07v.29ZM55.76 21.43c.18-.04.44-.06.68-.06.38 0 .62.07.8.22.14.12.22.31.22.52 0 .36-.23.61-.52.7.21.09.34.28.4.57.09.38.16.65.21.76h-.37c-.04-.08-.11-.31-.19-.66-.08-.38-.23-.52-.55-.54h-.34v1.19h-.36v-2.72Zm.36 1.26h.36c.38 0 .62-.21.62-.52 0-.36-.26-.51-.63-.52-.17 0-.29.02-.35.03v1.01Z" />
      </g>
      <g fill="#fff">
        <path d="M43.27 26.6h-.84v-.3h2.04v.3h-.84v2.46h-.36V26.6ZM45.83 26.3v1.15h1.33V26.3h.36v2.76h-.36v-1.29h-1.33v1.29h-.36V26.3h.36ZM49.28 28.19l-.29.87h-.37l.94-2.76h.43l.94 2.76h-.38l-.29-.87h-.98Zm.9-.28-.27-.79c-.06-.18-.1-.34-.14-.5-.04.16-.09.33-.14.5l-.27.8h.83ZM54.34 27.85c-.02-.38-.04-.85-.04-1.19h-.01c-.09.32-.21.67-.35 1.05l-.49 1.34h-.27l-.45-1.31c-.13-.39-.24-.74-.32-1.07 0 .34-.03.8-.05 1.22l-.07 1.18h-.34l.19-2.76h.45l.47 1.33c.11.34.21.64.28.93h.01c.07-.28.17-.58.29-.93l.49-1.33h.46l.17 2.76h-.35l-.07-1.21ZM57.43 27.77h-1.07v.99h1.19v.3H56V26.3h1.49v.3h-1.13v.87h1.07v.29ZM58.66 28.63c.16.1.39.18.64.18.37 0 .58-.19.58-.47 0-.26-.15-.4-.52-.55-.45-.16-.73-.39-.73-.78 0-.43.36-.75.89-.75.28 0 .49.06.61.13l-.1.29a1.07 1.07 0 0 0-.52-.13c-.38 0-.52.22-.52.41 0 .26.17.38.55.53.46.18.7.4.7.81 0 .43-.31.79-.96.79-.27 0-.56-.08-.7-.18l.09-.3Z" />
      </g>
      <g fill="#fff">
        <path d="M82.76 32.8c-.02-.38-.04-.83-.04-1.17h-.01c-.09.32-.21.65-.34 1.03l-.48 1.31h-.27l-.44-1.29c-.13-.38-.24-.73-.31-1.05 0 .34-.03.79-.05 1.2l-.07 1.16h-.33l.19-2.71h.45l.46 1.31c.11.33.21.63.27.91h.01c.07-.27.16-.57.29-.91l.48-1.31h.45l.17 2.71h-.34l-.07-1.19ZM84.79 33.14l-.28.85h-.36l.92-2.71h.42l.92 2.71h-.37l-.29-.85h-.96Zm.89-.27-.26-.78c-.06-.18-.1-.34-.14-.49-.04.16-.08.33-.14.49l-.26.78h.81ZM87.85 31.28v2.71h-.35v-2.71h.35ZM89.14 33.99v-2.71h.38l.87 1.37c.2.32.36.6.49.88-.03-.37-.04-.69-.04-1.12v-1.13h.33v2.71h-.35l-.86-1.37c-.19-.3-.37-.61-.51-.9h-.01c.02.34.03.67.03 1.12V34h-.33Z" />
      </g>
      <g fill="#fff">
        <path d="M76.04 37.54h-1.05v.98h1.17v.29h-1.52V36.1h1.46v.29h-1.11v.86h1.05v.29ZM77.3 38.81V36.1h.38l.87 1.37c.2.32.36.6.49.88-.03-.37-.04-.69-.04-1.12V36.1h.33v2.71h-.35l-.86-1.37c-.19-.3-.37-.61-.51-.9h-.01c.02.34.03.67.03 1.12v1.16h-.33ZM81.14 36.4h-.82v-.3h2v.3h-.83v2.41h-.35V36.4ZM83.3 36.14c.18-.04.43-.06.67-.06.37 0 .61.07.78.22.14.12.21.3.21.51 0 .36-.22.59-.51.69.21.08.33.28.4.56.09.38.15.64.21.74h-.36c-.04-.08-.1-.31-.18-.65-.08-.37-.23-.51-.54-.53h-.33v1.17h-.35v-2.67Zm.35 1.23h.36c.37 0 .61-.21.61-.51 0-.35-.25-.5-.62-.51-.17 0-.29.02-.34.03v.99ZM86.6 37.96l-.28.85h-.36l.92-2.71h.42l.92 2.71h-.37l-.29-.85h-.96Zm.89-.27-.26-.78c-.06-.18-.1-.34-.14-.49-.04.16-.08.33-.14.49l-.26.78h.81ZM89.31 38.81V36.1h.38l.87 1.37c.2.32.36.6.49.88-.03-.37-.04-.69-.04-1.12V36.1h.33v2.71h-.35l-.86-1.37c-.19-.3-.37-.61-.51-.9h-.01c.02.34.03.67.03 1.12v1.16h-.33ZM94.53 38.72c-.13.06-.39.13-.71.13-.76 0-1.34-.48-1.34-1.37s.57-1.42 1.41-1.42c.34 0 .55.07.64.12l-.08.29c-.13-.06-.32-.11-.55-.11-.63 0-1.06.41-1.06 1.12 0 .66.38 1.09 1.04 1.09.21 0 .43-.04.57-.11l.07.28ZM97.05 37.54H96v.98h1.17v.29h-1.52V36.1h1.46v.29H96v.86h1.05v.29Z" />
      </g>
    </g>
  )
}

export default Context
