import { useEffect } from "react"
import styled from "styled-components"
import { LOCATIONS, LONDON_LOCATIONS } from "dataset/location"
import { useStore } from "state/store"

function SideBar() {
  const activeLocationTabIndex = useStore(state => state.activeLocationTabIndex)
  const setActiveLocationTabIndex = useStore(state => state.setActiveLocationTabIndex)
  const setActiveItemIndex = useStore(state => state.setActiveItemIndex)
  const activeItemIndex = useStore(state => state.activeItemIndex)
  const setMapChangeClick = useStore(state => state.setMapChangeClick)
  const mapChangeClick = useStore(state => state.mapChangeClick)

  const setActivePOI = useStore(state => state.setActivePOI)

  useEffect(() => {
    setActiveItemIndex(0)
  }, [setActiveItemIndex, activeLocationTabIndex])

  const nextMap = () => {
    setMapChangeClick(!mapChangeClick)
    setActiveLocationTabIndex(0)
  }

  return (
    <>
      {mapChangeClick ? (
        <>
          <SideBarDevice>
            <Title>
              <h1>your</h1>
              <h2>london</h2>
              <h3>{LONDON_LOCATIONS[activeLocationTabIndex].name}</h3>
            </Title>
            <List>
              {LONDON_LOCATIONS[activeLocationTabIndex].pois.map((item, index) => (
                <Item
                  key={index}
                  active={activeItemIndex === item.typeIndexed}
                  onClick={() => {
                    setActivePOI(item)
                    setActiveItemIndex(item.typeIndexed)
                  }}>
                  <div>
                    <span>{index + 1}</span>
                  </div>
                  <div>
                    <h1>{item.poi}</h1>
                    <h2>{item.distance}</h2>
                  </div>
                </Item>
              ))}
            </List>
            <TabsShrink>
              {LONDON_LOCATIONS.map((tab, index) => (
                <Tab
                  key={index}
                  active={activeLocationTabIndex === index}
                  onClick={() => {
                    setActiveLocationTabIndex(index)
                  }}>
                  {<tab.icon active={activeLocationTabIndex === index} />}
                </Tab>
              ))}
            </TabsShrink>
            <NineElmsMap>
              <ButtonMap onClick={nextMap}>LONDON MAP &rarr;</ButtonMap>
            </NineElmsMap>
          </SideBarDevice>
        </>
      ) : (
        <>
          <SideBarDevice>
            <Title>
              <h1>your</h1>
              <h2>london</h2>
              <h3>{LOCATIONS[activeLocationTabIndex].name}</h3>
            </Title>
            <List>
              {LOCATIONS[activeLocationTabIndex].pois.map((item, index) => (
                <Item
                  key={index}
                  active={activeItemIndex === item.typeIndexed}
                  onClick={() => {
                    setActivePOI(item)
                    setActiveItemIndex(item.typeIndexed)
                  }}>
                  <div>
                    <span>{index + 1}</span>
                  </div>
                  <div>
                    <h1>{item.poi}</h1>
                    <h2>{item.distance}</h2>
                  </div>
                </Item>
              ))}
            </List>
            <TabsShrink>
              {LOCATIONS.map((tab, index) => {
                return (
                  <Tab
                    key={index}
                    active={activeLocationTabIndex === index}
                    onClick={() => {
                      setActiveLocationTabIndex(index)
                    }}>
                    {<tab.icon active={activeLocationTabIndex === index} />}
                  </Tab>
                )
              })}
            </TabsShrink>
            <NineElmsMap>
              <ButtonMap onClick={nextMap}>NINE ELMS MAP &rarr;</ButtonMap>
            </NineElmsMap>
          </SideBarDevice>
        </>
      )}
    </>
  )
}

function SideBarDevice({ children }) {
  return <SideBarDeskWrapper>{children}</SideBarDeskWrapper>
}

export default SideBar

const sharedValues = {
  toggleMapButtonHeight: "5%",
  titleHeight: "20%",
  tabsHeight: "20%",
  listHeight: "55%",
}

const TabsShrink = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: ${sharedValues.tabsHeight};
  box-sizing: border-box;
`

const Tab = styled.div`
  flex-basis: calc(50% - 1px);
  flex-grow: 1;
  box-sizing: border-box;
  border: 1px solid #c49f87;
  border-left: none;
  border-bottom: none;
  width: 100%;
  height: calc(100% / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props => (props.active ? "#b48465" : "#f6f2ee")};
  transition: all 0.2s ease-out;
  font-size: 0.5em;

  & svg {
    margin: 2px;
    scale: 0.9;
  }

  &:hover {
    opacity: 0.8;
  }
`

const SideBarDeskWrapper = styled.div`
  height: 100%;
  overflow: visible;
  width: 15em;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f6f2ee;
  border-left: #b58762 solid 2px;
  z-index: 999;
  box-sizing: border-box;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  padding: 2em 0;
  height: ${sharedValues.titleHeight};
  box-sizing: border-box;

  > h1 {
    font-size: 1.2em;
    color: #200b0b;
    font-weight: 100;
    line-height: 1.7;
    margin: 0;
    letter-spacing: 0.1em;
    justify-self: center;
  }
  > h2 {
    font-size: 2em;
    font-weight: 100;
    color: #b58762;
    line-height: 1;
    padding: 0;
    letter-spacing: 0.1em;
    border-bottom: 1px solid #b48465;
    justify-self: center;
  }
  > h3 {
    font-size: 0.9em;
    color: #200b0b;
    line-height: 1;
    margin-top: 1.2em;
    letter-spacing: 0.05em;
    justify-self: center;
  }
`
const heightCalculation = `calc(100% - ${sharedValues.toggleMapButtonHeight} - ${sharedValues.titleHeight} - ${sharedValues.tabsHeight})`

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  touch-action: pan-y;
  pointer-events: all;
  height: ${heightCalculation};
  box-sizing: border-box;

  & :last-child {
    border-bottom: none;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
  }

  * {
    touch-action: pan-y;
    pointer-events: all;
  }
`

const Item = styled.div`
  width: 16em;
  display: flex;
  font-size: 0.8em;
  justify-items: flex-start;
  align-items: center;
  border-bottom: 1px solid #b48465;
  padding-bottom: 1em;
  height: 7em;

  > div {
    > span {
      width: 2em;
      height: 2em;
      border-radius: 50%;
      border: 1px solid ${props => (props.active ? "#200b0b" : "#b48465")};
      text-align: center;
      line-height: 2.2;
      color: #200b0b;
      transition: all 0.5s ease-in;
      background-color: ${props => (props.active ? "#b48465" : "transparent")};
      cursor: pointer;
      margin-right: 1.5em;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    border-bottom: #b48465;
    gap: 0.6em;

    > h1 {
      font-size: 0.8em;
      color: #b48465;
      line-height: 1.2;
      font-weight: 400;
      padding: 0;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    > h2 {
      font-size: 0.8em;
      color: #200b0b;
      line-height: 1.2;
      font-weight: 100;
      margin: 0;
      letter-spacing: 0.1em;
    }
  }
`

const NineElmsMap = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  height: 3em;
  width: 100%;
  justify-content: center;
`

const ButtonMap = styled.button`
  background-color: #fff;
  border: 1px solid #c49f87;
  border-left: none;
  width: 100%;
  font-size: 0.8em;
  cursor: pointer;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  font-family: "Bliss-Light";
`
