export default function POIRetailIcon({ active = false }) {
  return (
    <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2519 0.360858C31.8879 0.360858 35.7301 4.06063 36.4051 8.80569H37.9685C40.3839 8.80569 42.3556 10.7757 42.3556 13.1708V37.2753C42.3556 39.6703 40.3832 41.6404 37.9685 41.6404H23.608H23.6083C23.4786 41.6424 23.3532 41.5926 23.2603 41.5022C23.1678 41.4121 23.1156 41.2889 23.1156 41.1606C23.1156 41.0321 23.1678 40.9088 23.2603 40.8188C23.3532 40.7284 23.4786 40.6785 23.6083 40.6805H37.9689C39.8518 40.6805 41.3844 39.1507 41.3844 37.2753V13.1707C41.3844 11.2954 39.8508 9.76558 37.9689 9.76558H36.4814C36.4902 9.94102 36.5115 10.1128 36.5115 10.2906V15.3607C36.5132 15.489 36.4628 15.6129 36.3716 15.7043C36.2801 15.796 36.1558 15.8476 36.0256 15.8476C35.8955 15.8476 35.7708 15.796 35.6797 15.7043C35.5885 15.6129 35.538 15.489 35.5397 15.3607V10.2909C35.5397 10.1131 35.5187 9.94137 35.5096 9.76592H19.0082C18.9983 9.94137 18.978 10.1131 18.978 10.2909V15.3607H18.9783C18.98 15.489 18.9299 15.6129 18.8384 15.7043C18.7473 15.796 18.6226 15.8476 18.4924 15.8476C18.3627 15.8476 18.238 15.796 18.1465 15.7043C18.0553 15.6129 18.0048 15.489 18.0069 15.3607V10.2909C18.0069 10.1131 18.0282 9.94137 18.037 9.76592H16.5495C14.6676 9.76592 13.134 11.2963 13.134 13.1711V18.1211L13.1336 18.1208C13.1357 18.249 13.0852 18.3729 12.9937 18.4646C12.9025 18.556 12.7778 18.6076 12.6481 18.6076C12.5179 18.6076 12.3932 18.556 12.3021 18.4646C12.2106 18.3729 12.1605 18.249 12.1622 18.1208V13.1708C12.1622 10.7751 14.1339 8.80567 16.5492 8.80567H18.1126C18.7879 4.06061 22.6151 0.36084 27.2511 0.36084L27.2519 0.360858ZM27.2519 1.32077C23.1834 1.32077 19.7634 4.5588 19.0999 8.80569H35.4183C34.7551 4.55846 31.3199 1.32077 27.2514 1.32077H27.2519Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0114 21.3149C19.2315 21.3149 21.044 23.1237 21.044 25.3244V37.6364C21.044 39.8332 19.2315 41.6412 17.0114 41.6412H4.62168C2.40154 41.6412 0.583984 39.8339 0.583984 37.6364V25.3244C0.583984 23.1226 2.40154 21.3149 4.62168 21.3149H17.0114ZM17.0114 22.2749H4.62168C2.93424 22.2749 1.55544 23.6433 1.55544 25.3244V37.6364C1.55544 39.3122 2.93424 40.681 4.62168 40.681H17.0114C18.6988 40.681 20.0725 39.3129 20.0725 37.6364V25.3244C20.0725 23.6426 18.6988 22.2749 17.0114 22.2749Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6942 24.8953C15.9626 24.8993 16.1767 25.117 16.173 25.3818V27.0717C16.173 28.2938 15.4211 29.2433 14.4063 29.8186C13.3918 30.3934 12.1024 30.6586 10.8149 30.6583C9.52722 30.658 8.23828 30.3924 7.22381 29.8172C6.20933 29.2423 5.45743 28.2928 5.45743 27.0717V25.3819H5.45709C5.45506 25.2536 5.50554 25.1297 5.5967 25.0383C5.68819 24.9466 5.81288 24.895 5.94266 24.895C6.07278 24.895 6.19747 24.9466 6.28862 25.0383C6.38011 25.1297 6.43026 25.2536 6.42857 25.3819V27.0717C6.42857 27.9205 6.89448 28.5238 7.70701 28.9842C8.51956 29.4449 9.66657 29.6977 10.8149 29.698C11.9632 29.6983 13.1102 29.4459 13.9228 28.9855C14.7353 28.5251 15.2012 27.9218 15.2012 27.0717V25.3818C15.1995 25.2522 15.251 25.1273 15.3439 25.0356C15.4367 24.9439 15.5631 24.8933 15.6942 24.8953H15.6942Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
    </svg>
  )
}
