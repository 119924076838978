export default function POIDiningIcon({ active = false }) {
  return (
    <svg width="51" height="41" viewBox="0 0 51 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_201_535)">
        <path
          d="M26.3233 30.168C31.9125 30.168 36.4435 25.6908 36.4435 20.168C36.4435 14.6451 31.9125 10.168 26.3233 10.168C20.7341 10.168 16.2031 14.6451 16.2031 20.168C16.2031 25.6908 20.7341 30.168 26.3233 30.168Z"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeWidth="1.10338"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M26.4713 35.001C34.8551 35.001 41.6516 28.2852 41.6516 20.001C41.6516 11.7167 34.8551 5.00098 26.4713 5.00098C18.0875 5.00098 11.291 11.7167 11.291 20.001C11.291 28.2852 18.0875 35.001 26.4713 35.001Z"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeWidth="1.10338"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.242 40.4147V0.586426C47.3354 0.586426 44.9863 14.1282 44.9863 17.1823V22.7143H50.2512"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeWidth="1.10338"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M4.97266 40.4147V0.586426"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeWidth="1.10338"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9.18912 0.586426V7.22288C9.19032 7.80466 9.08216 8.38096 8.87082 8.9187C8.65949 9.45644 8.34916 9.94503 7.95764 10.3564C7.56612 10.7678 7.10113 11.0939 6.58936 11.3159C6.07758 11.538 5.52911 11.6516 4.97542 11.6504V11.6504C3.85788 11.6504 2.78611 11.1839 1.99588 10.3536C1.20566 9.52327 0.761719 8.39712 0.761719 7.22288V0.586426"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeWidth="1.10338"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_535">
          <rect width="50.601" height="41" fill="white" transform="translate(0.201172)" />
        </clipPath>
      </defs>
    </svg>
  )
}
