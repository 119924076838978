import styled from "styled-components"

function Sections({ action, section }) {
  return (
    <Wrapper>
      <List>
        <ListElement
          id="FLOORPLAN"
          className={section === "FLOORPLAN" ? "selected" : ""}
          onClick={e => {
            action(e.currentTarget.id)
          }}>
          {" "}
          FLOOR PLAN{" "}
        </ListElement>
        <ListElement
          id="VIEWS"
          className={section === "VIEWS" ? "selected" : ""}
          onClick={e => {
            action(e.currentTarget.id)
          }}>
          {" "}
          VIEWS{" "}
        </ListElement>
        <ListElement
          id="SPECIFICATION"
          className={section === "SPECIFICATION" ? "selected" : ""}
          onClick={e => {
            action(e.currentTarget.id)
          }}>
          {" "}
          SPECIFICATION
        </ListElement>
      </List>
    </Wrapper>
  )
}

export default Sections

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .selected {
    color: #b58762;
    text-decoration: underline;
  }
`

const List = styled.ul`
  /* border: red solid 2px; */
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  padding-bottom: 0%;
  color: white;
  gap: 20px;
`

const ListElement = styled.li`
  flex: 1 1;
  display: grid;
  place-content: center;
  font-size: 0.8em;
  cursor: pointer;
`
