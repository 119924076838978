import React, { createContext, useContext, useState } from "react"

const ErrorContext = createContext(null)

export function useErrorContext() {
  return useContext(ErrorContext)
}

export function ErrorProvider({ children }) {
  const [error, setError] = useState(null)

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>
}
