import React from "react"
import { useStore } from "state/store"

function Cameras() {
  const { setShowImage } = useStore(state => state)
  const handleClickCamera = targetId => {
    setShowImage(targetId)
  }
  return (
    <>
      <defs>
        <linearGradient id="gradient-stroke" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: "blue", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "red", stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g onClick={() => handleClickCamera("a")} transform="translate(1400, 925) scale(2)" id="A">
        <path
          d="M22.93,45.5c12.47,0,22.58-10.11,22.58-22.58S35.4,.35,22.93,.35,.35,10.46,.35,22.92s10.11,22.58,22.58,22.58"
          fill="#a98162"
          stroke="url(#gradient-stroke)"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M19.91,15.08c-.38,0-.71,.19-.91,.44-.2,.24-.32,.53-.43,.8h0l-.38,1h-4.01c-.85,0-1.54,.7-1.54,1.55v10.9c0,.85,.69,1.55,1.54,1.55h18.31c.85,0,1.54-.7,1.54-1.55v-10.9c0-.85-.69-1.55-1.54-1.55h-4.01l-.38-1h0c-.1-.28-.22-.56-.43-.8s-.53-.44-.91-.44h-6.87Zm0,.74h6.87c.17,0,.24,.05,.35,.17s.21,.34,.3,.59l.47,1.24c.05,.14,.19,.24,.34,.24h4.26c.45,0,.8,.35,.8,.81v10.9c0,.46-.35,.81-.8,.81H14.19c-.45,0-.8-.35-.8-.81v-10.9c0-.46,.35-.81,.8-.81h4.26c.15,0,.29-.1,.34-.24l.47-1.24c.09-.25,.2-.46,.3-.59,.11-.13,.18-.17,.35-.17h0Zm3.44,3.66c-2.65,0-4.81,2.17-4.81,4.84s2.15,4.85,4.81,4.85,4.81-2.18,4.81-4.85-2.15-4.84-4.81-4.84Zm7.83,.15c-.14,0-.27,.06-.37,.16-.1,.1-.15,.23-.15,.37,0,.29,.23,.52,.52,.52,.14,0,.27-.05,.37-.15,.1-.1,.15-.23,.16-.37,0-.14-.06-.27-.15-.37-.1-.1-.23-.15-.37-.15h0Zm-7.83,.59c2.25,0,4.07,1.83,4.07,4.11s-1.82,4.11-4.07,4.11-4.07-1.83-4.07-4.11,1.82-4.11,4.07-4.11Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <g onClick={() => handleClickCamera("b")} transform="translate(1550, 820) scale(2)" id="B">
        <path
          id="circle-A"
          d="M22.93,45.5c12.47,0,22.58-10.11,22.58-22.58S35.4,.35,22.93,.35,.35,10.46,.35,22.92s10.11,22.58,22.58,22.58"
          fill="#a98162"
          stroke="url(#gradient-stroke)"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M19.91,15.08c-.38,0-.71,.19-.91,.44-.2,.24-.32,.53-.43,.8h0l-.38,1h-4.01c-.85,0-1.54,.7-1.54,1.55v10.9c0,.85,.69,1.55,1.54,1.55h18.31c.85,0,1.54-.7,1.54-1.55v-10.9c0-.85-.69-1.55-1.54-1.55h-4.01l-.38-1h0c-.1-.28-.22-.56-.43-.8s-.53-.44-.91-.44h-6.87Zm0,.74h6.87c.17,0,.24,.05,.35,.17s.21,.34,.3,.59l.47,1.24c.05,.14,.19,.24,.34,.24h4.26c.45,0,.8,.35,.8,.81v10.9c0,.46-.35,.81-.8,.81H14.19c-.45,0-.8-.35-.8-.81v-10.9c0-.46,.35-.81,.8-.81h4.26c.15,0,.29-.1,.34-.24l.47-1.24c.09-.25,.2-.46,.3-.59,.11-.13,.18-.17,.35-.17h0Zm3.44,3.66c-2.65,0-4.81,2.17-4.81,4.84s2.15,4.85,4.81,4.85,4.81-2.18,4.81-4.85-2.15-4.84-4.81-4.84Zm7.83,.15c-.14,0-.27,.06-.37,.16-.1,.1-.15,.23-.15,.37,0,.29,.23,.52,.52,.52,.14,0,.27-.05,.37-.15,.1-.1,.15-.23,.16-.37,0-.14-.06-.27-.15-.37-.1-.1-.23-.15-.37-.15h0Zm-7.83,.59c2.25,0,4.07,1.83,4.07,4.11s-1.82,4.11-4.07,4.11-4.07-1.83-4.07-4.11,1.82-4.11,4.07-4.11Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <g onClick={() => handleClickCamera("c")} transform="translate(1231,820) scale(2)" id="C">
        <path
          id="circle-A"
          d="M22.93,45.5c12.47,0,22.58-10.11,22.58-22.58S35.4,.35,22.93,.35,.35,10.46,.35,22.92s10.11,22.58,22.58,22.58"
          fill="#a98162"
          stroke="url(#gradient-stroke)"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M19.91,15.08c-.38,0-.71,.19-.91,.44-.2,.24-.32,.53-.43,.8h0l-.38,1h-4.01c-.85,0-1.54,.7-1.54,1.55v10.9c0,.85,.69,1.55,1.54,1.55h18.31c.85,0,1.54-.7,1.54-1.55v-10.9c0-.85-.69-1.55-1.54-1.55h-4.01l-.38-1h0c-.1-.28-.22-.56-.43-.8s-.53-.44-.91-.44h-6.87Zm0,.74h6.87c.17,0,.24,.05,.35,.17s.21,.34,.3,.59l.47,1.24c.05,.14,.19,.24,.34,.24h4.26c.45,0,.8,.35,.8,.81v10.9c0,.46-.35,.81-.8,.81H14.19c-.45,0-.8-.35-.8-.81v-10.9c0-.46,.35-.81,.8-.81h4.26c.15,0,.29-.1,.34-.24l.47-1.24c.09-.25,.2-.46,.3-.59,.11-.13,.18-.17,.35-.17h0Zm3.44,3.66c-2.65,0-4.81,2.17-4.81,4.84s2.15,4.85,4.81,4.85,4.81-2.18,4.81-4.85-2.15-4.84-4.81-4.84Zm7.83,.15c-.14,0-.27,.06-.37,.16-.1,.1-.15,.23-.15,.37,0,.29,.23,.52,.52,.52,.14,0,.27-.05,.37-.15,.1-.1,.15-.23,.16-.37,0-.14-.06-.27-.15-.37-.1-.1-.23-.15-.37-.15h0Zm-7.83,.59c2.25,0,4.07,1.83,4.07,4.11s-1.82,4.11-4.07,4.11-4.07-1.83-4.07-4.11,1.82-4.11,4.07-4.11Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </>
  )
}

export default Cameras
