import React, { useEffect, useState, useContext } from "react"
import PageTransitionHolder from "components/PageTransitionHolder"
import Orbits from "components/Orbits/Orbits"

export default function Home() {
  return (
    <PageTransitionHolder>
      <Orbits numberOfOrbits={10} totalTime={28000} pauseTime={1000} />
    </PageTransitionHolder>
  )
}
