import React, { useState } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import anm from "./transitions/transitions"
import { APART_PROPS } from "dataset/finder"
import dimensions from "dataset/dimensions"

function FloorplanMobile({ section, apartment }) {
  let activeApartment = apartment
  const transition = useTransition(section === "FLOORPLAN", anm)
  const [display, setDisplay] = useState(true)

  const route = activeApartment?.[APART_PROPS.unitType]?.split(" ")[1]?.toLowerCase()
  const aptDimensions = route && Object.keys(dimensions?.[route])

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          <Line />
          {display ? (
            <Plan
              route={route}
              onClick={() => {
                setDisplay(!display)
              }}
            />
          ) : (
            <DimensionsInfo
              onClick={() => {
                setDisplay(!display)
              }}>
              <ul>
                {aptDimensions?.map((key, i) => {
                  return (
                    <li key={`li${i}`}>
                      <h4> {i + 1} </h4>
                      <p>
                        <span>{key}</span>
                        {route && dimensions[route]?.[key]}
                      </p>
                    </li>
                  )
                })}
              </ul>
            </DimensionsInfo>
          )}
          <Line />
        </Wrapper>
      ),
  )
}

export default FloorplanMobile

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  touch-action: auto !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
`

const Plan = styled.div`
  width: 100%;
  height: 50vh;
  background-color: white;
  background-image: ${p => `url(assets/images/finder/plans/${p.route}.svg)`};
  /* background-image: ${p => `url(assets/images/finder/plans/${p.route}.png)`}; */
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: center;
`

const DimensionsInfo = styled.div`
  color: white;
  position: relative;
  width: 100%;
  max-width: 100%;

  & h1 {
    font-size: 3.5em;
    text-align: center;
    font-weight: 100;
  }

  & ul {
    list-style: none;
  }

  & li {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 5% 5%;
    gap: 5%;

    & h4 {
      width: 10%;
      aspect-ratio: 1/1;
      border: 1px solid #b58762;
      font-size: 3em;
      color: #b58762;
      display: grid;
      place-content: center;
      border-radius: 100%;
    }

    & p {
      display: flex;
      flex-direction: column;
      font-weight: 100;
      justify-content: center;
      font-family: Bliss-Light;
      font-size: 3em;

      & span {
        color: #b58762;
        font-weight: 100;
        font-family: Grande;
        font-size: 1.2em;
      }
    }
  }
`

const Line = styled.hr`
  width: 80%;
  height: 1px;
  margin: 5% 10%;
  background-color: white;
  border: none;
`
