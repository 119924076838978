import React, { useRef, useState } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import Sections from "./Sections"
import { useStore } from "state/store"
import anm from "../transitions/transitions"
import specs from "dataset/specs.js"
import { APART_PROPS } from "dataset/finder"

function Specifications({ section, action }) {
  const [open, setOpen] = useState(false)
  const selectedSpec = useRef(0)
  let activeApartment = useStore(s => s.activeApartment)
  const specType = activeApartment?.[APART_PROPS.unitType]?.split(" ")[1]?.toLowerCase();

  const [selectedFinish, setSelectedFinish] = useState(true)
  const specsArr = specType === 'chester' ? ["KITCHEN /PANTRY", "GENERAL", "BAR", "PRINCIPAL", "SHOWER", "BATHROOM", "POWDER"] :
  ["BATHROOM", "KITCHEN", "BALCONIES", "EXTERIOR", "TEMPERATURE", "COMMUNAL", "GENERAL"];

  const transition = useTransition(section === "SPECIFICATION", anm)
  const file = () => {
    if (
      specsArr[selectedSpec.current] === "BATHROOM" ||
      specsArr[selectedSpec.current] === "KITCHEN"
    ) {
      return selectedFinish
        ? `${specsArr[selectedSpec.current]}_lum`
        : `${specsArr[selectedSpec.current]}_all`
    } else {
      return specsArr[selectedSpec.current]
    }
  }
  const dropAnm = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
        {specType !== 'chester' && (
          <Image route={file()} />
        )}
          <SideBar>
            <SpecsInfo>
              <h1> SPECIFICATIONS</h1>
              <Line />
              <SpecsSelector>
                <h2
                  onClick={() => {
                    setOpen(!open)
                  }}>
                  {specsArr[selectedSpec.current]}
                  <span> ♢ </span>
                </h2>

                {dropAnm(
                  (drop, loaded) =>
                    loaded && (
                      <OptionsWrapper style={drop}>
                        {specsArr.map((spec, i) => {
                          return (
                            <SpecsOption
                              className={selectedSpec.current === i ? "selected" : ""}
                              onClick={() => {
                                selectedSpec.current = i
                                setOpen(false)
                              }}
                              key={`opt${i}`}>
                              {spec}
                            </SpecsOption>
                          )
                        })}
                      </OptionsWrapper>
                    ),
                )}
              </SpecsSelector>
              {(specsArr[selectedSpec.current] === "BATHROOM" ||
              specsArr[selectedSpec.current] === "KITCHEN") && specType != 'chester' ? (
                <CopyWrapper>
                  <button
                    className={selectedFinish ? "activeFinish" : ""}
                    onClick={() => {
                      setSelectedFinish(true)
                    }}>
                    {" "}
                    LUMINOUS{" "}
                  </button>
                  <button
                    className={!selectedFinish ? "activeFinish" : ""}
                    onClick={() => {
                      setSelectedFinish(false)
                    }}>
                    {" "}
                    ALLURE{" "}
                  </button>
                  {selectedFinish
  ? specs['default'][specsArr[selectedSpec.current]].luminous.map((p, i) => {
      return <Copy key={`spec${i}`}>{p}</Copy>;
    })
  : specs['default'][specsArr[selectedSpec.current]].allure.map((p, i) => {
      return <Copy key={`spec${i}`}>{p}</Copy>;
    })}

                </CopyWrapper>
              ) : (
                <CopyWrapper>
                 
                  {specs[specType === 'chester' ? 'chester' : 'default'][specsArr[selectedSpec.current]].map((p, i) => {
                    return <Copy key={`spec${i}`}>{p} </Copy>
                  })}
                </CopyWrapper>
              )}
            </SpecsInfo>
            <Line />
            <SectionsWrapper>
              <Sections action={action} section={section} />
            </SectionsWrapper>
          </SideBar>
        </Wrapper>
      ),
  )
}

export default Specifications

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const Image = styled.div`
  position: absolute;
  width: 72%;
  height: 100%;
  background-image: ${p => `url(assets/images/finder/specs/${p.route.toLowerCase()}.jpg)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const SideBar = styled.div`
  position: absolute;
  right: 0;
  width: 28%;
  height: 100%;
  padding: 2.5%;
  display: flex;
  flex-direction: column;
  background-color: black;
`

const SpecsInfo = styled.div`
  flex: 4 1;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & h1 {
    font-size: 1.5em;
    text-align: center;
    font-weight: 100;
  }
`

const SectionsWrapper = styled.div`
  flex: 1 1;
`

const Line = styled.hr`
  width: 80%;
  height: 1px;
  margin-left: 10%;
  background-color: white;
  border: none;
`

const SpecsSelector = styled.div`
  position: relative;
  width: 90%;
  padding: 10% 5%;
  margin-left: 5%;

  & h2 {
    font-weight: 100;
    font-size: 1rem;
    color: #b58762;
    border-bottom: 1px solid #b58762;
    cursor: pointer;

    & span {
      position: absolute;
      right: 10%;
    }
  }
`

const OptionsWrapper = styled(a.div)`
  margin-top: 5%;
  width: 80%;
  margin-left: 5%;
  position: absolute;
  background-color: black;
  border: 1px solid #b58762;
  border-top: none;
  z-index: 2;

  .selected {
    background-color: #b58762;
    color: black;
  }
`

const SpecsOption = styled.p`
  padding: 10% 0% 0% 10%;
  width: 100%;
  cursor: pointer;

  :hover {
    background-color: white;
    color: black;
  }
`
const CopyWrapper = styled.div`
  padding: 2% 0%;
  margin-bottom: 2%;
  overflow-y: scroll;

  & button {
    width: 45%;
    margin: 1.5%;
    padding: 1%;
    border: 1px solid black;
    background-color: #b58762;
    color: black;
    cursor: pointer;
  }

  .activeFinish {
    border: 1px solid white;
    transition: border 0.5s ease-in;
  }
`
const Copy = styled.p`
  width: 100%;
  text-align: left;
  font-weight: 100;
  margin: 5% 0%;
  font-family: Bliss-Light;
  font-size: 0.8em;
`
