export default function POITravelIcon({ active = false }) {
  return (
    <svg width="56" height="44" viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_201_541)">
        <path
          d="M6.68555 26.7959C8.88604 36.47 17.588 43.7 27.9934 43.7C38.3988 43.7 47.1038 36.473 49.3012 26.7959"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M15.4648 26.7959C17.4047 31.7925 22.2815 35.3382 27.998 35.3382C33.7144 35.3382 38.5913 31.7925 40.5311 26.7959"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M6.56055 17.8123C8.52766 7.83421 17.3781 0.303223 28.0018 0.303223C38.6254 0.303223 47.4758 7.83421 49.443 17.8123"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M40.7508 17.8099C38.9807 12.4973 33.9432 8.6626 27.9994 8.6626C22.0556 8.6626 17.0181 12.4973 15.248 17.8099"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M49.4428 17.812H55.5321V26.7968H49.3125"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M6.55497 17.812H0.46875V26.7968H6.68834"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M40.5311 26.7959H15.4648"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M15.248 17.812H40.7508"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M49.3021 26.7959H40.5273"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M6.68555 26.7959H15.4603"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M6.56055 17.812H15.2504"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
        <path
          d="M49.4349 17.812H40.748"
          stroke={active ? "#f6f2ee" : "#200a0b"}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_541">
          <rect width="55.6611" height="44" fill="white" transform="translate(0.167969)" />
        </clipPath>
      </defs>
    </svg>
  )
}
