const anm = {
    from:{
        opacity: 0,
        left: "-150%",
    },
    enter: {
        opacity: 1,
        left: "0%",
    },
    leave: {
        opacity: 0,
        left: "150%",
    }
}

export default anm