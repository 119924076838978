import React, { Suspense } from "react"

import CameraControls from "./components/CameraControls"
import Scene from "./components/Scene"
import Sphere, { TourSphere } from "./components/Sphere"

function SphericalTour({ texture, hotSpots, hasLimit }) {
  return (
    <Suspense fallback={"LOADING..."}>
      <Scene>
        <CameraControls />
        <TourSphere texture={texture} hotSpots={hotSpots} />
      </Scene>
    </Suspense>
  )
}

export default SphericalTour
