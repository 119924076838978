import styled from "styled-components"
import CloseIcon from "assets/images/icons/close.svg"
import { useStore } from "state/store"
import { useState } from "react"

function CloseButton() {
  const setFullCardOpen = useStore(s => s.setFullCardOpen)
  const setPanoViewerActive = useStore(s => s.setPanoViewerActive)
  function handleClosing() {
    setPanoViewerActive(false)
    setTimeout(() => setFullCardOpen(false), 300)
  }

  const [down, setDown] = useState(false)

  return (
    <Close
      onPointerDown={() => setDown(true)}
      onPointerUp={() => setDown(false)}
      onClick={() => {
        handleClosing()
      }}>
      <img style={{ opacity: down ? 0.2 : 1 }} src={CloseIcon} alt="" />
    </Close>
  )
}

export default CloseButton

const Close = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
  background-color: white;

  & img {
    transition: opacity 0.2s ease;
    position: absolute;
    bottom: 10%;
    right: 10%;
    z-index: 5;
    width: 35%;
    aspect-ratio: 1/1;
    border: 1px solid #b58762;
    border-radius: 100%;
    padding: 5%;
  }
`
