import { useState, useRef } from "react"
import styled from "styled-components/macro"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import { AnimatePresence } from "framer-motion"
import { useStore } from "state/store"
import { animated } from "@react-spring/web"
import DrawSVG from "components/AnimatedUIElements/AnimatedSVGPaths"
import { useEffect } from "react"
import useRecursiveTimeout from "utils/hooks/useRecursiveTimeout"
import useNotInitialRender from "utils/notOnMount"
import useLongPress from "utils/hooks/useLongPress"

const VIDEO_RATIO = 1920 / 1080

function AnimatedEnterButton() {
  const enter = ["e", "n", "t", "e", "r"]
  const [showEnterButton, setShowEnterButton] = useState(false)

  // START Electron Logic
  const [fullScreen, setFullScreen] = useState(true)
  const [electronMenu, setElectronMenu] = useState(false)
  const containerRef = useRef()

  const handleLongPress = useLongPress(() => {
    setElectronMenu(prevCheck => !prevCheck)
  }, 3000)

  const handleDoubleTap = () => {
    setFullScreen(prevCheck => !prevCheck)
  }

  useEffect(() => {
    const tempRef = containerRef.current

    tempRef?.addEventListener("dblclick", handleDoubleTap)

    return () => {
      tempRef?.removeEventListener("dblclick", handleDoubleTap)
    }
  }, [])

  useNotInitialRender(() => {
    if (window.api) window.api.send("toMain", { message: "toggleFullScreen", fullScreen })
  }, [fullScreen])

  useNotInitialRender(() => {
    if (window.api) window.api.send("toMain", { message: "toggleElectronMenu", electronMenu })
  }, [electronMenu])

  // END Electron Logic

  useRecursiveTimeout(() => {
    setShowEnterButton(true)
  }, 4000)

  useEffect(() => {
    return () => setShowEnterButton(false)
  }, [])

  const navigate = useNavigate()

  return (
    <>
      <FullScreenToggler ref={containerRef} />
      <ElectronMenuToggler {...handleLongPress} />
      <VideoSplash />
      {showEnterButton && (
        <EnterButton
          onClick={e => {
            e.stopPropagation()
            navigate("/home")
          }}>
          <Enter>
            {enter.map((l, i) => (
              <p key={i}>{l}</p>
            ))}
          </Enter>

          <DrawSVG active={true} repeat={true} action={() => {}}>
            <svg
              width="100%"
              viewBox="0 0 185 31"
              fill="none"
              stroke="#fff"
              xmlns="http://www.w3.org/2000/svg">
              <path
                pathLength={1}
                d="M171.199 26.9308L183.796 15.3604L171.199 3.78995"
                stroke="white"
                strokeWidth="1"
              />
              <path
                pathLength={1}
                d="M183.337 15.6328 L30.3929 15.6328"
                stroke="white"
                strokeWidth="1"
              />
              <path pathLength={1} d="M1,27.6l29.4-12L1,3.8" stroke="white" strokeWidth="1" />
            </svg>
          </DrawSVG>
        </EnterButton>
      )}
    </>
  )
}
const Enter = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > p {
    font-family: Bliss-Light;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1;
  }
`
const EnterButton = styled(animated.div)`
  position: absolute;
  bottom: 22%;
  width: 8em;
  height: 4.2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > img {
    pointer-events: none;
    width: 7em;
  }
`

export default function Splash() {
  return (
    <Holder>
      <AnimatePresence>
        <SplashContainer>
          <AnimatedEnterButton />
        </SplashContainer>
      </AnimatePresence>
    </Holder>
  )
}

function VideoSplash() {
  const [, setIsReady] = useState(false)
  const screenRatio = useStore(state => state.screenRatio)

  return (
    <>
      <Player
        url={"assets/videos/lifestyle.mp4"}
        width="100%"
        height={screenRatio > VIDEO_RATIO ? `${100 * (screenRatio / VIDEO_RATIO)}%` : "100%"}
        playsinline={true}
        playing={true}
        loop={true}
        muted={true}
        onReady={() => {
          setIsReady(true)
        }}
      />
    </>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: black;
  background-color: black;
  overflow: hidden;
`

const SplashContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Player = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FullScreenToggler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 8em;
  height: 8em;
  background: transparent;
  z-index: 999;
`

const ElectronMenuToggler = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 8em;
  height: 8em;
  background: transparent;
  z-index: 999;
`
