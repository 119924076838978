import styled from "styled-components/macro"

import { useStore } from "state/store"
import { useState } from "react"

const tabs = [
  {
    title: "Nine Elms",
    to: 0,
  },
  {
    title: "Lifestyle",
    to: 1,
  },
  {
    title: "Film",
    to: 2,
  },
]

const TAB_SIZE = 7

export default function GalleryTab() {
  const setActiveImage = useStore(s => s.setActiveImage)
  const setTabObject = useStore(s => s.setTabObject)

  const handleClick = (e, v) => {
    setTimeout(() => {
      setTabObject({ first: v.to, second: 0 })
    }, 20)
  }

  const [localTabIndex, set] = useState(0)
  return (
    <Holder>
      <Container>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={e => {
              e.stopPropagation()
              set(tab.to)
              setTimeout(() => {
                setActiveImage(null)
                handleClick(e, tab)
              }, 300)
            }}>
            {tab.title}
          </Tab>
        ))}
        <UnderLine idx={localTabIndex} />
      </Container>
    </Holder>
  )
}

const Holder = styled.div`
  position: fixed;
  bottom: 1.5em;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  /* background-color: #00000072; */
  padding: 0.5em;
  border-radius: 50px;
  z-index: 999;
`

const Container = styled.div`
  position: relative;
  width: ${TAB_SIZE * tabs.length}em;
  display: flex;
  flex-direction: row;
`

const Tab = styled.div`
  flex: 1;
  padding: 0.7em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
`

const UnderLine = styled.div`
  position: absolute;
  left: ${props => props.idx * TAB_SIZE + TAB_SIZE / 2}em;
  bottom: 0;
  z-index: 1;
  /* width: 5.5em; */
  /* height: 3px; */
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  background-color: #b48465;
  transition: all 0.3s ease-out;
  transform: translateX(-50%);
`
