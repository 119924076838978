import { useState, useEffect } from "react"

export default function useLongPress(callback = () => {}, ms = 500) {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId
    if (startLongPress) {
      timerId = setTimeout(callback, ms)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [callback, ms, startLongPress])

  const handleEvent = (e, v) => {
    e.stopPropagation()
    setStartLongPress(v)
  }

  return {
    onMouseDown: e => handleEvent(e, true),
    onMouseUp: e => handleEvent(e, false),
    onMouseLeave: e => handleEvent(e, false),
    onTouchStart: e => handleEvent(e, true),
    onTouchEnd: e => handleEvent(e, false),
  }
}
