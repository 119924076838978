import { LOCATIONS, LONDON_LOCATIONS } from "dataset/location"
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/web"
import { useRef } from "react"
import { useStore } from "state/store"

const colours = {
  food: "#aa8062",
  amenities: "#aa8062",
  leisure: "#aa8062",
  image: "#aa8062",
  travel: "#aa8062",
  retail: "#aa8062",
  education: "#aa8062",
}

function SVGElement({ d, id, x, y }) {
  const mapChangeClick = useStore(state => state.mapChangeClick)

  const circleRef = useRef()
  const textRef = useRef()
  const name = id.split("-")[0]
  const num = `${id}`.match(/\d+/g)

  const activeLocationTabIndex = useStore(s => s.activeLocationTabIndex)

  let show

  if (mapChangeClick) {
    show = name === LONDON_LOCATIONS[activeLocationTabIndex].name.toLowerCase()
  } else {
    show = name === LOCATIONS[activeLocationTabIndex].name.toLowerCase()
  }

  const setActiveItemIndex = useStore(state => state.setActiveItemIndex)
  const activeItemIndex = useStore(state => state.activeItemIndex)

  const active = activeItemIndex === id

  const { fill } = useSpring({
    fill: active ? "#1F2424" : colours[name],
  })

  return (
    <>
      {mapChangeClick ? (
        <>
          <a.circle
            ref={circleRef}
            onClick={() => {
              setActiveItemIndex(id)
            }}
            cx={x}
            cy={y - 0.25}
            r={active ? "2" : "1.3"}
            opacity={show ? 1 : 0}
            fill={fill}
          />
          <text
            onClick={() => {
              setActiveItemIndex(id)
            }}
            ref={textRef}
            x={x}
            y={y}
            textAnchor="middle"
            fill="#ffffffff"
            fontSize=".8"
            fontFamily="Bliss-Regular">
            {show && num}
          </text>
        </>
      ) : (
        <>
          <a.circle
            ref={circleRef}
            onClick={() => {
              setActiveItemIndex(id)
            }}
            cx={x + 6}
            cy={y - 4}
            r={active ? "52" : "35.4"}
            opacity={show ? 1 : 0}
            fill={fill}
          />
          <text
            onClick={() => {
              setActiveItemIndex(id)
            }}
            ref={textRef}
            x={x + 5}
            y={y + 2}
            textAnchor="middle"
            fill="#ffffffff"
            fontSize="20"
            fontFamily="Bliss-Regular">
            {show && num}
          </text>
        </>
      )}
    </>
  )
}

export default SVGElement
