import { useEffect } from "react"
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
} from "styled-components"
import { normalize } from "polished"
import Div100vh from "react-div-100vh"
import { useMeasure } from "react-use"
import { isMobile } from "react-device-detect"

import theme from "./theme"

//Multi carousel
import "react-multi-carousel/lib/styles.css"

//Lazy blur effect
import "react-lazy-load-image-component/src/effects/blur.css"

//Load primereact styles
import "theme/lara-light-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

import { device } from "./device"
import { useStore } from "state/store"

import PrimeReact from "primereact/api"
PrimeReact.ripple = true
PrimeReact.inputStyle = "filled"

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: #000000;
    font-family: Grande;
    font-Size: 16px;
    overflow: hidden;
    background-color: #171717;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    user-select: none;
    padding: 0;
    margin: 0;
    -webkit-touch-callout: none;
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background-color: #212121;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #b48465;
    border-radius: 10px;
    border: 4px solid #212121;
  }
`

const MobileContainer = styled(Div100vh)`
  width: 100vw;
  font-size: 2vh;
  @media ${device.pad} {
    font-size: 1.5vw;
  }
`

const DesktopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  font-size: 1.7vh;
  @media ${device.pad} {
    font-size: 1.5vw;
  }
`

export default function ThemeProvider({ children }) {
  const [ref, refMeasure] = useMeasure()
  const setIsPortrait = useStore(state => state.setIsPortrait)
  const setScreenRatio = useStore(state => state.setScreenRatio)

  useEffect(() => {
    setIsPortrait(refMeasure.height > refMeasure.width)
    setScreenRatio(refMeasure.width / refMeasure.height)
  }, [refMeasure])

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <GlobalStyle />
      {isMobile ? (
        <MobileContainer ref={ref}> {children} </MobileContainer>
      ) : (
        <DesktopContainer ref={ref}> {children} </DesktopContainer>
      )}
    </StyledComponentsThemeProvider>
  )
}
