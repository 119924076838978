export default function getSwipeDirection(e) {
  if (e._lastEventType !== "pointerup") {
    return null
  }

  const x = e.direction[0]
  const y = e.direction[1]

  let direction

  if (x === 0 && y !== 0) {
    y > 0 ? (direction = "up") : (direction = "down")
  }

  if (x > 0) {
    y > 0 ? (direction = "upleft") : (direction = "downleft")
  }

  if (x < 0) {
    y > 0 ? (direction = "upright") : (direction = "downright")
  }

  if (y === 0 && x !== 0) {
    x > 0 ? (direction = "left") : (direction = "right")
  }

  return direction
}
