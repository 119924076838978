import { useState, useEffect } from "react"
import { useIdleTimer } from "react-idle-timer"

import { useLocation, useNavigate } from "react-router-dom"

/**
 *
 * @param {Number} timeout | Time of inactivity in milliseconds
 * @returns
 */
export default function useScreenSaver(timeout) {
  const navigate = useNavigate()
  const location = useLocation()
  // Set timeout values
  // const timeout = t //1000 * 60 * 30
  // const promptTimeout = 3000 //1000 * 30

  // Time before idle
  const [, setRemaining] = useState(0)

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
  }

  const onIdle = () => {
    console.log(location.pathname)
    console.log("IDLE")

    if (location.pathname !== "/") {
      console.log("RESET")
      navigate("/")
      setTimeout(() => {
        window.location.reload()
      }, [400])
    }
    // setIsScreenSaver(true)
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
  }

  const onActive = () => {
    console.log("ACTIVE")
    // setActiveLights(apartments)
    // setIsScreenSaver(false)
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
  }

  const { getRemainingTime, isPrompted, isIdle } = useIdleTimer({
    timeout,
    onPrompt,
    onIdle,
    onActive,
  })

  useEffect(() => {
    return () => {
      // clearInterval(interval)
    }
  }, [isIdle])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return {}
}
