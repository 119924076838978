import { useSpring, animated, config as CONFIG } from "@react-spring/web"
import { useEffect, useRef, useState } from "react"

export function DrawSVG({ children, active, repeat, config }) {
  const ref = useRef()
  const [lengths, set] = useState(null)

  useEffect(() => {
    const svg = [...ref.current.children][0]
    const lengthsTemp = []
    const paths = [...svg.children]
    paths.forEach(p => lengthsTemp.push(p.getTotalLength()))
    set(lengthsTemp)
  }, [])

  return (
    <>
      <div style={{ display: "none" }} ref={ref}>
        {children}
      </div>
      {lengths && (
        <Elements config={config} active={active} repeat={repeat} lengths={lengths}>
          {children}
        </Elements>
      )}
    </>
  )
}

function Elements({ children, lengths, active, repeat, config = CONFIG.slow }) {
  const totallength = lengths.reduce((partialSum, a) => partialSum + a, 0)
  const svgProps = children.props
  const paths = svgProps.children

  const [{ offset }, apiOpac] = useSpring(() => ({
    from: { offset: totallength },
  }))

  useEffect(() => {
    apiOpac({
      offset: active ? 0 : totallength,
      loop: { reverse: repeat ? true : false, delay: 900 },
      config: { ...config, clamp: true },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, totallength, repeat])

  return (
    <svg {...svgProps}>
      {paths.map((c, i) => (
        <AnimatedPath
          {...c.props}
          key={i}
          offset={offset}
          length={lengths[i]}
          totallength={totallength}
        />
      ))}
    </svg>
  )
}

function AnimatedPath(props) {
  const interpolate = props.offset.to([0, props.totallength], [0, 1])
  const length = 1
  return (
    <animated.path
      pathLength={1}
      {...props}
      strokeDasharray={length}
      strokeDashoffset={interpolate}
      style={{
        pointerEvents: "auto",
      }}
    />
  )
}

export default DrawSVG
