//Three Bedrooms
import Bedroom2 from "assets/images/showhome/three/Bedroom2.jpg"
import Bedroom3 from "assets/images/showhome/three/Bedroom3.jpg"
import Ensuite from "assets/images/showhome/three/Ensuite.jpg"
import BathsHall from "assets/images/showhome/three/HallwayBathroom.jpg"
import BedsHall from "assets/images/showhome/three/HallwayBedrooms.jpg"
import EntryHall from "assets/images/showhome/three/HallwayEntry.jpg"
import KitchenHall from "assets/images/showhome/three/HallwayKitchen.jpg"
import Kitchen from "assets/images/showhome/three/Kitchen.jpg"
import KitchenLivingDining from "assets/images/showhome/three/KitchenLivingDining.jpg"
import Living from "assets/images/showhome/three/Living.jpg"
import Master from "assets/images/showhome/three/MasterBedroom.jpg"
import MasterEntry from "assets/images/showhome/three/MasterBedroomEntry.jpg"
import Shower from "assets/images/showhome/three/Shower.jpg"
import TerraceLiving from "assets/images/showhome/three/TerraceLiving.jpg"
import TerraceMasterBedroom from "assets/images/showhome/three/TerraceMasterBedroom.jpg"
//Two Bedrooms
import TBMaster from "assets/images/showhome/two/Bedroom1.jpg"
import TBBedroom from "assets/images/showhome/two/Bedroom2.jpg"
import TBBathroom from "assets/images/showhome/two/BathroomBoth.jpg"
import TBRoomBalcony from "assets/images/showhome/two/BalconyRoom.jpg"
import TBLivingBalcony from "assets/images/showhome/two/BalconyLiving.jpg"
import TBLiving from "assets/images/showhome/two/Living.jpg"
import TBKitchen from "assets/images/showhome/two/Kitchen.jpg"
import TBCorridor from "assets/images/showhome/two/Corridor.jpg"


import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"

useLoader.preload(TextureLoader, [
  KitchenLivingDining,
  EntryHall,
  Living,
  Bedroom2,
  Bedroom3,
  Ensuite,
  BathsHall,
  BedsHall,
  KitchenHall,
  Kitchen,
  KitchenLivingDining,
  MasterEntry,
  Shower,
  Master,
  Shower,
  TerraceLiving,
  TerraceMasterBedroom,
  TBMaster,
  TBBedroom,
  TBBathroom,
  TBRoomBalcony,
  TBLivingBalcony,
  TBLiving,
  TBKitchen,
  TBCorridor
])

const TOUR_LIST = {
  "1": [
    {
      id: 0,
      name: "Entry",
      file: EntryHall,
      hotspotPosition: [
        [(Math.PI / 1.8) * -0.99, Math.PI / 1.5, 1] /*hallway bathroom*/,
        [(Math.PI / 1.8) * -1.86, Math.PI / 1.5, 2] /*kitchen*/,
        [(Math.PI / 1.8) * -2.4, Math.PI / 1.5, 3] /*hallway kitchen*/,
      ],
    },
    {
      id: 1,
      name: "Bathroom Hall",
      file: BathsHall,
      hotspotPosition: [
        [Math.PI + 4.7, Math.PI / 1.5, 0] /*hallway entry */,
        [Math.PI + 0.6, Math.PI / 1.4, 4] /*ensuite */,
        [Math.PI + 1.23, Math.PI / 1.5, 5] /*hallway beds */,
      ],
    },
    {
      id: 2,
      name: "Kitchen",
      file: Kitchen,
      hotspotPosition: [
        [Math.PI + 3, Math.PI / 1.5, 0] /*hallway entry*/,
        [Math.PI - 0.3, Math.PI / 1.5, 13] /*living dining*/,
      ],
    },
    {
      id: 3,
      name: "Hallway Kitchen",
      file: KitchenHall,
      hotspotPosition: [
        [Math.PI - 4.2, Math.PI / 1.5, 0] /*hallway entry*/,
        [Math.PI - 1.2, Math.PI / 1.3, 14] /*bedroom 3*/,
      ],
    },
    {
      id: 4,
      name: "Bathroom",
      file: Ensuite,
      hotspotPosition: [[Math.PI * 2.3, Math.PI / 1.5, 1] /*hallway bathroom 1*/],
    },
    {
      id: 5,
      name: "Hallway Bedrooms",
      file: BedsHall,
      hotspotPosition: [
        [Math.PI * 2.36, Math.PI / 1.5, 1] /*hallway bathroom */,
        [Math.PI + 1.12, Math.PI / 1.2, 6] /*bedroom 2*/,
        [Math.PI - 0.15, Math.PI / 1.2, 7] /*master entrry*/,
      ],
    },
    {
      id: 6,
      name: "Bedroom Two",
      file: Bedroom2,
      hotspotPosition: [[Math.PI * 2.3, Math.PI / 1.5, 5] /*hallway bathroom */],
    },
    {
      id: 7,
      name: "Master Entry",
      file: MasterEntry,
      hotspotPosition: [
        [Math.PI - 3.2, Math.PI / 1.5, 5] /*hallway bedrooms */,
        [Math.PI - 1, Math.PI / 1.05, 8] /*ensuite */,
        [Math.PI, Math.PI / 1.5, 9] /*master */,
      ],
    },
    {
      id: 8,
      name: "Ensuite",
      file: Shower,
      hotspotPosition: [[Math.PI - 4.5, Math.PI / 1.1, 7] /*master entry */],
    },
    {
      id: 9,
      name: "Master",
      file: Master,
      hotspotPosition: [
        [Math.PI - 3.45, Math.PI / 1.5, 7] /*master entry */,
        [Math.PI, Math.PI / 1.2, 10] /*master terrace */,
      ],
    },
    {
      id: 10,
      name: "Master Terrace",
      file: TerraceMasterBedroom,
      hotspotPosition: [
        [Math.PI - 3.45, Math.PI / 1.5, 9] /*master*/,
        [Math.PI - 1.2, Math.PI / 1.5, 11] /*terrace living*/,
      ],
    },
    {
      id: 11,
      name: "Terrace Living",
      file: TerraceLiving,
      hotspotPosition: [
        [Math.PI + 1.2, Math.PI / 1.5, 10] /*master terrace */,
        [Math.PI - 3, Math.PI / 1.5, 12] /*living */,
      ],
    },
    {
      id: 12,
      name: "Living",
      file: Living,
      hotspotPosition: [
        [Math.PI + 0.4, Math.PI / 1.4, 11] /*living terrace */,
        [Math.PI - 3, Math.PI / 1.4, 13] /*living dining */,
      ],
    },
    {
      id: 13,
      name: "Living Dining",
      file: KitchenLivingDining,
      hotspotPosition: [
        [Math.PI, Math.PI / 1.4, 12] /*living  */,
        [Math.PI + 2.3, Math.PI / 1.4, 2] /*kitchen */,
      ],
    },
    {
      id: 14,
      name: "Bedroom Three",
      file: Bedroom3,
      hotspotPosition: [[Math.PI + 2.4, Math.PI / 1.4, 3] /*kitchen hall*/],
    },
  ],
  "2": [
    {
      id: 0,
      name: "Corridor",
      file: TBCorridor,
      hotspotPosition: [
        [Math.PI * 1.85, Math.PI / 1.5, 1] /*Living*/,
        [Math.PI * 1.25, Math.PI / 1.5, 2] /*Bedroom2*/,
        [Math.PI * 0.9, Math.PI / 1.8, 3] /*Master*/,
        [Math.PI * 0.8, Math.PI / 1.8, 4] /*Bathroom*/,
      ],
    },
    {
      id: 1,
      name: "Living",
      file: TBLiving,
      hotspotPosition: [
        [Math.PI * 0.7, Math.PI / 1.8, 0] /*Corridor */,
        [Math.PI * 0.3, Math.PI / 1.8, 5] /*Kitchen */,
        [Math.PI * 1.35, Math.PI / 1.8, 6] /*Living Balcony */,
      ],
    },
    {
      id: 2,
      name: "Bedroom",
      file: TBBedroom,
      hotspotPosition: [
        [Math.PI * 1.5, Math.PI / 1.5, 7] /*RoomBalcony*/,
        [Math.PI * 0.35, Math.PI / 1.8, 0] /*Corridor*/,
      ],
    },
    {
      id: 3,
      name: "Master",
      file: TBMaster,
      hotspotPosition: [
        [Math.PI * 1.45, Math.PI / 1.8, 0] /*Corridor*/,
        [Math.PI * 1.3, Math.PI / 1.8, 8] /*Ensuite*/,
      ],
    },
    {
      id: 4,
      name: "Bathroom",
      file: TBBathroom,
      hotspotPosition: [
        [Math.PI * 1.85, Math.PI / 1.4, 0] /*Corridor */,
      ],
    },
    {
      id: 5,
      name: "Kitchen",
      file: TBKitchen,
      hotspotPosition: [
        [Math.PI * 1.35, Math.PI / 1.8, 1] /*Living */,
      ],
    },
    {
      id: 6,
      name: "LivingBalcony",
      file: TBLivingBalcony,
      hotspotPosition: [
        [Math.PI * 2.2, Math.PI / 1.5, 1] /*Living */,
        [Math.PI * 0.8, Math.PI / 1.8, 7] /*RoomBalcony */,
      ],
    },
    {
      id: 7,
      name: "RoomBalcony",
      file: TBRoomBalcony,
      hotspotPosition: [
        [Math.PI * 1.65, Math.PI / 1.8, 6] /*LivingBalcony */,
        [Math.PI * 0.3, Math.PI / 1.8, 2] /*Room */,
      ],
    },
    {
      id: 8,
      name: "Ensuite",
      file: TBBathroom,
      hotspotPosition: [
        [Math.PI * 1.85, Math.PI / 1.4, 3] /*Master */,
      ],
    },
    
  ],
}

export default TOUR_LIST
