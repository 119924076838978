import ResidenceView from "assets/images/orbits/residence-view.svg"
import Entrance from "assets/images/orbits/entrance-orbit1.svg"
import EntranceBack from "assets/images/orbits/entrance-back.svg"
import Gardens from "assets/images/orbits/gardens.svg"
import CityViewsOrbit3 from "assets/images/orbits/city-views-orbit3.svg"
import CityViewsOrbit5 from "assets/images/orbits/city-views-orbit5.svg"
import GardensOrbit4 from "assets/images/orbits/gardens-orbit4.svg"
import ResidenceViewsOrbit4 from "assets/images/orbits/residence-views-orbit4.svg"
import ResidenceViewsBack from "assets/images/orbits/residence-views-back.svg"
import NineElmsPark from "assets/images/orbits/nine-elms-park.svg"

export const orbits = [
  {
    orbit: "1-FW",
    startAt: 1000,
    endAt: 3290,
  },
  {
    orbit: "2-FW",
    startAt: 3290,
    endAt: 6153,
  },
  {
    orbit: "3-FW",
    startAt: 6153,
    endAt: 8653,
  },
  {
    orbit: "4-FW",
    startAt: 8653,
    endAt: 11650,
  },
  {
    orbit: "5-FW",
    startAt: 11650,
    endAt: 14160,
  },
  {
    orbit: "5-BW",
    startAt: 14160,
    endAt: 16900,
  },
  {
    orbit: "4-BW",
    startAt: 16900,
    endAt: 19404,
  },
  {
    orbit: "3-BW",
    startAt: 19404,
    endAt: 22404,
  },
  {
    orbit: "2-BW",
    startAt: 22404,
    endAt: 25300,
  },
  {
    orbit: "1-BW",
    startAt: 25300,
    endAt: 28500,
  },
]

export const orbitViews = [
  {
    orbitIndex: 1,
    caption: {
      text: [
        "There is a London address where",
        " absolute quality meets",
        " beautifully",
        "elevated living.",
      ],
      style: {
        top: "0",
        right: "2rem",
      },
    },
    views: [
      {
        view: ResidenceView,
        className: "left",
        style: {
          top: "6%",
          left: "20%",
          height: "10em",
        },
      },
      {
        view: Entrance,
        className: "right",
        style: {
          top: "20%",
          left: "41%",
          height: "32em",
        },
      },
    ],
  },
  {
    orbitIndex: 2,
    caption: {
      text: [
        "Welcome to London Square Nine Elms.",
        "Our ultra-exclusive collection",
        "of 186 private apartments.",
      ],
      style: {
        right: "2rem",
        top: "0",
      },
    },
    views: [
      {
        view: ResidenceViewsBack,
        className: "left",
        style: {
          top: "76%",
          left: "0",
          height: "9rem",
        },
      },
      {
        view: Gardens,
        className: "right",
        style: {
          top: "75%",
          left: "55%",
          height: "5em",
        },
      },
    ],
  },
  {
    orbitIndex: 3,
    caption: {
      text: ["Nine Elms", "has been designed to perfectly", "balance with", "its surroundings."],
      style: {
        left: "10%",
        top: "0",
      },
    },
    views: [
      {
        view: EntranceBack,
        className: "left",
        style: {
          top: "23%",
          left: "51%",
          height: "15em",
        },
      },
      {
        view: CityViewsOrbit3,
        className: "right",
        style: {
          top: "0",
          left: "52%",
          height: "4em",
        },
      },
    ],
  },
  {
    orbitIndex: 4,
    caption: {
      text: [
        "Where breathtaking views stretch",
        "across to the beating heart",
        "of the world’s",
        "most vibrant city.",
      ],
      style: {
        top: "0",
        left: "10%",
      },
    },
    views: [
      {
        view: GardensOrbit4,
        className: "left",
        style: {
          top: "50%",
          left: "37%",
          height: "22em",
        },
      },
      {
        view: ResidenceViewsOrbit4,
        className: "right",
        style: {
          top: "5%",
          left: "62%",
          height: "15em",
        },
      },
    ],
  },
  {
    orbitIndex: 5,
    caption: {
      text: [
        "Every home features a richness",
        "of specifications.",
        "Modern interiors give way",
        "to dazzling",
        "balcony vistas.",
      ],
      style: {
        top: "0",
        right: "5%",
      },
    },
    views: [
      {
        view: CityViewsOrbit5,
        className: "left",
        style: {
          top: "20%",
          left: "40%",
          height: "16em",
        },
      },
      {
        view: NineElmsPark,
        className: "right",
        style: {
          top: "80%",
          left: "71%",
          height: "12em",
        },
      },
    ],
  },
  {
    orbitIndex: 1,
    caption: {
      text: [
        "There is a London address where",
        " absolute quality meets",
        " beautifully",
        "elevated living.",
      ],
      style: {
        top: "0",
        right: "2rem",
      },
    },
    views: [
      {
        view: ResidenceView,
        className: "left",
        style: {
          top: "6%",
          left: "15%",
          height: "10em",
        },
      },
      {
        view: Entrance,
        className: "right",
        style: {
          top: "20%",
          left: "39%",
          height: "32em",
        },
      },
    ],
  },
]
