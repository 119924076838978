import { createContext, useContext, useEffect, useState } from "react"

const WebSocketContext = createContext({
  send: () => {},
  socket: null,
})

export const useWebSocket = () => useContext(WebSocketContext)

const connect = url => {
  const socket = new WebSocket(url)

  return socket
}

const isWeb =
  (typeof window !== "undefined" &&
    window.location &&
    window.location?.href?.startsWith("https://")) ||
  window.location?.href.startsWith("http://")

export function getIsWeb() {
  return isWeb
}

/**
 * WebSocketProvider is a React provider component that provides a WebSocket connection to its children.
 * @params props - The props of the component.
 * @param props.url - The url to connect to the websocket server with (e.g. ws://192.168.1.1:3030)
 * @returns A React component.
 */
export const WebSocketProvider = ({ url, children }) => {
  const [newSocket, setNewSocket] = useState(null)

  const tryConnect = () => {
    const socket = connect(url)

    socket.onopen = () => {
      console.log("WebSocket connection established")
      setNewSocket(socket)
    }

    socket.onmessage = event => {
      console.log("WebSocket message received from Thorp")
    }

    socket.onerror = error => {
      console.error("WebSocket error:", error)
      socket.close()
    }

    socket.onclose = () => {
      console.log("WebSocket connection closed")
      setTimeout(tryConnect, 1000)
    }
  }

  useEffect(() => {
    // Only connect if we are on electron or ipad
    if (isWeb) return

    tryConnect()

    return () => {
      newSocket && newSocket.close()
    }
  }, [])

  const send = message => {
    if (newSocket) {
      newSocket.send(message)
    }
  }

  return (
    <WebSocketContext.Provider value={{ send, socket: newSocket }}>
      {children}
    </WebSocketContext.Provider>
  )
}
