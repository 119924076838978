import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { a, useTransition } from "react-spring"
import { useDrag } from "react-use-gesture"

import ShareCardMobile from "./ShareCardMobile"
import getSwipeDirection from "utils/getSwipeDirection"

function ShareSwiperMobile({ openState, favorites }) {
  const [currentCard, setCurrentCard] = useState(0)
  const [swiperActive, setSwiperActive] = useState(true)
  const swiped = useRef(false)
  const wrapperTransition = useTransition(openState[0], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  const swipeDir = useRef(null)

  const drag = useDrag(
    e => {
      swiped.current = true
     const direction = getSwipeDirection(e)
     swipeDir.current = !direction?.includes("right")
     if (e._lastEventType === "pointerup") {
       if (direction?.includes("left")) {
           currentCard === 0
             ? setCurrentCard(favorites?.length - 1)
             : setCurrentCard(currentCard - 1)
       } else {
         if (direction?.includes("right")) {
           currentCard === favorites?.length - 1
             ? setCurrentCard(0)
             : setCurrentCard(currentCard + 1)
         }
       }
     }
   },
   { enabled: swiperActive },
 )

  return wrapperTransition(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm} {...drag()}>
          {favorites.map((fav, i) => {
            return (
              <ShareCardMobile
                apartment={fav}
                key={`sharedCard${i}`}
                isOpen={currentCard === i}
                direction={swipeDir.current}
                action={openState[1]}
                setSwiperActive={setSwiperActive}
              />
            )
          })}
          {!swiped.current ? <Icon src={`${process.env.PUBLIC_URL}/assets/images/share/swipe.gif`} alt="swipe-icon"/> : null}
        </Wrapper>
      ),
  )
}

export default ShareSwiperMobile

const Wrapper = styled(a.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  touch-action: none;
`

const Icon = styled.img`
  position: absolute;
  z-index: 9;
  max-width: 3rem;
  left: 90%;
  bottom: 3%;
  transform: translateX(-50%);
`