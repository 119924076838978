export default function POICultureIcon({ active = false }) {
  return (
    <svg width="36" height="43" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.7973 4.70799H0.544922V0.293457H35.7973V4.70799ZM1.25228 3.9154H35.0872V1.08605H1.25228V3.9154Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M31.7068 26.4405H4.64002C4.00147 26.4405 3.47852 25.8598 3.47852 25.1441C3.47852 24.4283 3.99871 23.8477 4.64002 23.8477H31.7068C32.3454 23.8477 32.8684 24.4283 32.8684 25.1441C32.8684 25.8598 32.3482 26.4405 31.7068 26.4405ZM4.64002 24.6433C4.3923 24.6433 4.18863 24.8676 4.18863 25.1471C4.18863 25.4267 4.38955 25.6509 4.64002 25.6509H31.7068C31.9546 25.6509 32.1582 25.4267 32.1582 25.1471C32.1582 24.8676 31.9573 24.6433 31.7068 24.6433H4.64002Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M31.8852 42.411H4.45508V25.6499H31.8852V42.411ZM5.16519 41.6184H31.1751V26.4394H5.16244V41.6184H5.16519Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M17.2943 42.4109H12.7363V31.0505C12.7363 29.6496 13.7575 28.5068 15.0153 28.5068C16.2731 28.5068 17.2943 29.6466 17.2943 31.0505V42.4109ZM13.4464 41.6183H16.5841V31.0505C16.5841 30.0859 15.8795 29.2994 15.0153 29.2994C14.151 29.2994 13.4464 30.0859 13.4464 31.0505V41.6183Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M24.2904 42.4109H19.7324V31.0505C19.7324 29.6496 20.7536 28.5068 22.0114 28.5068C23.2692 28.5068 24.2904 29.6466 24.2904 31.0505V42.4109ZM20.4425 41.6183H23.5802V31.0505C23.5802 30.0859 22.8756 29.2994 22.0114 29.2994C21.1471 29.2994 20.4425 30.0859 20.4425 31.0505V41.6183Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M29.6753 42.4113H26.6367V30.206C26.6367 29.2721 27.3193 28.5103 28.156 28.5103C28.9928 28.5103 29.6753 29.2721 29.6753 30.206V42.4113ZM27.3441 41.6187H28.9652V30.206C28.9652 29.7084 28.6019 29.3028 28.156 29.3028C27.7101 29.3028 27.3468 29.7084 27.3468 30.206V41.6187H27.3441Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M10.3941 42.4113H7.35547V30.206C7.35547 29.2721 8.03806 28.5103 8.87478 28.5103C9.7115 28.5103 10.3941 29.2721 10.3941 30.206V42.4113ZM8.06283 41.6187H9.68398V30.206C9.68398 29.7084 9.32066 29.3028 8.87478 29.3028C8.4289 29.3028 8.06558 29.7084 8.06558 30.206V41.6187H8.06283Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M18.814 2.67149V1.87891V2.67149H2.69336V1.87891H18.814V2.67149Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M20.5636 1.87891H19.6855V2.67149H20.5636V1.87891Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M10.3875 17.195H9.67737V7.44126C9.67737 5.93289 8.57917 4.70714 7.22776 4.70714H6.91949C5.56807 4.70714 4.46988 5.93289 4.46988 7.44126C4.46988 8.50419 5.2433 9.36744 6.19562 9.36744C7.14794 9.36744 7.92135 8.50419 7.92135 7.44126V6.58109H8.63147V7.44126C8.63147 8.94042 7.53877 10.16 6.19562 10.16C4.85246 10.16 3.75977 8.94042 3.75977 7.44126C3.75977 5.49666 5.17724 3.91455 6.91949 3.91455H7.22776C8.97001 3.91455 10.3875 5.49666 10.3875 7.44126V17.1919V17.195Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M6.07247 13.2867C5.92109 13.2867 5.76695 13.2806 5.60731 13.2714C3.90635 13.1608 2.37328 12.104 1.50628 10.4451C0.545701 8.61109 0.545701 6.31012 1.50077 4.13818L2.13657 4.4884C1.28609 6.42072 1.28058 8.44827 2.11731 10.0488C2.86595 11.4804 4.18434 12.3897 5.6486 12.4849C8.74777 12.6846 9.67532 10.6356 9.71386 10.5495L10.3552 10.8905C10.3111 10.9919 9.301 13.2929 6.07247 13.2929V13.2867Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M26.7414 17.195H26.0312V7.44126C26.0312 5.49666 27.4487 3.91455 29.191 3.91455H29.4992C31.2415 3.91455 32.659 5.49666 32.659 7.44126C32.659 8.94042 31.5663 10.16 30.2231 10.16C28.88 10.16 27.7873 8.94042 27.7873 7.44126V6.58109H28.4974V7.44126C28.4974 8.50419 29.2708 9.36744 30.2231 9.36744C31.1754 9.36744 31.9489 8.50419 31.9489 7.44126C31.9489 5.93289 30.8507 4.70714 29.4992 4.70714H29.191C27.8396 4.70714 26.7414 5.93289 26.7414 7.44126V17.1919V17.195Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M30.3491 13.287C27.1178 13.287 26.1104 10.9861 26.0664 10.8847L26.7077 10.5437C26.7462 10.6297 27.6738 12.6818 30.773 12.4791C32.2345 12.3838 33.5556 11.4745 34.3043 10.0429C35.141 8.44241 35.1355 6.41486 34.285 4.48254L34.9208 4.13232C35.8759 6.30733 35.8731 8.60523 34.9153 10.4392C34.0483 12.0981 32.5125 13.1549 30.8143 13.2655C30.6546 13.2747 30.5005 13.2809 30.3491 13.2809V13.287Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M35.2156 22.2153H34.5055C34.5055 19.6655 32.6477 17.5919 30.3632 17.5919H6.079C3.79453 17.5919 1.93667 19.6655 1.93667 22.2153H1.22656C1.22656 19.2293 3.40369 16.7993 6.079 16.7993H30.3632C33.0385 16.7993 35.2156 19.2293 35.2156 22.2153Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M35.8001 24.6425H0V21.8193H35.8001V24.6425ZM0.710112 23.85H35.09V22.6089H0.710112V23.85Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M19.9758 13.2884H16.7775C15.8417 13.2884 15.082 12.4405 15.082 11.396V5.80791C15.082 4.76341 15.8417 3.91553 16.7775 3.91553H19.9758C20.9116 3.91553 21.6712 4.76341 21.6712 5.80791V11.396C21.6712 12.4405 20.9116 13.2884 19.9758 13.2884ZM16.7775 4.70812C16.2353 4.70812 15.7921 5.20272 15.7921 5.80791V11.396C15.7921 12.0012 16.2353 12.4958 16.7775 12.4958H19.9758C20.518 12.4958 20.9611 12.0012 20.9611 11.396V5.80791C20.9611 5.20272 20.518 4.70812 19.9758 4.70812H16.7775Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M20.4466 17.5897H16.3125V12.4932H20.4466V17.5897ZM17.0199 16.7971H19.7365V13.2858H17.0199V16.7971Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M20.4466 13.2884H16.3125V3.91553H20.4466V13.2884ZM17.0199 12.4958H19.7365V4.70812H17.0199V12.4958Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M5.98746 12.876H5.27734V17.1922H5.98746V12.876Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
      <path
        d="M31.1964 12.876H30.4863V17.1922H31.1964V12.876Z"
        fill={active ? "#f6f2ee" : "#200a0b"}
      />
    </svg>
  )
}
