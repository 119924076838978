import { useTrail, a } from "@react-spring/web"
import { useEffect } from "react"
import { useStore } from "state/store"
import styled from "styled-components"

function AnimatedText({ text, delay, fontSize }, props) {
  const letters = text.split("")
  const interacted = useStore(s => s.interacted)
  const modelsLoaded = useStore(s => s.modelsLoaded)

  const [trail, api] = useTrail(
    letters.length,
    () => ({
      from: { opacity: 0 },
    }),
    [],
  )

  useEffect(() => {
    if (interacted && modelsLoaded) {
      api.start({ opacity: 0 })
    }
  }, [interacted, api, modelsLoaded])

  useEffect(() => {
    setTimeout(() => {
      api.start({ opacity: 1 })
    }, delay)
  }, [])

  return (
    <TextWrapper {...props} style={{ fontSize: fontSize }}>
      {trail.map(({ ...rest }, index) => (
        <a.div key={index}>
          <a.span style={{ ...rest }}>{letters[index] === " " ? <Space /> : letters[index]}</a.span>
        </a.div>
      ))}
    </TextWrapper>
  )
}

const Space = styled.div`
  min-width: 0.4em;
`

const TextWrapper = styled.div`
  color: #d1ae97;
  display: flex;
  /* flex-wrap: wrap; */
`
export default AnimatedText
