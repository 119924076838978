export const convert2Date = date => {
  const dd = String(date.getDate()).padStart(2, "0")
  const mm = String(date.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = date.getFullYear()

  return mm + "/" + dd + "/" + yyyy
}

export const priceFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 0,
})

export function availability(n, value){
  switch (n) {
    case "0":
      return "UNRELEASED"
    case "1":
      return priceFormatter.format(parseInt(value))
    case "2":
      return "RESERVED"
    case "3":
      return "SOLD"
    default:
      break;
  }
} 
