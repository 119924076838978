import * as THREE from "three"
import { nanoid } from "nanoid"
import { extend } from "@react-three/fiber"
import glsl from "babel-plugin-glsl/macro"

import { animated } from "@react-spring/three"

function shaderMaterial(uniforms, vertexShader, fragmentShader, onInit) {
  const material = class material extends THREE.RawShaderMaterial {
    constructor() {
      const entries = Object.entries(uniforms) // Create unforms and shaders

      super({
        uniforms: entries.reduce((acc, [name, value]) => {
          const uniform = THREE.UniformsUtils.clone({
            [name]: {
              value,
            },
          })
          return { ...acc, ...uniform }
        }, {}),
        vertexShader,
        fragmentShader,
      }) // Create getter/setters
      // this.extensions = {
      //   derivatives: false, // set to use derivatives
      //   fragDepth: false, // set to use fragment depth values
      //   drawBuffers: false, // set to use draw buffers
      //   shaderTextureLOD: false, // set to use shader texture LOD
      // }
      this.key = ""
      entries.forEach(([name]) =>
        Object.defineProperty(this, name, {
          get: () => this.uniforms[name].value,
          set: v => (this.uniforms[name].value = v),
        }),
      )
      if (onInit) onInit(this)
    }
  }
  material.key = nanoid()
  return material
}

const ShaderMat = shaderMaterial(
  //uniform
  {
    uDiffuse: new THREE.Texture(),
    uIntensity: 1,
    uOpacity: 0,
  },
  glsl`
    precision mediump float;

    // attribute float uvAtt;
    // attribute vec3 position;
    // attribute vec2 uv;
    in float uvAtt;
    in vec3 position;
    in vec2 uv;
    uniform vec2 resolution;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;

    out float vuvAtt;
    out vec2 vUv;
    out vec3 pos;

    void main(){
      vuvAtt=uvAtt;
      vUv=uv;
      pos=position;
      gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.);
    }

`,
  //FRAGMENT
  glsl`
    precision mediump float;

    uniform sampler2D uDiffuse;
    uniform float uIntensity;
    uniform float uOpacity;

    // varying vec2 vUv;
    // varying vec3 pos;
   
    in vec2 vUv;
    in vec3 pos;
   
    out vec4 fragColor;

    float sRGB(float x) {
    if (x <= 0.00031308)
        return 12.92 * x;
    else
        return 1.055*pow(x,(1.0 / 2.4) ) - 0.055; 
    }


    vec3 sRGB_v3(vec3 c) {
        return vec3(sRGB(c.x),sRGB(c.y),sRGB(c.z));
    }

    
    void main()
    {
      
    vec4 diffuse =  texture( uDiffuse, vUv);
    vec4 col = vec4(diffuse.rgb * 0.7, 1.);  
    fragColor =  vec4(sRGB_v3(vec3(diffuse.rgb)), 1.) ;
}
`,
)

extend({ ShaderMat })

const AnimatedBakedShader = animated("ShaderMat")

function AnimatedBakedShaderMaterial({ map, opacity }) {
  return (
    <AnimatedBakedShader
      uOpacity={opacity}
      uDiffuse={map}
      glslVersion={THREE.GLSL3}
      flatShading={true}
      transparent={1}
      attach="material"
    />
  )
}

export default AnimatedBakedShaderMaterial
