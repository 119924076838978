import React from "react"
import LocalPOIs from "./LocalPOIs"
import BgImage from "assets/images/location/map_base.png"
import BgImage2 from "assets/images/location/london_station_base.png"
import Cameras from "../../../dataset/Cameras"
import { useStore } from "state/store"

function LocalMap() {
  const mapChangeClick = useStore(state => state.mapChangeClick)
  return (
    <>
      {mapChangeClick ? (
        <>
          <svg
            style={{
              width: "87%",
              height: "100%",
            }}
            id="a"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 123.11 65.21">
            <image href={BgImage2} width="100%" height="100%" />
            <LocalPOIs />
          </svg>
        </>
      ) : (
        <>
          <svg
            style={{
              width: "87%",
              height: "100%",
            }}
            id="a"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2732 2048">
            <image href={BgImage} width="100%" height="100%" />
            <LocalPOIs />
            <Cameras />
          </svg>
        </>
      )}
    </>
  )
}

export default LocalMap
