import styled from "styled-components"
import { a, useTransition } from "react-spring"
import anm from "../animations/animation"
import { apartmentPolygons } from "dataset/apartmentPolygons"
import { APART_PROPS } from "dataset/finder"
import { useApartments } from "utils/useApartments"

export const colors = {
  1: "#ffc251",
  2: "#c88d59",
  3: "#b86941",
  4: "#000000",
}

function ApartmentPolygon({ isFav, isResi, apartment, data, floor, handleClick, allPlots }) {
  const apartmentIndex = data.index
  const id = `${floor}${apartmentIndex}`
  const thisApartment = allPlots[id]
  const isActive = apartment?.[APART_PROPS.unit] === id
  const isAvailable = thisApartment?.["Availability"] === "1"
  const color = colors[thisApartment?.[APART_PROPS.beds]]
  const availableColor = isAvailable ? color : "#928c8c"
  const fill = isActive ? (isFav ? color : "#fefefe29") : "#2b2b2b6a"
  const opacity = isFav ? 0 : isActive ? 1 : 0.6

  return (
    <g id={id} onClick={e => handleClick(e, id)}>
      <polygon
        fill={fill}
        stroke="#B38562"
        strokeWidth="2"
        strokeMiterlimit="10"
        points={data.points}
      />
      <Text
        style={{ opacity: opacity, fill: availableColor }}
        x={data.textPos.x}
        y={data.textPos.y}
        className={isResi ? "resiText" : undefined}>
        {id}
      </Text>
    </g>
  )
}

function FloorPlateElement({
  isFav,
  isResi = undefined,
  apartment,
  planDataID,
  floor,
  handleClick,
}) {
  const transition = useTransition(floor, anm)
  const { allPlots } = useApartments()
  const planData = apartmentPolygons[planDataID]
  return (
    <>
      {transition(
        (styles, loaded) =>
          loaded && (
            <Wrapper style={styles}>
              {planData.map((data, i) => (
                <ApartmentPolygon
                  key={i}
                  isFav={isFav}
                  isResi={isResi}
                  allPlots={allPlots}
                  apartment={apartment}
                  handleClick={handleClick}
                  floor={loaded}
                  data={data}
                  index={i}
                />
              ))}
              <Text x={300} y={300} textAnchor="middle" className="floorText">
                {loaded}
              </Text>
            </Wrapper>
          ),
      )}
    </>
  )
}

export default FloorPlateElement

const Wrapper = styled(a.g)`
  & text {
    fill: #edd8c8;
    font-size: 30px;
    font-family: Grande;
    cursor: pointer;
  }

  .floorText {
    font-size: 70px;
    cursor: unset;
    fill: #b38562;
  }

  .resiText {
    font-size: 25px;
  }
`

const Text = styled.text``
