const dimensions = {
  arundel: {
    "KITCHEN/LIVING": "6.51m x 4.37m | 21’4” x 14’4”",
    "BEDROOM 1": "5.53m X 2.89m | 18’2” X 9’6”",
    "BEDROOM 2": "3.08m X 3.74m | 10’1” X 12’3”",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  arundel2: {
    "KITCHEN/LIVING": "6.51m x 4.37m | 21’4” x 14’4”",
    "BEDROOM 1": "5.53m X 2.89m | 18’2” X 9’6”",
    "BEDROOM 2": "3.08m X 3.74m | 10’1” X 12’3”",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  belgrave: {
    "KITCHEN/LIVING": "6.50m x 4.21m | 21’4” x 13’10”",
    "BEDROOM 1": "5.53m x 2.88m | 18’2” x 9’5” ",
    "BEDROOM 2": "3.98m x 3.40m | 13’1” x 11’2”",
    "BEDROOM 3": "3.10m x 4.36m | 10’2” x 14’4",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  bloomsbury: {
    KITCHEN: "2.14m x 6.20m | 7’0” x 20’4”",
    "LIVING/DINING": "4.55m x 3.24m | 14’11” x 10’8”",
    BEDROOM: "2.99m x 4.70m | 9’10” x 15’5”",
    BALCONY: "3.89m x 1.63m | 12’9” x 5’4”",
  },
  egerton: {
    "KITCHEN/DINING": "3.19m x 5.99m | 10’6” x 19’8”",
    LIVING: "3.41m x 3.37m | 11’2” x 11’1”",
    "BEDROOM 1": "4.34m x 3.49m | 14’3” x 11’5”",
    "BEDROOM 2": "4.34m x 2.82m | 14’3” x 9’3” ",
    BALCONY: "1.63m x 3.89m | 5’4” x 12’9”",
  },
  finsbury: {
    LIVING: "3.60m x 3.78m | 11’10” x 12’05”",
    "KITCHEN/DINING": "3.72m x 7.29m | 12’2” x 23’11",
    "BEDROOM 1": "5.24m x 3.02m | 17’2” x 9’11”",
    "BEDROOM 2": "4.79m x 2.91m | 15’8” x 9’6”",
    "BEDROOM 3": "2.63m x 3.77m | 8’7” x 12’4”",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  grosvenor: {
    "KITCHEN/DINING": "3.66m x 3.95m | 12’0” x 12’11”",
    LIVING: "3.00m x 2.66m | 9’10” x 8’9”",
    BEDROOM: "3.56m x 3.40m | 11’8” x 11’2”",
    BALCONY: "1.63m x 3.89m | 5’4” x 12’9”",
  },
  harrington: {
    "KITCHEN/LIVING": "3.19m x 6.65m | 10’5½” x 21’10”",
    BEDROOM: "4.28m x 3.13m | 14’0½” x 10’3”",
    BALCONY: "2.95m x 1.59m | 9’8” x 5’2½”",
  },
  kensington: {
    "KITCHEN/LIVING": "6.33m x 3.80m | 20’9” x 12’6”",
    "BEDROOM 1": "4.96m X 3.00m | 16’3” X 9’10”",
    "BEDROOM 2": "2.36m X 3.68m | 7’9” X 12’1”",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  sloane: {
    "KITCHEN/DINING": "6.43m x 3.58m | 21’1” x 11’9”",
    LIVING: "3.36m x 2.96m | 11’0” x 9’9”",
    "BEDROOM 1": "3.36m x 2.80m | 11’0” x 9’2”",
    "DRESSER/STUDY": "2.20m x 2.29m | 7’3” x 7’6”",
    "BEDROOM 2": "4.15m x 3.10m | 13’7” x 10’2”",
    "BEDROOM 3": "3.20m x 4.05m | 10’6” x 13’3”",
    BALCONY: "1.63m x 7.92m | 5’4” x 26’0”",
  },
  tavistock: {
    "KITCHEN/LIVING": "6.51m x 4.37m | 21’4” x 14’4”",
    "BEDROOM 1": "5.53m X 2.89m | 18’2” X 9’6”",
    "BEDROOM 2": "3.08m X 3.74m | 10’1” X 12’3” ",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  trafalgar: {
    "KITCHEN/LIVING": "5.92m x 4.37m | 19’5” x 14’4”",
    "BEDROOM 1": "5.53m X 2.89m | 18’2” X 9’6”",
    "BEDROOM 2": "3.08m X 3.74m | 10’1” X 12’3” ",
    BALCONY: "1.59m x 6.04m | 5’3” x 19’10”",
  },
  wilton: {
    "KITCHEN/LIVING": "5.08m x 4.13m | 16’8” x 13’7”",
    BEDROOM: "3.56m x 3.20m | 11’10” x 10’6”",
    BALCONY: "1.59m x 2.95m | 5’3” x 9’8” ",
  },
  chester: {
    "PRINCIPAL BEDROOM": "3.80m x 3.49m | 12'6\" x 11'5\"",
    "PRINCIPAL BATHROOM": "3.70m x 2.59m | 12'2\" x 8'6\"",
    "BEDROOM 2": "3.17m x 2.92m | 10'5\" x 9'7\"",
    "BEDROOM 3": "4.38m x 2.89m | 14'5\" x 9'6\"",
    "BALCONY": "1.48m x 7.92m | 4'10\" x 26'0\"",
    "KITCHEN": "5.16m x 3.05m | 16'11\" x 10'0\"",
    "DINING ROOM": "3.53m x 3.37m | 11'7\" x 11'1\"",
    "LIVING ROOM": "6.75m x 4.02m | 22'2\" x 13'2\"",
    "TERRACE 1": "14.00m x 2.10m | 45'11\" x 6'10\"",
    "TERRACE 2": "7.50m x 2.30m | 24'7\" x 7'6\"",
    "TERRACE 3": "10.30m x 2.10m | 33'9\" x 6'10\""
  },
}

export default dimensions
