import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components/macro"
import { useStore } from "state/store"
import Icon from "assets/images/showhome/360.svg"

export default function RoomSelector() {
  const homeState = useStore(state => state.homeState)
  const setHomeState = useStore(state => state.setHomeState)
  return (
    <AnimatePresence>
      {homeState === 0 && (
        <Holder initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Content>
            <Title>Explore</Title>
            <div className="mt-4 flex align-items-center gap-3">
              <Divider />
              <Link
                onClick={() => {
                  setHomeState(2)
                }}>
                Two bedroom
              </Link>
                <Divider />
              <Link
                onClick={() => {
                  setHomeState(1)
                }}>
                Three bedroom
              </Link>
                <Divider />
            </div>
            <div className="mt-6">
              <Img src={Icon} alt="" />
            </div>
          </Content>
        </Holder>
      )}
    </AnimatePresence>
  )
}

const Holder = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000aa;
`

const Title = styled.p`
  font-family: Grande;
  font-size: 7em;
  color: #b58762;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`

const Link = styled.p`
  font-family: Grande;
  font-size: 1em;
  color: #f2f3f4;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
`

const Divider = styled.span`
  width: 1px;
  height: 2em;
  background-color: #b48465;
`

const Img = styled.img`
  width: 6em;
`
