import React from "react"
import styled from "styled-components"

function AboutCardFive({ svg }) {
  return (
    <Wrapper>
      <img src={svg} alt="" />
    </Wrapper>
  )
}

export default AboutCardFive

const Wrapper = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #b58762;
  padding: 0.5rem;

  & img {
    height: 80vh;
    width: auto;
    padding: 1% 1%;
    background-color: white;
  }
`
