import nineelmsconcierge1 from "assets/images/gallery/nineelms/concierge/1.jpg"
import nineelmsconcierge2 from "assets/images/gallery/nineelms/concierge/2.jpg"
import nineelmsconcierge3 from "assets/images/gallery/nineelms/concierge/3.jpg"
import nineelmsconcierge4 from "assets/images/gallery/nineelms/concierge/4.jpg"
import nineelmsconcierge5 from "assets/images/gallery/nineelms/concierge/5.jpg"
import nineelmsoutside1 from "assets/images/gallery/nineelms/outside/1.jpg"
import nineelmsoutside2 from "assets/images/gallery/nineelms/outside/2.jpg"
import nineelmsoutside3 from "assets/images/gallery/nineelms/outside/3.jpg"
import nineelmsoutside4 from "assets/images/gallery/nineelms/outside/4.jpg"
import nineelmsoutside5 from "assets/images/gallery/nineelms/outside/5.jpg"
import nineelmsoutside6 from "assets/images/gallery/nineelms/outside/6.jpg"
import nineelmsoutside7 from "assets/images/gallery/nineelms/outside/7.jpg"
import nineelmsresidence1 from "assets/images/gallery/nineelms/residence/1.jpg"
import nineelmsresidence10 from "assets/images/gallery/nineelms/residence/10.jpg"
import nineelmsresidence11 from "assets/images/gallery/nineelms/residence/11.jpg"
import nineelmsresidence12 from "assets/images/gallery/nineelms/residence/12.jpg"
import nineelmsresidence13 from "assets/images/gallery/nineelms/residence/13.jpg"
import nineelmsresidence14 from "assets/images/gallery/nineelms/residence/14.jpg"
import nineelmsresidence15 from "assets/images/gallery/nineelms/residence/15.jpg"
import nineelmsresidence16 from "assets/images/gallery/nineelms/residence/16.jpg"
import nineelmsresidence17 from "assets/images/gallery/nineelms/residence/17.jpg"
import nineelmsresidence18 from "assets/images/gallery/nineelms/residence/18.jpg"
import nineelmsresidence19 from "assets/images/gallery/nineelms/residence/19.jpg"
import nineelmsresidence2 from "assets/images/gallery/nineelms/residence/2.jpg"
import nineelmsresidence20 from "assets/images/gallery/nineelms/residence/20.jpg"
import nineelmsresidence21 from "assets/images/gallery/nineelms/residence/21.jpg"
import nineelmsresidence3 from "assets/images/gallery/nineelms/residence/3.jpg"
import nineelmsresidence4 from "assets/images/gallery/nineelms/residence/4.jpg"
import nineelmsresidence5 from "assets/images/gallery/nineelms/residence/5.jpg"
import nineelmsresidence6 from "assets/images/gallery/nineelms/residence/6.jpg"
import nineelmsresidence7 from "assets/images/gallery/nineelms/residence/7.jpg"
import nineelmsresidence8 from "assets/images/gallery/nineelms/residence/8.jpg"
import nineelmsresidence9 from "assets/images/gallery/nineelms/residence/9.jpg"
import lifestyleamenities1 from "assets/images/gallery/lifestyle/amenities/1.jpg"
import lifestyleamenities10 from "assets/images/gallery/lifestyle/amenities/10.jpg"
import lifestyleamenities11 from "assets/images/gallery/lifestyle/amenities/11.jpg"
import lifestyleamenities12 from "assets/images/gallery/lifestyle/amenities/12.jpg"
import lifestyleamenities2 from "assets/images/gallery/lifestyle/amenities/2.jpg"
import lifestyleamenities3 from "assets/images/gallery/lifestyle/amenities/3.jpg"
import lifestyleamenities4 from "assets/images/gallery/lifestyle/amenities/4.jpg"
import lifestyleamenities5 from "assets/images/gallery/lifestyle/amenities/5.jpg"
import lifestyleamenities6 from "assets/images/gallery/lifestyle/amenities/6.jpg"
import lifestyleamenities7 from "assets/images/gallery/lifestyle/amenities/7.jpg"
import lifestyleamenities8 from "assets/images/gallery/lifestyle/amenities/8.jpg"
import lifestyleamenities9 from "assets/images/gallery/lifestyle/amenities/9.jpg"
import lifestyleculinary1 from "assets/images/gallery/lifestyle/culinary/1.jpg"
import lifestyleculinary2 from "assets/images/gallery/lifestyle/culinary/2.jpg"
import lifestyleculinary3 from "assets/images/gallery/lifestyle/culinary/3.jpg"
import lifestyleculture1 from "assets/images/gallery/lifestyle/culture/1.jpg"
import lifestyleretail1 from "assets/images/gallery/lifestyle/retail/1.jpg"
import lifestyleretail2 from "assets/images/gallery/lifestyle/retail/2.jpg"
import lifestyleretail3 from "assets/images/gallery/lifestyle/retail/3.jpg"

import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"
useLoader.preload(TextureLoader, [
  nineelmsconcierge1,
  nineelmsconcierge2,
  nineelmsconcierge3,
  nineelmsconcierge4,
  nineelmsconcierge5,
  nineelmsoutside1,
  nineelmsoutside2,
  nineelmsoutside3,
  nineelmsoutside4,
  nineelmsoutside5,
  nineelmsoutside6,
  nineelmsoutside7,
  nineelmsresidence1,
  nineelmsresidence10,
  nineelmsresidence11,
  nineelmsresidence12,
  nineelmsresidence13,
  nineelmsresidence14,
  nineelmsresidence15,
  nineelmsresidence16,
  nineelmsresidence17,
  nineelmsresidence18,
  nineelmsresidence19,
  nineelmsresidence2,
  nineelmsresidence20,
  nineelmsresidence21,
  nineelmsresidence3,
  nineelmsresidence4,
  nineelmsresidence5,
  nineelmsresidence6,
  nineelmsresidence7,
  nineelmsresidence8,
  nineelmsresidence9,
  lifestyleamenities1,
  lifestyleamenities10,
  lifestyleamenities11,
  lifestyleamenities2,
  lifestyleamenities3,
  lifestyleamenities4,
  lifestyleamenities5,
  lifestyleamenities6,
  lifestyleamenities7,
  lifestyleamenities8,
  lifestyleamenities9,
  lifestyleculinary1,
  lifestyleculinary2,
  lifestyleculinary3,
  lifestyleculture1,
  lifestyleretail1,
  lifestyleretail2,
  lifestyleretail3,
])

export const galleryIndex = {
  nineelms: {
    0: {
      1: nineelmsconcierge1,
      2: nineelmsconcierge2,
      3: nineelmsconcierge3,
      4: nineelmsconcierge4,
      5: nineelmsconcierge5,
    },
    1: {
      1: nineelmsoutside1,
      2: nineelmsoutside2,
      3: nineelmsoutside3,
      4: nineelmsoutside4,
      5: nineelmsoutside5,
      6: nineelmsoutside6,
      7: nineelmsoutside7,
    },
    2: {
      1: nineelmsresidence1,
      2: nineelmsresidence2,
      3: nineelmsresidence3,
      4: nineelmsresidence4,
      5: nineelmsresidence5,
      6: nineelmsresidence6,
      7: nineelmsresidence7,
      8: nineelmsresidence8,
      9: nineelmsresidence9,
      10: nineelmsresidence10,
      11: nineelmsresidence11,
      12: nineelmsresidence12,
      13: nineelmsresidence13,
      14: nineelmsresidence14,
      15: nineelmsresidence15,
      16: nineelmsresidence16,
      17: nineelmsresidence17,
      18: nineelmsresidence18,
      19: nineelmsresidence19,
      20: nineelmsresidence20,
      21: nineelmsresidence21,
    },
  },
  lifestyle: {
    0: {
      1: lifestyleamenities1,
      2: lifestyleamenities2,
      3: lifestyleamenities3,
      4: lifestyleamenities4,
      5: lifestyleamenities5,
      6: lifestyleamenities6,
      7: lifestyleamenities7,
      8: lifestyleamenities8,
      9: lifestyleamenities9,
      10: lifestyleamenities10,
      11: lifestyleamenities11,
      12: lifestyleamenities12,
    },
    1: {
      1: lifestyleculinary1,
      2: lifestyleculinary2,
      3: lifestyleculinary3,
    },
    2: {
      1: lifestyleculture1,
    },
    3: {
      1: lifestyleretail1,
      2: lifestyleretail2,
      3: lifestyleretail3,
    },
  },
}
