/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import anm from "./transitions/transitions"
import { useStore } from "state/store"
import Views360 from "components/ApartmentCard/components/Views360"

function ViewsMobile({ section, apartment }) {
  const [disabled, setDisabled] = useState(false)
  const transition = useTransition(section === "VIEWS", anm)

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false)
    }, 600)
  }, [disabled])

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          <Views360 activeApartment={apartment} section={section} />
        </Wrapper>
      ),
  )
}

export default ViewsMobile

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`
