import { Canvas } from "@react-three/fiber"
import styled from "styled-components"
import GalleryCamera from "./GalleryCamera"
import GalleryMesh from "./GalleryMesh"
import { a } from "@react-spring/web"
import { useSpring } from "@react-spring/core"
import { useStore } from "state/store"
import { useEffect } from "react"
import { useState } from "react"
function GalleryScene() {
  const setActiveImage = useStore(s => s.setActiveImage)
  const setTabObject = useStore(s => s.setTabObject)
  const tabObj = useStore(s => s.tabObj)
  const cleanup = () => {
    setActiveImage(null)

    setTabObject({ first: 0, second: 0 })
  }

  useEffect(() => {
    return () => cleanup()
  }, [])

  const { opacity } = useSpring({
    opacity: tabObj.first !== 2 ? 1 : 0,
  })

  const [loadmsg, setLoadmsg] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoadmsg(false), 2000)
  }, [])

  return (
    <GalleryWr style={{ opacity: opacity }}>
      <Canvas style={{ zIndex: 9 }} linear flat>
        <GalleryCamera />
        {tabObj.first !== 2 && <GalleryMesh />}
      </Canvas>
      {loadmsg && <Loading>LOADING GALLERY</Loading>}
    </GalleryWr>
  )
}
const Loading = styled.div`
  z-index: 0;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: 50%;
`

const GalleryWr = styled(a.div)`
  color: white;
  position: absolute;
  top: 10vh;
  width: 100%;
  height: 80vh;
`
export default GalleryScene
