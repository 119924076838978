import { APART_PROPS } from "dataset/finder"

export function getMaxPrice(arr) {
  let max
  for (let i = 0; i < arr.length; i++) {
    if (max == null || parseInt(arr[i][APART_PROPS.price]) > parseInt(max[APART_PROPS.price])) {
      max = arr[i]
    }
  }
  return max
}

export function getMinPrice(arr) {
  let min
  for (let i = 0; i < arr.length; i++) {
    if (min == null || parseInt(arr[i][APART_PROPS.price]) < parseInt(min[APART_PROPS.price])) {
      min = arr[i]
    }
  }
  return min
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

/* $2,500.00 */
