import { APART_PROPS } from "dataset/finder"
import { useStore } from "state/store"

export const useApartments = () => {
  const { apartments, bedroomCounts, priceRange, levelRange, type, selectedAspects } = useStore(
    state => ({
      apartments: state.apartments,
      bedroomCounts: state.bedroomCounts,
      priceRange: state.priceRange,
      levelRange: state.levelRange,
      type: state.type,
      selectedAspects: state.selectedAspects,
    }),
  )

  const all = [...apartments]
  const allPlots = Object.fromEntries(apartments.map(ap => [ap[APART_PROPS.unit], ap]))

  const isApartmentAvailable = ap => {
    return (
      ap.Availability === "1" &&
      ap[APART_PROPS.price] >= priceRange[0] &&
      ap[APART_PROPS.price] < priceRange[1] &&
      ap[APART_PROPS.floor] >= levelRange[0] &&
      ap[APART_PROPS.floor] <= levelRange[1] + 1 &&
      (!selectedAspects.length ||
        ap[APART_PROPS.aspect].split("").some(e => selectedAspects.includes(e))) &&
      (!type.length || type.includes(ap[APART_PROPS.fullName])) &&
      (!bedroomCounts.length || bedroomCounts.includes(ap[APART_PROPS.beds]))
    )
  }

  const availableApartments = apartments.filter(isApartmentAvailable)
  const availableIDs = availableApartments.map(ap =>
    parseInt(ap[APART_PROPS.unitNumber].split(".")[1]),
  )
  const availablePlots = availableApartments.map(ap => parseInt(ap[APART_PROPS.plot]))

  return {
    all,
    availableApartments,
    availablePlots,
    availableIDs,
    allPlots,
  }
}
