import React from "react"
import { range } from "lodash-es"
import { Text } from "@react-three/drei"
import { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { DoubleSide } from "three"
import Grande from "assets/fonts/Grande.otf"
import { useGLTF } from "utils/hooks/useGLTF"

const layers = 1
const locations = ["US EMBASSY", "BATTERSEA POWER STATION", "NINE ELMS STATION"]
function LandscapeMain() {
  let rangeArr = range(0, Math.PI * 2, 2.2)

  return (
    <>
      {rangeArr.map((y, i) => (
        <LandscapeElement key={i} index={i} y={y + 1.5} />
      ))}
    </>
  )
}

function LandscapeElement({ y, index }) {
  const distance = 350
  const meshRef = useRef()
  useFrame(({ camera }) => {
    meshRef.current.lookAt(camera.position)
  })
  return (
    <group scale={1.4} rotation={[0, y, 0]}>
      <Text
        textAlign="center"
        font={Grande}
        layers={layers}
        position={[distance, 40, 0]}
        ref={meshRef}
        fontSize="10">
        {locations[index]}
        <meshMatcapMaterial side={DoubleSide} color="#ceaa8b" wireframe={false} />
      </Text>
      <mesh layers={layers} rotation={[0, Math.PI / 2, 0]} position={[distance, -6, 0]}>
        <boxGeometry args={[0.3, 65, 0.3]} />
        <meshMatcapMaterial side={DoubleSide} color="#ceaa8b" wireframe={false} />
      </mesh>
      <mesh layers={layers} rotation={[0, Math.PI / 2, 0]} position={[distance, -40, 0]}>
        <sphereGeometry args={[2.5, 2, 2]} />
        <meshMatcapMaterial side={DoubleSide} color="#ceaa8b" wireframe={false} />
      </mesh>
    </group>
  )
}

export function Roads({ model }) {
  const { nodes } = useGLTF(model)

  return (
    <>
      <mesh geometry={nodes.roads.geometry}>
        <meshBasicMaterial color="#655547" />
      </mesh>
    </>
  )
}

export default LandscapeMain
