import POICultureIcon from "assets/images/icons/POICultureIcon"
import POIDiningIcon from "assets/images/icons/POIDiningIcon"
import POIEducationIcon from "assets/images/icons/POIEducationIcon"
import POILeisureIcon from "assets/images/icons/POILeisureIcon"
import POIRetailIcon from "assets/images/icons/POIRetailIcon"
import POITravelIcon from "assets/images/icons/POITravelIcon"

export const LOCATIONS = [
  {
    name: "Travel",
    icon: POITravelIcon,
    pois: [
      {
        typeIndexed: "travel-1",
        poi: "Nine Elms Station",
        distance: "7 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "travel-2",
        poi: "Battersea Power Station",
        distance: "13 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "travel-3",
        poi: "Battersea Park Station",
        distance: "14 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "travel-4",
        poi: "Vauxhall Station",
        distance: "15 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "travel-5",
        poi: "Battersea Power Station River Boat",
        distance: "28 MINS WALK",
        image: "",
      },
    ],
  },
  {
    name: "Food",
    icon: POIDiningIcon,
    pois: [
      {
        typeIndexed: "food-1",
        poi: "DISTRICT COFFEE",
        distance: "1 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-2",
        poi: "THE ALCHEMIST",
        distance: "1 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-3",
        poi: "HOMEBOY BAR",
        distance: "2 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-4",
        poi: "DARBY’S ",
        distance: "2 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-5",
        poi: "OXEYE",
        distance: "2 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-6",
        poi: "THE BATTERSEA BARGE",
        distance: "8 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-7",
        poi: "NINE ELMS TAVERN",
        distance: "8 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-8",
        poi: "CHOKHI DHANI",
        distance: "8 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-9",
        poi: "BRUNSWICK HOUSE",
        distance: "9 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "food-10",
        poi: "THE RIVERSIDE",
        distance: "9 MINS WALK",
        image: "",
      },
    ],
  },
  {
    name: "Amenities",
    icon: POICultureIcon,
    pois: [
      {
        typeIndexed: "amenities-1",
        poi: "U.S. EMBASSY",
        distance: "1 MINUTES WALK",
        image: "",
      },
      {
        typeIndexed: "amenities-2",
        poi: "NEW COVENT GARDEN MARKET ",
        distance: "2 MINUTES CYCLING",
        image: "",
      },
      {
        typeIndexed: "amenities-3",
        poi: "ST GEORGE WHARF",
        distance: "2 MINUTES CYCLING",
        image: "",
      },
      {
        typeIndexed: "amenities-4",
        poi: "BATTERSEA POWER\rSTATION RIVER BOAT",
        distance: "28 MINUTES WALK",
        image: "",
      },
      {
        typeIndexed: "amenities-5",
        poi: "WAITROSE",
        distance: "3 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "amenities-6",
        poi: "BATTERSEA POWER STATION",
        distance: "3 MINUTES CYCLING",
        image: "",
      },
      {
        typeIndexed: "amenities-7",
        poi: "THE HYATT BATTERSEA",
        distance: "COMING SOON",
        image: "",
      },
    ],
  },
  {
    name: "Leisure",
    icon: POILeisureIcon,
    pois: [
      {
        typeIndexed: "leisure-1",
        poi: "LINNAEAN",
        distance: "3 MINS WALK",
        image: "",
      },
      {
        typeIndexed: "leisure-2",
        poi: "STATIC CYCLE",
        distance: "4 MINS WALK",
        image: "",
      },
    ],
  },
  // {
  //   name: "Image",
  //   icon: POIRetailIcon,
  //   pois: [
  //     {
  //       typeIndexed: "image-a",
  //       poi: "Mill Pond Wood",
  //       distance: "",
  //       image: "",
  //     },
  //     {
  //       typeIndexed: "image-b",
  //       poi: "Mill Pond Lawn",
  //       distance: "",
  //       image: "",
  //     },
  //     {
  //       typeIndexed: "image-c",
  //       poi: "Mill Pond Square",
  //       distance: "",
  //       image: "",
  //     },
  //   ],
  // },
]

//Second Location

export const LONDON_LOCATIONS = [
  {
    name: "Amenities",
    icon: POICultureIcon,
    pois: [
      {
        typeIndexed: "amenities-1",
        poi: "CHELSEA FLOWER SHOWGROUND",
        distance: "11 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-2",
        poi: "SOUTHBANK CENTRE",
        distance: "12 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-3",
        poi: "SAATCHI GALLERY",
        distance: "13 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-4",
        poi: "NATIONAL THEATRE",
        distance: "13 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-5",
        poi: "THE NATIONAL GALLERY",
        distance: "16 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-6",
        poi: "ROYAL OPERA HOUSE",
        distance: "16 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-7",
        poi: "TATE MODERN",
        distance: "17 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-8",
        poi: "BOROUGH MARKET",
        distance: "18 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-9",
        poi: "STAMFORD BRIDGE",
        distance: "21 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-10",
        poi: "NATURAL HISTORY MUSEUM",
        distance: "21 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-11",
        poi: "V&A MUSEUM",
        distance: "21 MINUTES CYCLING",
      },
      {
        typeIndexed: "amenities-12",
        poi: "THE RITZ",
        distance: "21 MINUTES CYCLING",
      },
    ],
  },
  {
    name: "Retail",
    icon: POIRetailIcon,
    pois: [
      {
        typeIndexed: "retail-1",
        poi: "KING’S ROAD",
        distance: "14 MINUTES CYCLING",
      },
      {
        typeIndexed: "retail-2",
        poi: "HARRODS",
        distance: "16 MINUTES CYCLING",
      },
      {
        typeIndexed: "retail-3",
        poi: "LIBERTY LONDON ",
        distance: "22 MINUTES CYCLING",
      },
      {
        typeIndexed: "retail-4",
        poi: "SELFRIDGES",
        distance: "24 MINUTES CYCLING",
      },
    ],
  },
  {
    name: "Education",
    icon: POIEducationIcon,
    pois: [
      {
        typeIndexed: "education-1",
        poi: "University of Arts London (Kennington)",
        distance: "11 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-2",
        poi: "University College London (Warren Street)",
        distance: "12 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-3",
        poi: "London South Bank University (Elephant & Castle)",
        distance: "15 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-4",
        poi: "Kings College London (Embankment)",
        distance: "16 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-5",
        poi: "London School of Economics (Charing Cross)",
        distance: "17 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-6",
        poi: "Imperial College London (South Kensington)",
        distance: "22 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-7",
        poi: "London Metropolitan University (Holloway Road)",
        distance: "28 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-8",
        poi: "Saint Georges Church of England Primary School",
        distance: "12 MINS WALK",
      },
      {
        typeIndexed: "education-9",
        poi: "Griffin Primary School",
        distance: "16 MINS WALK",
      },
      {
        typeIndexed: "education-10",
        poi: "John Burns Primary School",
        distance: "16 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-11",
        poi: "Vauxhall Primary School",
        distance: "17 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-12",
        poi: "Shaftesbury Park Primary School",
        distance: "19 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-13",
        poi: "Prior Weston Primary School",
        distance: "35 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-14",
        poi: "Grange Primary School",
        distance: "37 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-15",
        poi: "Phoenix Primary School",
        distance: "40 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-16",
        poi: "Notre Dame Roman Catholic School for Girls",
        distance: "22 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-17",
        poi: "Oasis Academy South Bank",
        distance: "24 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-18",
        poi: "Westminster City School",
        distance: "25 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-19",
        poi: "Kennington Park Academy",
        distance: "27 MINS PUBLIC TRANSPORT",
      },
      {
        typeIndexed: "education-20",
        poi: "Harris Academy Bermondsey",
        distance: "36 MINS PUBLIC TRANSPORT",
      },
    ],
  },
  {
    name: "Travel",
    icon: POITravelIcon,
    pois: [],
  },
]
