import { useState } from "react"
import Bg1 from "assets/images/about/1.jpg"
import Bg2 from "assets/images/about/2.jpg"
import Bg3 from "assets/images/about/3.jpg"
import Bg4 from "assets/images/about/4.jpg"
import styled from "styled-components"

import { Swiper, SwiperSlide } from "swiper/react"
import AboutCardOne from "components/AboutCard/AboutCardOne"
import AboutCardTwo from "components/AboutCard/AboutCardTwo"
import AboutCardThree from "components/AboutCard/AboutCardThree"
import AboutCardFour from "components/AboutCard/AboutCardFour"
import AboutCardFive from "components/AboutCard/AboutCardFive"

import Page1 from "assets/images/about/page1.png"
import Page2 from "assets/images/about/page2.png"
import Page3 from "assets/images/about/page3.png"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/scrollbar"

let content = [
  {
    image: Bg1,
    title: "LONDON LIVING BEAUTIFULLY ELEVATED",
    copy: [
      `A new London address where absolute quality
      meets beautifully elevated living. Where every
      detail has been exquisitely crafted and where
      the hallmark is pure luxury over 23 storeys`,
      `Welcome to London Square Nine Elms.
      Our ultra-exclusive collection of 186 private
      apartments, each rich in design and elegance,
      set within bold, modern elevations.`,
      `Every home features a richness of specifications.
      Opulent, contemporary interiors give
      way to dazzling balcony vistas.`,
    ],
  },
  {
    image: Bg2,
    title: "BEST OF BRITISH DESIGN",
    copy: [
      `The Nine Elms area may have already seen huge
      changes, but the future vision is even greater.`,
      `Inspired by London’s famous residential squares, London Square
      understands how the use of space plays a profound role in
      the well-being of both individuals and entire communities.`,
      `London Square Nine Elms has been designed to perfectly
      balance with its surroundings, putting it in the heart of
      carefully designed and calming parkland on your doorstep`,
    ],
  },
  {
    image: Bg3,
    title: "EXQUISITELY CRAFTED",
    copy: [
      `Nine Elms Park is about more than building
      London’s newest quarter, it’s about creating
      a legacy that will be enjoyed for generations.`,
      `Using the most modern materials and
      methods, London Square Nine Elms
      will be an architectural landmark within
      Nine Elms Park that will stand the test
      of time in both form and function.`,
      `From foundations to finishing touches,
      it embodies the creativity, innovation and
      attention to detail that will cement the
      area’s leading role in London’s rich future.`,
    ],
  },
  {
    image: Bg4,
    title: "A UNIQUE COLLABORATION",
    copy: [
      `World-leading architects, developers, planners, and
      businesses come together at Nine Elms delivering
      a successful and innovative mixed-use district.
      With its cutting edge design and forward thinking
      architecture, it is fast becoming a magnet for
      growth with opportunities to invest.`,
      `Nine Elms is home to London’s first new tube
      stations in 25 years; Nine Elms and Battersea
      Power Station. This extension to London’s tube
      network means both the West End and City
      are less than 15 minutes away.`,
      `As such, the Nine Elms area is fast becoming
      one of the most important districts not just
      in London, but in the world.`,
    ],
  },
]

function About() {
  const [showIcon, setShowIcon] = useState(true)
  return (
    <Wrapper>
      <Swiper
        threshold={30}
        onSlideChange={() => {
          showIcon && setShowIcon(false)
        }}
        centeredSlides={true}
        spaceBetween={500}
        slidesPerView={1}>
        <SwiperSlide>
          <AboutCardOne data={content[0]} />
        </SwiperSlide>
        <SwiperSlide>
          <AboutCardTwo data={content[1]} />
        </SwiperSlide>
        <SwiperSlide>
          <AboutCardThree data={content[2]} />
        </SwiperSlide>
        <SwiperSlide>
          <AboutCardFour data={content[3]} />
        </SwiperSlide>
        <SwiperSlide>
          <AboutCardFive svg={Page1} />
        </SwiperSlide>
        <SwiperSlide>
          <AboutCardFive svg={Page2} />
        </SwiperSlide>
        <SwiperSlide>
          <AboutCardFive svg={Page3} />
        </SwiperSlide>
        {showIcon ? (
          <Icon src={`${process.env.PUBLIC_URL}/assets/images/share/swipe.gif`} alt="swipe-icon" />
        ) : null}
      </Swiper>
    </Wrapper>
  )
}

export default About

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .swiper {
    width: 100%;
    height: 100%;
    background-color: black;
  }

  .swiper-slide {
    background-color: black;
  }
`
const Icon = styled.img`
  position: absolute;
  z-index: 9;
  max-width: 3rem;
  left: 50%;
  top: 2%;
  transform: translateX(-50%);
`
