import React, { useRef } from "react"
import { useThree } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"

// extend({ OrbitControls })
// let rangeArr = [0, 0.785398, 0.5 * Math.PI, 2.356194, Math.PI, 3.926991, 1.5 * Math.PI, 5.497787]
// let fov = [75, 75, 40, 40, 30, 45, 75, 75]
function CameraControls(props) {
  const {
    camera,
    gl: { domElement },
  } = useThree()
  const controls = useRef()

  const vertOffset = props.vOffset

  return (
    <>
      {props.hasLimit ? (
        <OrbitControls
          {...props}
          ref={controls}
          args={[camera, domElement]}
          enableZoom={false}
          autoRotate={false}
          rotateSpeed={-0.3}
          minAzimuthAngle={props.min}
          maxAzimuthAngle={props.max}
        />
      ) : (
        <OrbitControls enableZoom={false} autoRotate={false} rotateSpeed={-0.3} />
      )}
    </>
  )
}

export default CameraControls
