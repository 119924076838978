import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import ReactPlayer from "react-player"
import { animated } from "@react-spring/web"
import { orbits, orbitViews } from "./data"
import CameraImg from "assets/images/location/singlecam-01.svg"
import CloseCircle from "assets/images/icons/close-circle.svg"
import { motion, AnimatePresence } from "framer-motion"

import TriangleCaption from "./Caption/TriangleCaption"

export default function Orbits({ numberOfOrbits, totalTime, pauseTime }) {
  const playerRef = useRef()
  const timerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [showRender, setShowRender] = useState(false)
  let [currentOrbit, setCurrentOrbit] = useState(0)
  let [direction, setDirection] = useState("fw")

  const prevCountRef = useRef()
  const prevDirection = useRef()
  const placeholderRef = useRef()
  const wrapperRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const sendMessage = () => {
    const orbit = orbits.find(
      e => e.orbit === `${currentOrbit}-${prevDirection.current.toUpperCase()}`,
    )

    let index = orbits.findIndex(
      x => x.orbit === `${currentOrbit}-${prevDirection.current.toUpperCase()}`,
    )

    playerRef.current.seekTo(orbits[index].startAt / 1000)

    setIsPlaying(true)

    timerRef.current = setTimeout(() => {
      setIsPlaying(false)
      playerRef.current.seekTo(orbits[index].endAt / 1000)
      clearTimeout(timerRef.current)
    }, orbit.endAt - orbit.startAt)
  }

  useEffect(() => {
    prevCountRef.current = currentOrbit
    prevDirection.current = direction
  }, [currentOrbit, direction])

  function incrementCount() {
    currentOrbit = (currentOrbit % 5) + 1

    if (currentOrbit > prevCountRef.current && prevDirection.current === "bw") {
      prevDirection.current = "fw"
      currentOrbit = prevCountRef.current
    }

    if (prevCountRef.current === 5 && prevDirection.current === "bw") {
      prevDirection.current = "fw"
      currentOrbit = 5
    }

    setCurrentOrbit(Math.abs(currentOrbit))
    sendMessage(currentOrbit)
  }

  function decrementCount() {
    currentOrbit = currentOrbit - 1

    if (currentOrbit < prevCountRef.current && prevDirection.current === "fw") {
      prevDirection.current = "bw"
      currentOrbit = prevCountRef.current
    }

    if (currentOrbit === 0) currentOrbit = 5

    setCurrentOrbit(currentOrbit)
    sendMessage(currentOrbit)
  }

  function getOrbitIndex() {
    let orbit = 0

    if (direction === "fw") orbit = currentOrbit
    if (direction === "bw") orbit = (currentOrbit % 5) - 1
    if (orbit === -1) orbit = 4

    return Math.abs(orbit)
  }

  return (
    <Holder>
      <VideoHolder ref={wrapperRef}>
        <ReactPlayer
          style={{ pointerEvents: "none" }}
          ref={playerRef}
          width="100%"
          height="100%"
          stopOnUnmount={true}
          muted={true}
          playing={isPlaying}
          config={{
            file: { attributes: { poster: "noposter" } },
          }}
          onPause={() => {
            setTimeout(() => {
              if (direction === "fw") playerRef.current.seekTo(orbits[currentOrbit].startAt / 1000)
            }, 10)
          }}
          url={`${process.env.PUBLIC_URL}/assets/orbits/Orbit.mp4`}
        />

        <OrbitCaption
          className={isPlaying ? "hidden" : ""}
          style={orbitViews[getOrbitIndex()].caption?.style}>
          <TriangleCaption content={orbitViews[getOrbitIndex()].caption} load={!isPlaying} />
        </OrbitCaption>

        {orbitViews[getOrbitIndex()].views.map((o, i) => {
          return (
            !isPlaying && (
              <OrbitView
                className={o.className}
                key={o.view}
                onClick={e => {
                  if (e.target.className.includes("left")) {
                    decrementCount()
                    setDirection("bw")
                  }

                  if (e.target.className.includes("right")) {
                    incrementCount()
                    setDirection("fw")
                  }
                }}
                src={o.view}
                style={{
                  top: o.style.top,
                  left: o.style.left,
                  height: o.style.height,
                  display: o.style.display,
                  width: "auto",
                }}
              />
            )
          )
        })}
        <FramePlaceholder
          ref={placeholderRef}
          className={isPlaying ? "hidden" : "visible"}
          src={`${process.env.PUBLIC_URL}/assets/orbits/frames/${getOrbitIndex()}.jpg`}
          alt=""
        />
      </VideoHolder>
      {showRender && (
        <AnimatePresence>
          <Render
            transition={{ delay: 0, duration: 0.2 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={`${process.env.PUBLIC_URL}/assets/orbits/renders/${getOrbitIndex()}.jpg`}
            alt=""
          />
        </AnimatePresence>
      )}
      <ShowRender
        src={showRender ? CloseCircle : CameraImg}
        onClick={() => {
          setShowRender(prev => !prev)
        }}
        alt=""
      />
    </Holder>
  )
}

const ShowRender = styled.img`
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 10;
  height: 4em;
`

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* touch-action: none; */
`
const VideoHolder = styled(animated.div)`
  background: "#fff";
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  & video {
    object-fit: cover;
  }
`

const Render = styled(motion.img)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all ease-in 500ms;
  z-index: 9;

  &.hidden {
    opacity: 0;
  }
`

const FramePlaceholder = styled.img`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all ease-in 500ms;

  &.hidden {
    opacity: 0;
  }
`

const OrbitView = styled.img`
  position: absolute;
  z-index: 9;
`

const OrbitCaption = styled.div`
  position: fixed;
  font-family: Grande;
  font-size: 1.25vw;
  /* font-size: 1.2em; */

  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.3em;
  width: 12em;
  text-align: right;
  top: ${props => props.style?.top};
  right: ${props => props.style?.right};
  z-index: 9;
  opacity: 1;
  transition: all ease-in 500ms;

  &.hidden {
    opacity: 0;
  }
`
