import styled from "styled-components/macro"

import { useStore } from "state/store"
import { animated } from "@react-spring/web"
import SideBar from "./SideBar"
import LocalMap from "../maps/LocalMap"
import ImageSlider from "./ImageSlider"

import a from "assets/images/location/renders/a.jpg"
import b from "assets/images/location/renders/b.jpg"
import c from "assets/images/location/renders/c.jpg"

const images = {
  a: a,
  b: b,
  c: c,
}

export default function Viewer() {
  const mapChangeClick = useStore(state => state.mapChangeClick)
  const { showImage, setShowImage } = useStore(state => ({
    showImage: state.showImage,
    setShowImage: state.setShowImage,
  }))

  return (
    <Holder isMobile={false}>
      {mapChangeClick ? (
        <>
          <ImageSlider />
          <MapWrapperDesktop>
            <LocalMap />
          </MapWrapperDesktop>
          <SideBar />
        </>
      ) : (
        <>
          <MapWrapperDesktop showImage={showImage}>
            <ImageModal showImage={showImage} setShowImage={setShowImage} />
            <LocalMap />
          </MapWrapperDesktop>
          <SideBar />
        </>
      )}
    </Holder>
  )
}

function ImageModal({ showImage, setShowImage }) {
  const showCaption = () => {
    switch (showImage) {
      case "a":
        return "MILL POND WOOD"
      case "b":
        return "MILL POND LAWN"
      case "c":
        return "MILL POND SQUARE"
      default:
        break
    }
  }

  return (
    showImage && (
      <Image
        style={{ opacity: showImage ? 1 : 0 }}
        onClick={() => {
          setShowImage(null)
        }}>
        <img src={images[showImage]} alt={showImage} />
        <p>{showCaption()}</p>
      </Image>
    )
  )
}

const Image = styled(animated.div)`
  z-index: 9;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000d7;
  border: 1px solid #495057;

  color: black;

  & p {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    color: #fff;
    bottom: 3%;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const MapWrapperDesktop = styled.div`
  position: absolute;
  z-index: ${props => (props.showImage ? 9999 : 99)};
  width: ${props => (props.showImage ? "100%" : "90%")};
  height: 100%;
  left: ${props => (props.showImage ? "" : "-25px")};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`
