export const APART_PROPS = {
  plot: "Postal Number",
  type: "Flat Type",
  beds: "Size (Bed)",
  floor: "Level",
  status: "Availability",
  price: "Full Value",
  fullValue: "Full Value",
  fullName: "Unit Type",
  depositRequired: "Deposit Required",
  shareValue: "Share Value",
  monthlyCost: "Monthly Cost",
  monthlyMortgate: "Monthly Mortgage",
  monthlyRent: "Monthly Rent",
  monthlyCharge: "Monthly Service Charge",
  minHI: "Minimum Household Income",
  maxHI: "Maximum Household Income",
  unit: "Postal Number",
  unitType: "Unit Type",
  unitNumber: "Unit Number",
  aspect: "Aspect",
  thorpID: "Port Number (for model lighting)",
}
export const DIRECTIONS = ["N", "E", "S", "W"]
export const BEDS = [1, 2, 3]
export const MIN_FLOOR = 1
export const MAX_FLOOR = 33
export const VIEW_PORT_AMOUNT = 2
