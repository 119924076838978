import { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import { Keyboard } from "@capacitor/keyboard"
import { a } from "@react-spring/web"
import { useTransition } from "@react-spring/core"

import LogoImage from "assets/images/share/logo.png"
import { useFormik } from "formik"
import { classNames } from "primereact/utils"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { copyToClipboard } from "utils/copyToClipboard"
import useNotInitialRender from "utils/notOnMount"
import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import CloseIcon from "assets/images/icons/close-white.svg"
import { getIsWeb } from "throp/WebSocketProvider"

export default function ShareDialog() {
  const holderRef = useRef()

  useEffect(() => {
    const isWeb = getIsWeb()
    if (!isWeb) {
      Keyboard.addListener("keyboardWillShow", ({ keyboardHeight }) => {
        requestAnimationFrame(() => {
          holderRef.current.style.transform = `translateY(-${keyboardHeight - 200}px)`
          holderRef.current.activeElement.scrollIntoViewIfNeeded(true)
        })
      })

      Keyboard.addListener("keyboardWillHide", () => {
        requestAnimationFrame(() => {
          holderRef.current.style.transform = `translateY(0px)`
        })
      })

      Keyboard.setAccessoryBarVisible({ isVisible: true })
    }

    return () => {
      if (!isWeb) {
        Keyboard.removeAllListeners()
      }
    }
  }, [])

  const [formData, setFormData] = useState({})
  const [sending, setSending] = useState(false)
  const [copied, setCopied] = useState(false)
  const [sent, setSent] = useState(false)
  const domain = "https://www.lsqnineelms.app"
  // const domain = window.location.origin
  const favorites = useStore(state => state.favorites)
  const plots = []
  favorites.map(f => plots.push(f[APART_PROPS.unit]))
  const encodedFav = btoa(`${plots.join(",")}`)
  const encodeEmailGallery = btoa("encodeEmailGallery")
  // const shareURL = `${domain}/${window.api !== undefined ? "#/" : ""}share?units=${encodedFav}`
  // const emailGallery = `${domain}/${window.api !== undefined ? "#/" : ""}${encodeEmailGallery}`

  const shareURL = `${domain}/share?units=${encodedFav}`
  const emailGallery = `${domain}/${encodeEmailGallery}`

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },

    validate: data => {
      let errors = {}

      if (!data.name) {
        errors.name = "Name is required."
      }

      if (!data.email) {
        errors.email = "Email is required."
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = "Invalid email address. E.g. example@email.com"
      }

      return errors
    },
    onSubmit: data => {
      setFormData(data)
    },
  })

  const handleSendemail = () => {
    setSending(true)
    const names = formData?.name?.split(" ") ?? []
    const emailURL = "https://qqo4y7jd7c.execute-api.eu-west-2.amazonaws.com/dev/send"
    const apiKey = "SuslEuh0MU9WUeGbEjF6N83RLw84ikWq5pQqw7CK"

    fetch(emailURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // from: "matthewosborne@live.com",
        from: "nineelms@londonsquare.co.uk",
        to: [formData?.email ?? ""],
        template: "nineElms",
        language: "en",
        data: {
          firstname: names[0],
          lastname: names.slice(1).join(" "),
          link: shareURL,
          emailGalleryLink: emailGallery,
        },
      }),
    }).then(() => {
      setSending(false)
      setSent(true)
      // setTimeout(() => {
      //   setSent(false)
      // }, 1000)
      formik.resetForm()
    })
  }

  useNotInitialRender(() => {
    setTimeout(() => {
      if (formData.name !== "" && formData.email !== "") {
        handleSendemail()
      }
    }, 500)
  }, [formData])

  const isFormFieldValid = name => !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = name => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>
  }

  const sentTransition = useTransition(sent, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <Holder ref={holderRef}>
      <Title src={LogoImage} alt="logo" />
      <FormContainer>
        <div className="card mt-2">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            {true ? (
              <>
                <div className="field">
                  <span className="p-float-label">
                    <InputText
                      style={{ color: "white" }}
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      disabled={sending || copied}
                      autoFocus
                      className={classNames({
                        "p-invalid": isFormFieldValid("name"),
                      })}
                    />
                    <label
                      style={{ color: "white" }}
                      htmlFor="name"
                      className={classNames({
                        "p-error": isFormFieldValid("name"),
                      })}>
                      Name*
                    </label>
                  </span>
                  {getFormErrorMessage("name")}
                </div>
                <div style={{ color: "white" }} className="field mt-5">
                  <span style={{ color: "white" }} className="p-float-label p-input-icon-right">
                    <i style={{ color: "white" }} className="pi pi-envelope" />
                    <InputText
                      style={{ color: "white" }}
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      disabled={sending || copied}
                      className={classNames({
                        "p-invalid": isFormFieldValid("email"),
                      })}
                    />
                    <label
                      htmlFor="email"
                      className={classNames({
                        "p-error": isFormFieldValid("email"),
                      })}>
                      Email*
                    </label>
                  </span>
                  {getFormErrorMessage("email")}
                </div>
                <Button
                  type="submit"
                  label={sending ? "Sending" : sent ? "Sent" : "Submit"}
                  className="mt-2"
                  loading={sending}
                />
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "1em" }}>
                Share feature is not available offline. You can copy the link to the favourite
                apartments and save it to a note until you're back online.
              </div>
            )}

            <Button
              type="button"
              label={copied ? "Copied" : "Copy Link"}
              className="mt-2"
              onClick={() => {
                copyToClipboard(shareURL, copied => {
                  copied ? setCopied(true) : setCopied(false)
                  setTimeout(() => {
                    setCopied(false)
                  }, 1500)
                })
              }}
            />
          </form>
        </div>

        {sentTransition(
          (style, show) =>
            show && (
              <SentMessage style={style}>
                <h4>
                  Thank you for submitting your favourites. You should shortly receive an email link
                  to view them.
                </h4>
                <Close onClick={() => setSent(false)}>
                  <img width="100%" height="100%" src={CloseIcon} alt="" />
                </Close>
              </SentMessage>
            ),
        )}
      </FormContainer>
    </Holder>
  )
}

const Close = styled.div`
  cursor: pointer;
  width: 1em;
  height: 1em;
`

const SentMessage = styled(a.div)`
  position: absolute;
  width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 2em;
  justify-content: center;
  /* left: 50%; */

  height: max-content;
  text-align: center;
  text-justify: center;
  /* padding: 4em 2em; */
  border-radius: 40px;
  /* border: red solid 2px; */
  font-family: Grande;

  color: white;
  background-color: #b58055;
`

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #212121;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  & form {
    font-family: Grande;
    color: #f2f2f2;
  }

  & form div:nth-of-type(1) span input,
  form div:nth-of-type(2) span input {
    background-color: transparent;
    border: unset;
    border-radius: 0%;
    border-bottom: 0.1vw solid #f2f2f2;
    user-select: text;
    -webkit-user-select: text;
  }

  & form div:nth-of-type(1) span input:focus,
  form div:nth-of-type(1) span input:focus-visible,
  form div:nth-of-type(1) span input:hover,
  form div:nth-of-type(2) span input:focus,
  form div:nth-of-type(2) span input:focus-visible,
  form div:nth-of-type(2) span input:hover {
    border: unset;
    outline: none;
    box-shadow: none;
    color: #f2f2f2;
    border-radius: 0%;
    border-bottom: 0.1vw solid #f2f2f2;
  }

  & form button {
    background-color: #b58055;
    color: #f2f2f2;
    border: none;
    font-family: Grande;

    :hover {
      background-color: #b58055 !important;
    }
  }
`

const FormContainer = styled.div`
  background-color: #212121;
  width: 30%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.img`
  width: 80%;
  max-width: 30em;
`
