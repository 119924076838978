import DrawSVG from "components/AnimatedUIElements/AnimatedSVGPaths"
import { useState, useEffect } from "react"
import { useStore } from "state/store"

function AnimatedFrame() {
  const [active, set] = useState(false)

  const modelsLoaded = useStore(s => s.modelsLoaded)

  useEffect(() => {
    setTimeout(() => set(true), 300)
  }, [])

  const interacted = useStore(s => s.interacted)

  useEffect(() => {
    if (interacted && modelsLoaded) set(false)
  }, [interacted, modelsLoaded])

  return (
    <>
      <DrawSVG active={active} repeat={false} action={() => {}}>
        <svg
          width="100%"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 939.3 750"
          enableBackground="new 0 0 939.3 750"
          opacity="0.3"
          strokeWidth="1">
          <path fill="none" stroke="#AD976E" strokeWidth={1} d="M861.4,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M899.3,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M939.3,0v743" />
          <path fill="none" stroke="#AD976E" d="M859.1,0v743" />
          <path fill="none" stroke="#AD976E" d="M739.3,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M777.2,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M817.3,0v743" />
          <path fill="none" stroke="#AD976E" d="M737.1,0v743" />
          <path fill="none" stroke="#AD976E" d="M617.3,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M655.2,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M695.3,0v743" />
          <path fill="none" stroke="#AD976E" d="M615,0v743" />
          <path fill="none" stroke="#AD976E" d="M495.3,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M533.2,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M573.3,0v743" />
          <path fill="none" stroke="#AD976E" d="M493,0v743" />
          <path fill="none" stroke="#AD976E" d="M373.2,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M411.1,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M451.2,0v743" />
          <path fill="none" stroke="#AD976E" d="M371,0v743" />
          <path fill="none" stroke="#AD976E" d="M251.2,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M289.1,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M329.2,0v743" />
          <path fill="none" stroke="#AD976E" d="M249,0v743" />
          <path fill="none" stroke="#AD976E" d="M129.2,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M167.1,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M207.1,0v743" />
          <path fill="none" stroke="#AD976E" d="M126.9,0v743" />
          <path fill="none" stroke="#AD976E" d="M7.1,742.2l37.9-12.9l39.1,13.3" />
          <path fill="none" stroke="#AD976E" d="M45.1,0v730.1" />
          <path fill="none" stroke="#AD976E" d="M85.1,0v743" />
          <path fill="none" stroke="#AD976E" d="M4.9,0v743" />
        </svg>
      </DrawSVG>
    </>
  )
}

export default AnimatedFrame
