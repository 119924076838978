import { APART_PROPS } from "dataset/finder"

const thorpFn = {
  leds: 1024,

  unitToArrayOfNumbers(unit) {
    const parsedUnit = unit.split(";")
    const start = parseInt(parsedUnit[0])
    const end = parseInt(parsedUnit[1])
    const range = [...Array(end - start + 1).keys()].map(x => x + start)
    return range
  },

  lightUpUnits(socket, units) {
    if (!socket) return

    if (socket.readyState !== 1 /* OPEN */) {
      console.log("Socket not ready")
      return
    }

    if (!Array.isArray(units)) {
      units = [units]
    }

    const parsedUnits = units
      .map(unit => thorpFn.unitToArrayOfNumbers(unit[APART_PROPS.thorpID]))
      .flat()

    thorpFn.writeFrame(socket, parsedUnits)
  },

  writeFrame(socket, units) {
    if (!socket) return

    if (socket.bufferedAmount > 0) {
      console.log("Socket not ready or network is lagging")
      return
    }

    const packet = new Uint8ClampedArray(8 + thorpFn.leds * 3)
    let dest = 4

    for (let led = 0; led < thorpFn.leds; led++) {
      if (units.includes(led + 1)) {
        packet[dest++] = 204
        packet[dest++] = 152
        packet[dest++] = 84
      } else {
        packet[dest++] = 102
        packet[dest++] = 76
        packet[dest++] = 42
      }
    }

    // Set timeout to avoid sending too many packets at once otherwise buffer will overflow
    setTimeout(() => {
      socket.send(packet.buffer)
      socket.send(packet.buffer)
    }, 100)
  },
}

export default thorpFn
