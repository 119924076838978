import React, { useRef } from "react"
import styled from "styled-components"
import { a, useSpring } from "react-spring"

function AboutCardTwo({ data }) {
  const [imgStyle, imgApi] = useSpring(() => ({
    clipPath: "polygon(0% 40%, 100% 40%, 100% 80%, 0% 80%)",
  }))
  const [copyStyle, copyApi] = useSpring(() => ({ opacity: 1 }))
  const isOpen = useRef(false)

  function handleClick() {
    isOpen.current = !isOpen.current
    imgApi.start({
      clipPath: isOpen.current
        ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
        : "polygon(0% 40%, 100% 40%, 100% 80%, 0% 80%)",
      delay: isOpen.current ? 500 : 0,
    })
    copyApi.start({
      opacity: isOpen.current ? 0 : 1,
      delay: isOpen.current ? 0 : 500,
    })
  }
  return (
    <Wrapper
      onClick={() => {
        handleClick()
      }}>
      <Image route={data.image} style={imgStyle} />
      <Title style={copyStyle}>
        <div>
          <h1> {data.title} </h1>
        </div>
      </Title>
      <Copy style={copyStyle}>
        <Text>
          {data.copy.map((text, i) => {
            return <p key={`p${i}`}>{text}</p>
          })}
        </Text>
      </Copy>
    </Wrapper>
  )
}

export default AboutCardTwo

const Wrapper = styled.div`
  position: absolute;
  width: 85%;
  height: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
`

const Image = styled(a.div)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${p => `url(${p.route})`};
  background-position: 0 0;
  background-size: cover;
`

const Copy = styled(a.div)`
  position: absolute;
  z-index: 0;
  top: 5%;
  left: 60%;
  width: 40%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  width: 60%;

  & p {
    font-family: Bliss-Light;
    font-weight: 100;
    font-size: 0.8rem;
    text-align: center;
    margin: 5% auto;
    color: #00000099;

    :nth-of-type(1) {
      color: #b58762;
    }
  }
`
const Title = styled(a.div)`
  background-color: #b58762;
  width: 60%;
  height: 90%;
  clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 0%);
  margin: 0;
  top: 0;
  left: 0;

  & div {
    position: relative;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    left: 2px;
    clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 0%);
    text-align: center;
    font-size: 1.5rem;
    font-weight: 100;
    background-color: white;
    color: black;

    & h1 {
      margin: 0;
      position: absolute;
      top: 20%;
      left: 60%;
      transform: translateX(-50%);
      font-weight: 100;
      font-size: 2rem;
    }
  }
`
