import { useFrame, useThree } from "@react-three/fiber"
import { Color, HalfFloatType, MathUtils, ShaderMaterial, Vector2 } from "three"
import { EffectComposer, RenderPass } from "three-stdlib"
import * as THREE from "three"
import { useLayoutEffect, useState } from "react"
import { getFBO } from "./Shaders/Edges/js/FBO"
import { webgl2Vertex } from "./Shaders/Edges/custom/webgl2Vertex"
import { webgl2Fragment } from "./Shaders/Edges/custom/webgl2Fragment"
import { ShaderPass } from "./Shaders/Edges/ShaderPass"
import { useRef } from "react"
import { useEffect } from "react"
import { useStore } from "state/store"
import Hud from "components/BuildingExplorer/elements/Hud"

const normalMat = new THREE.MeshNormalMaterial({ side: THREE.DoubleSide })

function PostProcessing({ rotationRef, model, arrow }) {
  const interactRef = useRef(useStore.getState().interacted)

  const panoViewerActiveRef = useRef(useStore.getState().panoViewerActive)

  useEffect(() => {
    useStore.subscribe(state => (panoViewerActiveRef.current = state.panoViewerActive))
  }, [])

  useEffect(() => {
    useStore.subscribe(state => (interactRef.current = state.interacted))
  }, [])

  const {
    gl,
    scene: defaultScene,
    camera: defaultCamera,
    size,
    events,
    viewport: { width, height },
  } = useThree()

  const [hudScene] = useState(() => new THREE.Scene())
  const [hudCam] = useState(() => new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 1000))
  let composer

  const res = 1 // 2

  const wW = window.innerWidth * res
  const wH = window.innerHeight * res

  const params = {
    scale: 40,
    hatchScale: 1.5,
    contour: 2,
    paperGrid: 0.25,
    objectGrid: 0.25,
    inkColor: new Color(181, 128, 85),
    paperColor: new Color(239, 208, 175),
    angleGrid: 0,
    angleDark: (15 * Math.PI) / 180,
    angleLight: (45 * Math.PI) / 180,
    dark: 0.5,
    light: 0.7,
  }

  const colorFBO = getFBO(wW, wH, { samples: 512 })
  const coordsFrontFBO = getFBO(wW, wH, { type: HalfFloatType })
  const coordsBackFBO = getFBO(wW, wH, { type: HalfFloatType })
  const normalFBO = getFBO(wW, wH, { samples: 512 })

  const EdgesShader = new ShaderMaterial({
    extensions: {
      derivatives: "#extension GL_OES_standard_derivatives : enable",
    },
    uniforms: {
      tDiffuse: { value: null },
      uTargetTest: { value: null },
      iResolution: { type: "v2", value: new Vector2() },
      uMouseX: { value: 0 },
      uMouseY: { value: 0 },
      uOpacity: { value: 1 },
      uIsOffice: { value: 1 },
      //////////////////////////////
      paperTexture: { value: null },
      colorTexture: { value: colorFBO.texture },
      coordsFrontTexture: { value: coordsFrontFBO.texture },
      coordsBackTexture: { value: coordsBackFBO.texture },
      normalTexture: { value: normalFBO.texture },
      inkColor: { value: params.inkColor },
      paperColor: { value: params.paperColor },
      scale: { value: params.scale },
      hatchScale: { value: params.hatchScale },
      contour: { value: params.contour },
      paperGrid: { value: params.paperGrid },
      objectGrid: { value: params.objectGrid },
      angleDark: { value: params.angleDark },
      angleLight: { value: params.angleLight },
      dark: { value: params.dark },
      light: { value: params.light },
      uBlend: { value: 0 },
    },

    vertexShader: webgl2Vertex,
    fragmentShader: webgl2Fragment,
  })

  composer = new EffectComposer(gl)
  const renderPass = new RenderPass(defaultScene, defaultCamera)
  const sketchPass = new ShaderPass(gl, EdgesShader)

  composer.addPass(renderPass)
  composer.addPass(sketchPass)

  useLayoutEffect(() => {
    hudCam.left = -size.width / 2
    hudCam.right = size.width / 2
    hudCam.top = size.height / 2
    hudCam.bottom = -size.height / 2
    hudCam.position.set(0, 0, 1000)
    hudCam.near = 0.1
    hudCam.far = 2000
    hudCam.updateProjectionMatrix()
  }, [size, hudCam])

  useFrame(() => {
    sketchPass.shader.uniforms.uBlend.value = MathUtils.lerp(
      sketchPass.shader.uniforms.uBlend.value,
      interactRef.current ? 1 : 0,
      0.06,
    )

    gl.autoClear = false
    gl.clear()
    defaultCamera.layers.set(1)
    gl.setRenderTarget(colorFBO)
    gl.clear()
    gl.render(defaultScene, defaultCamera)
    gl.setRenderTarget(null)
    gl.clearDepth()
    defaultScene.overrideMaterial = normalMat
    gl.autoClear = false
    gl.setRenderTarget(normalFBO)
    gl.clear()
    gl.render(defaultScene, defaultCamera)
    gl.setRenderTarget(null)
    defaultScene.overrideMaterial = null
    gl.autoClear = false
    composer.render()
    gl.clearDepth()
    defaultCamera.layers.set(0)

    if (!panoViewerActiveRef.current) {
      gl.render(hudScene, hudCam)
      gl.render(defaultScene, defaultCamera)
    }
  }, 1)

  return (
    <>
      <Hud
        arrow={arrow}
        model={model}
        rotationRef={rotationRef}
        hudScene={hudScene}
        hudCam={hudCam}
        sceneProps={{ size: size, events: events, width: width, height: height }}
      />
    </>
  )
}

export default PostProcessing
