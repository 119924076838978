import React from "react"
import styled from "styled-components/macro"
import RoomSelector from "./components/RoomSelector"
import { a, useTransition } from "react-spring"

import IconButton from "components/Buttons/IconButton"
import CloseIcon from "assets/images/icons/close-white.svg"
import SphericalTour from "components/SphericalTour/SphericalTour"

import { useStore } from "state/store"
import TOUR_LIST from "dataset/tourData"
import { useEffect } from "react"

export default function Showhome() {
  const homeState = useStore(state => state.homeState)
  const setHomeState = useStore(state => state.setHomeState)
  const tourIndex = useStore(s => s.tourIndex)
  const setTourIndex = useStore(s => s.setTourIndex)

  const handleCleanup = () => {
    setHomeState(0)
    setTourIndex(0)
  }

  useEffect(() => {
    return () => handleCleanup()
  }, [])

  const currentData = homeState !== 0 ? TOUR_LIST[`${homeState}`] : undefined

  const tourAnm = useTransition(homeState !== 0, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const getTour = () => {
    switch (homeState) {
      case 1:
        return (
          <SphericalTour
            texture={currentData[`${tourIndex}`].file}
            hotSpots={currentData[`${tourIndex}`].hotspotPosition}
          />
        )
      case 2:
        return (
          <SphericalTour
            texture={currentData[`${tourIndex}`].file}
            hotSpots={currentData[`${tourIndex}`].hotspotPosition}
          />
        )
      default:
        return
    }
  }
  return (
    <Holder>
      {tourAnm((anm, loaded) => loaded && <TourWrapper style={anm}>{getTour()}</TourWrapper>)}
      <RoomSelector />
      {homeState !== 0 && (
        <CloseButton
          icon={CloseIcon}
          color="#f2f3f4"
          onClick={() => {
            setHomeState(0)
            setTourIndex(0)
          }}
        />
      )}
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 3;
`

const TourWrapper = styled(a.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`
