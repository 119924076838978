import { useState } from "react"
import styled from "styled-components/macro"
import IconButton from "components/Buttons/IconButton"
import Image1 from "assets/images/gallery/film/1.jpg"
import Image2 from "assets/images/gallery/film/2.jpg"
import Image3 from "assets/images/gallery/film/3.jpg"
import Video from "./Video"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"
import { useStore } from "state/store"

export default function FilmGallery() {
  const [imageIndex, setImageIndex] = useState(null)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const placeholders = [
    {
      id: 0,
      image: Image1,
      video: "assets/videos/nine-elms.mp4",
      text: "Nine Elms",
      position: {
        top: "40rem",
        left: "15rem",
      },
      clipPathInitial: "polygon(0% 0%, 50% 100%, 50% 100%, 0 100%, 0 0)",
      clipPathStart: "polygon(10% 0%, 60% 100%, 50% 100%, 0 100%, 0 0)",
      clipPathEnd: "polygon(100% 0%, 150% 100%, 50% 100%, 0 100%, 0 0)",
    },
    {
      id: 1,
      image: Image3,
      video: "assets/videos/lifestyle.mp4",
      text: "Lifestyle",
      position: {
        top: "calc(40rem + 50px)",
        left: "50%",
      },
      clipPathInitial: "polygon(0% 0, 100% 0, 100% 0, 50% 100%, 50% 100%, 0 0)",
      clipPathStart: "polygon(0 0, 100% 0, 100% 50%, 60% 100%, 40% 100%, 0 50%)",
      clipPathEnd: "polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0% 100%, 0 100%)",
    },
    {
      id: 2,
      image: Image2,
      video: "assets/videos/interior.mp4",
      text: "Interior",
      position: {
        top: "40rem",
        right: "15rem",
      },
      clipPathInitial: "polygon(100% 0, 100% 50%, 100% 100%, 50% 100%, 100% 0)",
      clipPathStart: "polygon(100% 0, 100% 50%, 100% 100%, 50% 100%, 100% 0)",
      clipPathEnd: "polygon(100% 0, 100% 50%, 100% 100%, -50% 100%, 0% 0)",
    },
  ]

  const handleClick = index => {
    setImageIndex(index)
    setIsFullScreen(prev => !prev)
  }

  const tabObj = useStore(s => s.tabObj)

  const transition = useTransition(tabObj.first === 2, {
    from: { opacity: 0 },
    enter: { opacity: 1, delay: 700 },
    leave: { opacity: 0 },
  })
  return transition(
    ({ opacity }, show) =>
      show && (
        <TransitionWr style={{ opacity: opacity }}>
          {placeholders.map((el, index) => {
            return (
              <Video
                key={el.id}
                el={el}
                index={index}
                imageIndex={imageIndex}
                isFullScreen={isFullScreen}
                handleClick={handleClick}
              />
            )
          })}
        </TransitionWr>
      ),
  )
}

const TransitionWr = styled(a.div)`
  position: absolute;
  height: 100vh;

  width: 100%;
`
const BackButton = styled(IconButton)`
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 1;
`
