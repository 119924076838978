import { useStore } from "state/store"
import { LONDON_DATA, MAP_DATA } from "../../../dataset/mapData"
import SVGElement from "./SVGElement"

function LocalPOIs() {
  const mapChangeClick = useStore(state => state.mapChangeClick)
  return (
    <>
      {mapChangeClick ? (
        <>
          {LONDON_DATA.map((el, index) => (
            <SVGElement key={index} id={el.id} x={el.x} y={el.y}></SVGElement>
          ))}
        </>
      ) : (
        <>
          {MAP_DATA.map((el, index) => (
            <SVGElement key={index} id={el.id} x={el.x} y={el.y}></SVGElement>
          ))}
        </>
      )}
    </>
  )
}

export default LocalPOIs
