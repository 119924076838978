import React from "react"
import styled from "styled-components/macro"
import Viewer from "./components/Viewer"

export default function Location() {
  return (
    <Holder>
      <Viewer />
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
