import { useTransition, useSpring } from "@react-spring/core"
import { a } from "@react-spring/web"
import CompassIcon from "assets/images/icons/compass_svg.svg"
import { Canvas } from "@react-three/fiber"
import CameraControls from "components/SphericalTour/components/CameraControls"
import Sphere from "components/SphericalTour/components/Sphere"
import { useEffect, useState, Suspense } from "react"
import styled from "styled-components"
import { APART_PROPS } from "dataset/finder"
import { useCallback } from "react"
import { useRef } from "react"
import { MathUtils, Vector3 } from "three"
import { Compass } from "components/BuildingExplorer/BuildingExplorer"
import { OrbitControls } from "@react-three/drei"

function removeLeadingZero(str) {
  return str.startsWith("0") && str.length == 2 ? str.slice(1) : str
}

function addLeadingZero(str) {
  return str.length === 1 ? "0" + str : str
}

function SplitPostalNumber(postalNumber) {
  const letters = postalNumber.split("")
  let output

  if (letters.length === 4) {
    let floor = `${letters[0]}${letters[1]}`
    let unit = `${letters[2]}${letters[3]}`
    output = `${floor}_${removeLeadingZero(unit)}`
    return output
  } else if (letters.length === 3) {
    let floor = `${letters[0]}`
    let unit = `${letters[1]}${letters[2]}`
    output = `${floor}_${removeLeadingZero(unit)}`
    return output
  }
}

function Viewer360({ activeApartment }) {
  // const { min, max, hasZoom } = angleLimit(activeApartment[APART_PROPS.aspect])

  // let floor = activeApartment[APART_PROPS.floor]

  // const compassRef = useRef()

  // const compassCallback = useCallback(v => {
  //   let lat = MathUtils.radToDeg(v.theta) * -1
  //   compassRef.current.style.transform = `rotate(${lat - 200}deg)`
  // }, [])

  const [renderImage, set] = useState(false)

  const transition = useTransition(renderImage, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 200 },
    leave: { opacity: 0, delay: 0 },
  })

  useEffect(() => {
    setTimeout(() => set(true), 600)
  }, [])

  // const [active] = useState(0)

  // const viewImage = viewMapper[activeApartment[APART_PROPS.floor]]
  // const fullScreenViews = useStore(s => s.fullScreenViews)

  // const { opacity2 } = useSpring({
  //   opacity2: fullScreenViews ? 0 : 1,
  // })

  const canvasRef = useRef()
  const ref = useRef()

  return transition(
    ({ opacity }, item) =>
      item && (
        <Wrapper ref={ref} style={{ opacity: opacity, color: "white" }}>
          {/* <CompassUI style={{ opacity: opacity2 }}>
            <North>N</North>
            <img ref={compassRef} alt="" src={CompassIcon} />
          </CompassUI> */}
          {renderImage && (
            <Suspense fallback={null}>
              <Canvas
                gl={{ preserveDrawingBuffer: true }}
                ref={canvasRef}
                camera={{ position: new Vector3(-1, 0, 0) }}>
                {/* <Compass compassCallback={compassCallback} /> */}
                <OrbitControls enableZoom={false} autoRotate={false} rotateSpeed={-0.3} />
                {/* <CameraControls
                  hasZoom={hasZoom}
                  active={active}
                  hasLimit={true}
                  compassCallback={compassCallback}
                  min={min}
                  max={max}
                  vOffset={vOffset[floor]}
                  minPolarAngle={floor > 5 ? 1.2 : Math.PI / 2}
                  maxPolarAngle={floor > 5 ? 2 : 2}
                /> */}
                <Sphere texture={SplitPostalNumber(activeApartment["Postal Number"])} />
              </Canvas>
            </Suspense>
          )}
        </Wrapper>
      ),
  )
}

// const North = styled.div`
//   position: absolute;
//   width: 100%;
//   color: #b48465;
//   font-family: Bliss-Light;
//   display: flex;
//   justify-content: center;
//   transform: translate(0, -140%);
// `

// const CompassUI = styled(a.div)`
//   z-index: 2;
//   position: fixed;
//   right: 6em;
//   top: 2em;
//   min-width: 3.5em;
//   max-width: 3.5em;
//   min-height: 3.5em;
//   max-height: 3.5em;
//   box-sizing: border-box;
//   border: #b48465 solid 2px;
//   border: 100%;
//   border-radius: 100%;

//   & img {
//     scale: 1.3;
//   }
// `

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`

export default Viewer360
