import React, { useRef, useEffect } from "react"
import { a, useSpring } from "react-spring"
import { useStore } from "state/store"
import styled from "styled-components/macro"
import travel_overlay from "./travel-overlay.svg"
import Map from "./tube_map.jpg"
import Logo from "./underground.svg"

const ImageSlider = () => {
  const setActiveLocationTabIndex = useStore(state => state.setActiveLocationTabIndex)
  const activeLocationTabIndex = useStore(s => s.activeLocationTabIndex)
  const mapOpen = useRef(false)

  const [mapAnm, mapApi] = useSpring(() => ({ opacity: 0 }))

  const handleOpen = () => {
    setActiveLocationTabIndex(0)
  }

  function openMap() {
    mapOpen.current = !mapOpen.current
    mapApi.start({ opacity: mapOpen.current ? 1 : 0 })
  }

  useEffect(() => {
    return () => {
      mapApi.start({ opacity: 0 })
      setActiveLocationTabIndex(0)
    }
  }, [])

  return (
    <Drawer open={activeLocationTabIndex === 3}>
      <Button
        onClick={() => {
          openMap()
        }}>
        <img src={Logo} alt="UnderGround Map" />
      </Button>
      <a.img style={mapAnm} className="map" src={Map} alt="UnderGround Map" />
      <img
        id="travel_overlay"
        src={travel_overlay}
        alt="travel_overlay"
        width="100%"
        height="100%"
        onClick={() => {
          handleOpen()
        }}
      />
    </Drawer>
  )
}

const Drawer = styled(a.div)`
  z-index: 9999;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  height: 100vh;
  color: white;
  background-color: #1d1e1b;
  opacity: ${props => (props.open && 1) || 0};
  left: ${props => (props.open ? 0 : "100%")};
  transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;

  & text {
    cursor: pointer;
  }

  & img {
    object-fit: contain;
    object-position: right;
    cursor: pointer;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .map {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: black;
  }
`

const Button = styled.div`
  position: fixed;
  z-index: 5;
  top: 2%;
  left: 5%;
  width: 5rem;
  height: 2rem;
  cursor: pointer;

  & img {
    max-width: 100%;
  }
`

export default ImageSlider
