const BookmarkIcon = ({ color = "#fff" }) => {
  return (
    <svg width="48" height="78" viewBox="0 0 48 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 0H0V78H48V0ZM24.0008 43.4159C31.0598 35.0478 37.1513 40.8269 38.1218 42.5548V42.5665C39.8896 45.7596 38.8295 50.5843 36.36 52.9193L24.0008 65L11.6415 52.9193C9.16905 50.5901 8.11199 45.7655 9.87675 42.5548C10.8621 40.8269 16.5834 35.0478 24.0008 43.4159Z"
        fill={color}
      />
    </svg>
  )
}

export default BookmarkIcon
