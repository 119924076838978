export const copyToClipboard = (string, cb) => {
  let textarea
  let result

  try {
    textarea = document.createElement("textarea")
    textarea.setAttribute("readonly", true)
    textarea.setAttribute("contenteditable", true)
    textarea.style.position = "fixed"
    textarea.value = string
    document.body.appendChild(textarea)
    textarea.focus()
    textarea.select()
    const range = document.createRange()
    range.selectNodeContents(textarea)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    textarea.setSelectionRange(0, textarea.value.length)
    result = document.execCommand("copy")
  } catch (err) {
    result = null
  } finally {
    document.body.removeChild(textarea)
  }

  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
    const copyHotkey = isMac ? "⌘C" : "CTRL+C"
    result = prompt(`Press ${copyHotkey}`, string)

    if (!result) {
      return false
    }
  }

  cb(string)

  return true
}
