import styled from "styled-components/macro"
import { AnimatePresence, motion } from "framer-motion"

function TriangleCaption({ content, load }) {
  return (
    <AnimatePresence>
      {load && (
        <Drawer
          style={content?.style}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: {
              y: "-100%",
              opacity: 0,
            },
            animate: {
              y: 0,
              opacity: 1,
            },
            exit: {
              y: "-100%",
              opacity: 0,
            },
          }}
          transition={{ duration: 0.5 }}>
          <svg
            width="100%"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 635 439.5">
            <path transform="translate(5 0)" stroke="#B58762" d="M1,0l306,437.7L624,0" />

            {content?.text?.map((line, i) => {
              return (
                <text x="50%" y={`${20 + i * 10}%`} textAnchor="middle" fill="#B48465" fontSize="15px" key={`text${i}`}>
                  {line}
                </text>
              )
            })}
          </svg>
        </Drawer>
      )}
    </AnimatePresence>
  )
}

export default TriangleCaption

const Drawer = styled(motion.div)`
  position: fixed;
  pointer-events: auto;
  width: 25%;
  height: 20em;
  color: white;
  overflow: visible;
 
  & text {
    cursor: pointer;
    font-family: Bliss-light;
    letter-spacing: 0.1rem;
  }
`
