/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react"

const useNotInitialRender = (func, deps) => {
  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export default useNotInitialRender