import Residences from "pages/Residences"
import Favorites from "pages/Favorites"
import GalleryLayout from "pages/Gallery"
import Share from "pages/Share"
import Showhome from "pages/Showhome"
import Location from "pages/Location"
import About from "pages/About"
import Home from "pages/Home"
import Splash from "pages/Splash"

export const PrimaryRoutes = [
  {
    path: "/",
    title: "Splash",
    element: <Splash />,
    hasChildren: false,
  },
  {
    path: "/home",
    title: "Home",
    element: <Home />,
    hasChildren: false,
  },
  {
    path: "/residences",
    title: "Residences",
    element: <Residences />,
    hasChildren: false,
  },
  {
    path: "/showhome",
    title: "Tour",
    element: <Showhome />,
    hasChildren: false,
  },
  {
    path: "/location",
    title: "Location",
    element: <Location />,
    hasChildren: false,
  },
  {
    path: "/gallery",
    title: "Gallery",
    element: <GalleryLayout />,
    hasChildren: true,
  },
  {
    path: `/${btoa("encodeEmailGallery")}`,
    title: "Gallery",
    element: <GalleryLayout />,
    hasChildren: true,
  },
  {
    path: "/about",
    title: "About",
    element: <About />,
    hasChildren: false,
  },
]

export const pageNotFound = {
  path: "*",
  title: "Error 404",
  element: <div>Error 404 Component Here</div>,
}

export const FavoritesRoute = {
  path: "/favourites",
  title: "Favourites",
  element: <Favorites />,
  hasChildren: false,
}

export const ShareRoute = {
  path: "/share",
  title: "Share",
  element: <Share />,
  hasChildren: false,
}
