import React, { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { DoubleSide, MathUtils } from "three"

import { useStore } from "state/store"

function HotSpot({ theta }) {
   const sphereRef = useRef()
 
   const setTourIndex = useStore(s => s.setTourIndex)
 
   const speed = 3
 
   const min = 0.07
   const max = 0.12
 
   useFrame(({ clock }) => {
     sphereRef.current.scale.x =
       Math.sin(clock.getElapsedTime() * speed) / 2 + 0.5 + 0.6
 
     sphereRef.current.scale.x = MathUtils.mapLinear(
       Math.sin(clock.getElapsedTime() * speed) / 2 + 0.5,
       0,
       1,
       min,
       max
     )
     sphereRef.current.scale.y = MathUtils.mapLinear(
       Math.sin(clock.getElapsedTime() * speed) / 2 + 0.5,
       0,
       1,
       min,
       max
     )
     sphereRef.current.scale.z = MathUtils.mapLinear(
       Math.sin(clock.getElapsedTime() * speed) / 2 + 0.5,
       0,
       1,
       min,
       max
     )
 
     sphereRef.current.material.opacity = MathUtils.lerp(
       sphereRef.current.material.opacity,
       0.4,
       0.3
     )
   })
 
   return (
     <group scale={1}>
       <mesh
         position={[
           Math.sin(theta[0]) * 200,
           Math.cos(theta[1]) * 200,
           Math.cos(theta[0]) * 200
         ]}
         ref={sphereRef}>
         <sphereBufferGeometry args={[60, 64, 64]} />
         <meshBasicMaterial
           side={DoubleSide}
           color={"#B58762"}
           transparent={true}
           opacity={0.5}
         />
       </mesh>
       <mesh
         position={[
           Math.sin(theta[0]) * 500,
           Math.cos(theta[1]) * 500,
           Math.cos(theta[0]) * 500
         ]}
         onClick={e => {
           e.stopPropagation()
           setTourIndex(theta[2])
         }}>
         <sphereBufferGeometry args={[40, 64, 64]} />
         <meshStandardMaterial transparent={true} opacity={0} />
       </mesh>
     </group>
   )
 }

 export default HotSpot