export const LONDON_DATA = [
  {
    id: "amenities-1",
    x: "31.45",
    y: "45.46",
  },
  {
    id: "amenities-2",
    x: "68.41",
    y: "17.15",
  },
  {
    id: "amenities-3",
    x: "34.24",
    y: "38.54",
  },
  {
    id: "amenities-4",
    x: "70.59",
    y: "15.25",
  },
  {
    id: "amenities-5",
    x: "59.2",
    y: "11.57",
  },
  {
    id: "amenities-6",
    x: "62.91",
    y: "6.52",
  },
  {
    id: "amenities-7",
    x: "82.93",
    y: "15.82",
  },
  {
    id: "amenities-8",
    x: "89.92",
    y: "17.15",
  },
  {
    id: "amenities-9",
    x: "7.78",
    y: "50.65",
  },
  {
    id: "amenities-10",
    x: "19.76",
    y: "29.11",
  },
  {
    id: "amenities-11",
    x: "23.17",
    y: "29.78",
  },
  {
    id: "amenities-12",
    x: "48.46",
    y: "14.51",
  },
  {
    id: "retail-1",
    x: "21.86",
    y: "45.6",
  },
  {
    id: "retail-2",
    x: "31.27",
    y: "24.8",
  },
  {
    id: "retail-3",
    x: "49.75",
    y: "5.74",
  },
  {
    id: "retail-4",
    x: "38.46",
    y: "5.95",
  },
  {
    id: "education-1",
    x: "80.52",
    y: "33.38",
  },
  {
    id: "education-2",
    x: "51.06",
    y: "1.07",
  },
  {
    id: "education-3",
    x: "81.96",
    y: "29.52",
  },
  {
    id: "education-4",
    x: "71.14",
    y: "10.12",
  },
  {
    id: "education-5",
    x: "71.92",
    y: "8.09",
  },
  {
    id: "education-6",
    x: "21.95",
    y: "29.57",
  },
  {
    id: "education-7",
    x: "73.08",
    y: "1.07",
  },
  {
    id: "education-8",
    x: "50.39",
    y: "59.07",
  },
  {
    id: "education-9",
    x: "50.86",
    y: "60.61",
  },
  {
    id: "education-10",
    x: "37.42",
    y: "63.26",
  },
  {
    id: "education-11",
    x: "67.95",
    y: "39.22",
  },
  {
    id: "education-12",
    x: "32.09",
    y: "64.2",
  },
  {
    id: "education-13",
    x: "87.8",
    y: ".79",
  },
  {
    id: "education-14",
    x: "97.76",
    y: "31.94",
  },
  {
    id: "education-15",
    x: "110.67",
    y: "43.12",
  },
  {
    id: "education-16",
    x: "78.29",
    y: "29.15",
  },
  {
    id: "education-17",
    x: "72.02",
    y: "27.24",
  },
  {
    id: "education-18",
    x: "48.98",
    y: "29.35",
  },
  {
    id: "education-19",
    x: "79.3",
    y: "49.22",
  },
  {
    id: "education-20",
    x: "103.03",
    y: "34.34",
  },
]

export const MAP_DATA = [
  {
    id: "amenities-7",
    path: "M2296.03,441.47c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 2289.93994140625,
    y: 422.2900085449219,
  },
  {
    id: "amenities-6",
    path: "M439.98,907.47c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 433.8900146484375,
    y: 888.2899169921875,
  },
  {
    id: "amenities-5",
    path: "M1471.21,773.92c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1465.1199951171875,
    y: 754.7399291992188,
  },
  {
    id: "amenities-4",
    path: "M316.4,579.4c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 310.30999755859375,
    y: 560.219970703125,
  },
  {
    id: "amenities-3",
    path: "M2326.18,199.82c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 2320.08984375,
    y: 180.6400146484375,
  },
  {
    id: "amenities-2",
    path: "M1079.49,1190.78c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1073.4000244140625,
    y: 1171.60009765625,
  },
  {
    id: "amenities-1",
    path: "M1727.21,749.15c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1721.1199951171875,
    y: 729.969970703125,
  },

  {
    id: "food-10",
    path: "M2245.62,266.97c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 2234.530029296875,
    y: 247.79000854492188,
  },
  {
    id: "food-9",
    path: "M2380.49,361.12c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 2374.39990234375,
    y: 341.94000244140625,
  },
  {
    id: "food-8",
    path: "M1121.67,878.02c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1115.580078125,
    y: 858.8399658203125,
  },
  {
    id: "food-7",
    path: "M1032.11,825.18c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1026.0199584960938,
    y: 805.9999389648438,
  },
  {
    id: "food-6",
    path: "M1103.28,738.9c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1097.1900634765625,
    y: 719.719970703125,
  },
  {
    id: "food-5",
    path: "M1470.48,934.54c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1464.3900146484375,
    y: 915.3599243164062,
  },
  {
    id: "food-4",
    path: "M1906.4,841.2c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1900.31005859375,
    y: 822.0199584960938,
  },
  {
    id: "food-3",
    path: "M1537.11,906.59c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1531.02001953125,
    y: 887.4099731445312,
  },
  {
    id: "food-2",
    path: "M1831.44,895.28c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1825.3499755859375,
    y: 876.0999755859375,
  },
  {
    id: "food-1",
    path: "M1594.22,882.1c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1588.1300048828125,
    y: 862.919921875,
  },

  {
    id: "leisure-2",
    path: "M1320.8,851.19c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1314.7100830078125,
    y: 832.0099487304688,
  },
  {
    id: "leisure-1",
    path: "M1370.77,819.32c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 1364.6800537109375,
    y: 800.1399536132812,
  },

  {
    id: "travel-1",
    path: "M2158.85,1314.16c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 2152.760009765625,
    y: 1294.9801025390625,
  },
  {
    id: "travel-2",
    path: "M632.85,1390.59c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 626.7599487304688,
    y: 1371.4100341796875,
  },
  {
    id: "travel-3",
    path: "M133.18,1187.06c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 127.08999633789062,
    y: 1167.880126953125,
  },
  {
    id: "travel-4",
    path: "M2653.35,237.06c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 2647.260009765625,
    y: 217.8800048828125,
  },
  {
    id: "travel-5",
    path: "M319.41,605.32c13.86,0,25.09-11.23,25.09-25.09s-11.23-25.09-25.09-25.09-25.09,11.23-25.09,25.09,11.23,25.09,25.09,25.09",
    x: 313.32000732421875,
    y: 586.1399536132812,
  },
]
