import { OrbitControls, OrthographicCamera } from "@react-three/drei"
import { isMobile } from "react-device-detect"

export default function GalleryCamera() {
  // return <OrbitControls />
  return (
    <OrthographicCamera
      zoom={isMobile ? 1200 : 2000}
      makeDefault={true}
      args={[-1, 1, 1, -1, 0, 1000]}
    />
  )
}
