import React, { useRef, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import PlayButton from "assets/images/icons/play-button.svg"

export default function Video({ el, index, imageIndex, isFullScreen, handleClick }) {
  const active = index === imageIndex && imageIndex !== null
  const playerRef = useRef()
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  return (
    <>
      <Holder
        id={index}
        key={index}
        position={el.position}
        el={el}
        onClick={e => {
          handleClick(index)
        }}>
        {active && isFullScreen && (
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="100%"
            stopOnUnmount={true}
            loop={true}
            muted={false}
            playing={active && isFullScreen}
            config={{
              file: {
                attributes: {
                  poster: "noposter",
                },
              },
            }}
            url={el.video}
          />
        )}
        <div
          onTransitionEnd={() => setIsVideoVisible(prev => !prev)}
          style={{ opacity: isFullScreen && !active ? 0 : isFullScreen && active ? 0 : 1 }}
          className={isFullScreen && active ? "placeholder fullScreen" : "placeholder thumbnail"}>
          <div className="caption">
            <h2>{el.text}</h2>
          </div>
        </div>
      </Holder>
    </>
  )
}

const Holder = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;

  & video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  & .caption {
    position: absolute;
    top: ${props => props.position?.top};
    left: ${props => props.position?.left};
    right: ${props => props.position?.right};
    transform: ${props => (props.el.id === 1 ? "translateX(-50%)" : "none")};
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    z-index: 10;
  }

  & .playButton {
    height: 4em;
  }

  & .placeholder {
    margin-top: ${props => (props.el.id === 1 ? "-50px" : "0")};
    background-image: url(${props => props.el.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    clip-path: ${props => props.el.clipPathInitial};
    cursor: pointer;
    opacity: 1;
    transition: all ease-in-out 0.6s;

    &.fullScreen {
      z-index: 99;
      clip-path: ${props => props.el.clipPathStart};
      clip-path: ${props => props.el.clipPathEnd};
      width: calc(100% + 50px);
      height: calc(100vh + 50px);
    }

    &.thumbnail {
      z-index: 9;
      clip-path: ${props => props.el.clipPathInitial};
    }
  }

  & .placeholder::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 55%);
  }
`

// const Holder = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100vh;

//   & .caption {
//     position: absolute;
//     top: 15rem;
//     left: 50%;
//     transform: translateX(-50%);
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     gap: 1em;
//     z-index: 10;
//   }

//   & .playButton {
//     height: 4em;
//   }

//   & .placeholder {
//     background-image: url(${Image2});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: right;
//     margin-top: -100px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 1;
//     width: 100%;
//     height: 100vh;
//     object-fit: cover;
//     clip-path: polygon(0% 0, 100% 0, 100% 0, 50% 100%, 50% 100%, 0 0);
//     cursor: pointer;
//     opacity: 1;
//     transition: all ease-in-out 0.6s;

//     &.fullScreen {
//       z-index: 99;
//       margin-top: 0px;
//       clip-path: polygon(0 0, 100% 0, 100% 50%, 60% 100%, 40% 100%, 0 50%);
//       clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0% 100%, 0 100%);
//       width: calc(100% + 100px);
//       height: calc(100vh + 100px);
//     }

//     &.thumbnail {
//       z-index: 9;
//       clip-path: polygon(0% 0, 100% 0, 100% 0, 50% 100%, 50% 100%, 0 0);
//     }
//   }

//   & .placeholder::before {
//     content: "";
//     position: absolute;
//     top: 0px;
//     right: 0px;
//     bottom: 0px;
//     left: 0px;
//     background-color: rgb(0 0 0 / 55%);
//   }
// `

// const Holder = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100vh;

//   & .caption {
//     position: absolute;
//     top: 32rem;
//     right: 9rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     gap: 1em;
//     z-index: 10;
//   }

//   & .playButton {
//     height: 4em;
//   }

//   & .placeholder {
//     background-image: url(${Image3});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: right;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 1;
//     width: 100%;
//     height: 100vh;
//     object-fit: cover;
//     clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 100%, 100% 0);
//     cursor: pointer;
//     opacity: 1;
//     transition: all ease-in-out 0.6s;

//     &.fullScreen {
//       z-index: 99;
//       clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 100%, 100% 0);
//       clip-path: polygon(100% 0, 100% 50%, 100% 100%, -50% 100%, 0% 0);
//       width: calc(100% + 50px);
//       height: calc(100vh + 50px);
//     }

//     &.thumbnail {
//       z-index: 9;
//       clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 100%, 100% 0);
//     }
//   }

//   & .placeholder::before {
//     content: "";
//     position: absolute;
//     top: 0px;
//     right: 0px;
//     bottom: 0px;
//     left: 0px;
//     background-color: rgb(0 0 0 / 55%);
//   }
// `
