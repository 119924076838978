import * as THREE from "three"
import { nanoid } from "nanoid"
import { extend } from "@react-three/fiber"
import { useEffect } from "react"
import { DoubleSide } from "three"
import { useStore } from "state/store"
import { useSpring } from "@react-spring/core"
import { animated } from "@react-spring/three"

import glsl from "babel-plugin-glsl/macro"

function shaderMaterial(uniforms, vertexShader, fragmentShader, onInit) {
  const material = class material extends THREE.RawShaderMaterial {
    constructor() {
      const entries = Object.entries(uniforms) // Create unforms and shaders

      super({
        uniforms: entries.reduce((acc, [name, value]) => {
          const uniform = THREE.UniformsUtils.clone({
            [name]: {
              value,
            },
          })
          return { ...acc, ...uniform }
        }, {}),
        vertexShader,
        fragmentShader,
      }) // Create getter/setters
      this.key = ""
      entries.forEach(([name]) =>
        Object.defineProperty(this, name, {
          get: () => this.uniforms[name].value,
          set: v => (this.uniforms[name].value = v),
        }),
      )
      if (onInit) onInit(this)
    }
  }
  material.key = nanoid()
  return material
}

const AnimShaderMat = shaderMaterial(
  //uniform
  {
    uDiffuse: new THREE.Texture(),
    uIntensity: 1,
    uOpacity: 0,
    uActiveElementID: 0,
  },
  glsl`
    precision mediump float;


    in float floorAtr;
    in float vertID;
    in float IDAtt;
    in vec3 position;
    in vec2 uv;
    
    uniform vec2 resolution;
    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;

    out float vFloorAtr;
    out float vVertID;
    out float vIDAtt;
    out vec2 vUv;
    out vec3 pos;

    void main(){
      vFloorAtr=floorAtr;
      vVertID=vertID;
      vIDAtt=IDAtt;
      vUv=uv;
      pos=position;
      gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.);
    }

`,
  //FRAGMENT
  glsl`
    precision mediump float;

    uniform sampler2D uDiffuse;
    uniform float uIntensity;
    uniform float uOpacity;
    uniform float uActiveElementID;
    uniform float uActiveFloor;

    in float vFloorAtr;
    in float vVertID;
    in float vIDAtt;
    in vec2 vUv;
    in vec3 pos;
   
    out vec4 fragColor;


    float sRGB(float x) {
      if (x <= 0.00031308)
          return 12.92 * x;
      else
          return 1.055*pow(x,(1.0 / 2.4) ) - 0.055; 
      }


    vec3 sRGB_v3(vec3 c) {
        return vec3(sRGB(c.x),sRGB(c.y),sRGB(c.z));
    }


    void main()
    {      
    vec4 diffuse =  texture( uDiffuse, vUv);
    vec4 col = vec4(diffuse.rgb, 1.);  

    if(abs(vVertID - uActiveElementID) < 0.5) {
        fragColor = vec4(1.00, 0.93, 0.76, uOpacity);
    } else{         
      if(vIDAtt < 0.5){
        if(vFloorAtr > 0.5 ){
          fragColor = vec4(col.rgb * 1., uOpacity);
        } else {
          fragColor = vec4(col.rgb * 0.5, uOpacity);
        }
      } else {
        fragColor = vec4(sRGB_v3(col.rgb) * 0.175, uOpacity);
      }
    }


}
`,
)

extend({ AnimShaderMat })

const AnimatedBakedShader = animated("animShaderMat")

function RoomLightsMaterial({ map, intensity }) {
  const activeElementID = useStore(s => s.activeElementID)

  const [{ opacity }, api] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      delay: 800,
    }),
    [],
  )

  useEffect(() => {
    api.start()
  }, [api, activeElementID])

  return (
    <AnimatedBakedShader
      tonemapped={false}
      uOpacity={opacity}
      toneMapped={false}
      uDiffuse={map}
      uActiveElementID={activeElementID}
      side={DoubleSide}
      uIntensity={intensity}
      glslVersion={THREE.GLSL3}
      flatShading={true}
      transparent={1}
      attach="material"
    />
  )
}

export default RoomLightsMaterial
