import React, { useRef } from "react"
import styled from "styled-components"
import { a, useSpring } from "react-spring"

function AboutCardThree({ data }) {
  const [imgStyle, imgApi] = useSpring(() => ({
    clipPath: "polygon(60% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 100%)",
  }))
  const [copyStyle, copyApi] = useSpring(() => ({ opacity: 1 }))
  const isOpen = useRef(false)

  function handleClick() {
    isOpen.current = !isOpen.current
    imgApi.start({
      clipPath: isOpen.current
        ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%)"
        : "polygon(60% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 100%)",
      delay: isOpen.current ? 500 : 0,
    })
    copyApi.start({
      opacity: isOpen.current ? 0 : 1,
      delay: isOpen.current ? 0 : 500,
    })
  }
  return (
    <Wrapper
      onClick={() => {
        handleClick()
      }}>
      <Image route={data.image} style={imgStyle}/>
      <Copy style={copyStyle}>
        <h1> {data.title} </h1>
        <Text>
          {data.copy.map((text, i) => {
            return <p key={`p${i}`}>{text}</p>
          })}
        </Text>
      </Copy>
    </Wrapper>
  )
}

export default AboutCardThree

const Wrapper = styled.div`
  position: absolute;
  width: 85%;
  height: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
`

const Image = styled(a.div)`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${p => `url(${p.route})`};
  background-position: 0 0;
  background-size: cover;
`
const Copy = styled(a.div)`
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 5%;
  width: 50%;
  height: 90%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  clip-path: polygon(0% 0%, 100% 0%, 8% 100%, 0% 100%);

  & h1 {
    position: relative;
    z-index: 1;
    width: 50%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 100;
    margin-left: 5%;
  }
`

const Text = styled.div`
  width: 60%;
  position: relative;
  z-index: 1;

  & p {
    font-family: Bliss-Light;
    font-weight: 100;
    font-size: 0.8rem;
    text-align: center;
    margin: 5% auto;
    color: #00000099;

    :nth-of-type(1) {
      color: #b58762;
    }
  }
`
