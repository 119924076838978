import React from "react"
import { createRoot } from "react-dom/client"
import { HashRouter, BrowserRouter } from "react-router-dom"
import App from "./App"
import { QueryClient, QueryClientProvider } from "react-query"
import { WebSocketProvider } from "./throp/WebSocketProvider"
import { ErrorProvider } from "errors/ErrorProvider"

const queryClient = new QueryClient()

const container = document.getElementById("app")
const root = createRoot(container) // createRoot(container!) if you use TypeScript

const setRender = () => {
  if (window.api !== undefined) {
    return (
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <WebSocketProvider url="ws://192.168.1.38:7890">
            <ErrorProvider>
              <App />
            </ErrorProvider>
          </WebSocketProvider>
        </QueryClientProvider>
      </HashRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <WebSocketProvider url="ws://192.168.1.38:7890">
            <ErrorProvider>
              <App />
            </ErrorProvider>
          </WebSocketProvider>
        </QueryClientProvider>
      </BrowserRouter>
    )
  }
}

root.render(setRender())
