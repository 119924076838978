import styled from "styled-components/macro"
import { AnimatePresence, motion } from "framer-motion"
import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import { availability } from "utils/convertDataFormat"
import FavouriteButton from "components/ApartmentCard/components/FavouriteButton"
import CloseIcon from "assets/images/icons/close.svg"

function ApartmentCardMini() {
  const activeApartment = useStore(s => s.activeApartment)
  const setFullCardOpen = useStore(s => s.setFullCardOpen)
  const setActiveApartment = useStore(s => s.setActiveApartment)
  const setActiveElementID = useStore(s => s.setActiveElementID)

  const handleOpen = () => {
    setFullCardOpen(true)
  }
  return (
    <AnimatePresence>
      {activeApartment && (
        <Drawer
          initial="initial"
          animate="animate"
          exit="exit"
          variants={{
            initial: {
              y: "-100%",
              opacity: 0,
            },
            animate: {
              y: 0,
              opacity: 1,
            },
            exit: {
              y: "-100%",
              opacity: 0,
            },
          }}
          transition={{ duration: 0.8 }}>
          <svg
            width="100%"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 625 439.5">
            <path transform="translate(5 0)" stroke="#B58762" d="M1,0l306,437.7L624,0" />
            {/* <path  stroke="#B58762" d="M1,0l306,437.7L624,0" /> */}

            <text
              onClick={handleOpen}
              x="50%"
              y="25%"
              textAnchor="middle"
              fill="#B48465"
              fontFamily="'grande'"
              fontSize="92px">
              <text>&#9826; </text>
              {activeApartment && activeApartment[APART_PROPS.plot]}
              <text> &#9826;</text>
            </text>
            <rect x="154.4" y="222.8" fill="none" width="314.6" height="78.4" />
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              fill="#B48465"
              fontFamily="'BlissLight'"
              fontSize="24px">
              {activeApartment && activeApartment[APART_PROPS.fullName].toUpperCase()}
            </text>
            <rect x="149.7" y="262" fill="none" width="314.6" height="78.4" />
            <text
              x="50%"
              y="60%"
              textAnchor="middle"
              fill="#AD976E"
              fontFamily="'BlissRegular'"
              fontSize="26px">
              {activeApartment &&
                availability(activeApartment["Availability"], activeApartment[APART_PROPS.price])}
            </text>
            <text
              x="50%"
              y="70%"
              textAnchor="middle"
              fill="#AD976E"
              fontFamily="'BlissRegular'"
              fontSize="21px">
              {activeApartment && activeApartment[APART_PROPS.beds]} {activeApartment && "BED"}
            </text>
          </svg>
          <FavWrapper>
            <FavouriteButton />
          </FavWrapper>
          <CloseWrapper
            onClick={() => {
              setActiveElementID(null)
              setActiveApartment(null)
            }}>
            <img src={CloseIcon} alt="" />
          </CloseWrapper>
        </Drawer>
      )}
    </AnimatePresence>
  )
}

export default ApartmentCardMini

const CloseWrapper = styled.div`
  cursor: pointer;
  z-index: 99;
  position: absolute;
  bottom: 17%;
`
const FavWrapper = styled.div`
  z-index: 99;
  position: absolute;
  top: 33%;
`

const Drawer = styled(motion.div)`
  /* border: red solid 1px; */
  z-index: 11;
  position: fixed;
  pointer-events: auto;
  top: 0;
  left: 0em;

  z-index: 2;
  width: 40%;
  height: 20em;
  color: white;

  & text {
    cursor: pointer;
  }

  /* background-color: #b48465cc; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 3em;
`
