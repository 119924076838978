import styled from "styled-components/macro"

export default function IconButton({ onClick, className, icon, color = "transparent" }) {
  return (
    <Button className={className} onClick={onClick} color={color}>
      <img src={icon} alt=""></img>
    </Button>
  )
}

const Button = styled.div`
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => props.color};

  &:hover {
    opacity: 0.7;
  }
`
