import { Environment, OrbitControls } from "@react-three/drei"
import { Canvas, useFrame } from "@react-three/fiber"
import { Suspense, useEffect } from "react"
import styled from "styled-components/macro"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"
import Building from "./elements/Building"

import EnvHdr from "assets/images/lighting/pano_c.hdr"
import Apartments from "./elements/ApartmentModels"
import { useStore } from "state/store"
import { useState } from "react"
import LandscapeMain, { Roads } from "./landscape/LandscapeMain"
import { LoadingManager, Spherical, Vector3 } from "three"
import { useRef } from "react"
import PostProcessing from "./PostProcessing/PostProcessing"

import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"
import { GLTFLoader } from "three-stdlib"

import flat from "assets/models/flat.glb"
import floorplates from "assets/models/floorplates.glb"
import glass from "assets/models/glass.glb"
import concrete from "assets/models/concrete.glb"
import blackrail from "assets/models/blackrail.glb"
import black from "assets/models/black.glb"
import brass from "assets/models/brass.glb"
// import window from "assets/models/window.glb"
// import arrow from "assets/models/arrow.glb"
import roads from "assets/models/roads.glb"
import concreteimg from "assets/textures/concrete_d.jpg"
import flat_d from "assets/textures/flat_d.jpg"

const manager = new LoadingManager()

useLoader.preload(TextureLoader, [concreteimg, flat_d], () => /* console.log("loaded text") */ {})
useLoader.preload(
  GLTFLoader,
  [glass, concrete, blackrail, black, brass, floorplates, roads],
  e => console.log(e),
  () => console.log("....loading"),
  () => console.log("....loading"),
  () => console.log("....loading"),
)

let models = {
  glass: glass,
  concrete: concrete,
  blackrail: blackrail,
  black: black,
  floorplates: floorplates,
  brass: brass,
  // window: window,
  roads: roads,
}
let textures = {
  concreteimg: concreteimg,
  flat_d: flat_d,
}

export default function BuildingExplorer({ compassCallback, rotationRef, socket, lightUpUnits }) {
  const [loadDelay, setLoadDelay] = useState(false)

  function resolveAfter2Seconds() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve("resolved")
      }, 2700)
    })
  }

  useEffect(() => {
    resolveAfter2Seconds().then(data => {
      setLoadDelay(true)
    })
    return () => {
      setLoadDelay(false)
    }
  }, [])

  return (
    <>
      <Loader loadDelay={loadDelay} />
      {loadDelay && (
        <Canvas
          mode="concurrent"
          dpr={window.devicePixelRatio}
          gl={{ alpha: true }}
          camera={{ position: [0.56, 180, 516.345], near: 400, far: 1250, fov: 30 }}>
          <LandscapeMain />
          {/* <PostProcessing arrow={arrow} model={floorplates} rotationRef={rotationRef} /> */}
          <Compass compassCallback={compassCallback} />
          <Suspense fallback={null}>
            <CameraControls />
            <Environment intensity={1} background={false} files={EnvHdr} />
            <group position={[0, 15, 0]}>
              <Roads model={roads} />
              <Apartments
                model={flat}
                texture={flat_d}
                socket={socket}
                lightUpUnits={lightUpUnits}
              />
              <Building textures={textures} models={models} />
            </group>
          </Suspense>
        </Canvas>
      )}
    </>
  )
}

function CameraControls() {
  const controlsRef = useRef()

  const origin = new Vector3(0, 0, 0)
  useFrame(({ camera }) => {
    // console.log(camera.position)
    controlsRef.current.target = origin
  })

  return (
    <OrbitControls
      ref={controlsRef}
      enablePan={false}
      minPolarAngle={1.4}
      maxPolarAngle={1.5}
      minDistance={500}
      maxDistance={550}
    />
  )
}

export function Compass({ compassCallback }) {
  var dir = new Vector3()
  var sph = new Spherical()
  useFrame(({ camera }) => {
    camera.getWorldDirection(dir)
    sph.setFromVector3(dir)
    compassCallback(sph.setFromVector3(dir))
  })

  return null
}

function Loader({ loadDelay }) {
  const modelsLoaded = useStore(s => s.modelsLoaded)

  console.log(modelsLoaded)

  const transition = useTransition(loadDelay && !modelsLoaded, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
    config: { duration: 70 },
  })

  return transition(
    ({ opacity }, item) =>
      item && !modelsLoaded && <LoaderWr style={{ opacity: opacity }}>...Loading</LoaderWr>,
  )
}

const LoaderWr = styled(a.div)`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 6rem;
  height: 6rem;
  bottom: 12%;
  left: 45%;
  color: #b48465;
`
