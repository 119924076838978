import styled from "styled-components/macro"

import { useStore } from "state/store"
import { useState } from "react"
import { useEffect } from "react"

const tabs = [
  [
    {
      title: "Concierge",
      to: 0,
    },
    {
      title: "Outside",
      to: 1,
    },
    {
      title: "Residence",
      to: 2,
    },
  ],
  [
    {
      title: "Amenities",
      to: 0,
    },
    {
      title: "Culinary",
      to: 1,
    },
    {
      title: "Culture",
      to: 2,
    },
    {
      title: "Retail",
      to: 3,
    },
  ],
]

export default function GallerySubTab() {
  const tabObj = useStore(s => s.tabObj)
  const setTabObject = useStore(s => s.setTabObject)
  const setActiveImage = useStore(s => s.setActiveImage)

  const [localTabIndex, set] = useState(0)

  useEffect(() => {
    set(tabObj.second)
  }, [tabObj.second])

  const handleClick = (e, v) => {
    set(v.to)
    setTimeout(() => {
      setActiveImage(null)
      setTabObject({ first: tabObj.first, second: v.to })
    }, 200)
  }

  return (
    <Holder>
      {tabs[tabObj.first] &&
        tabs[tabObj.first].map((n, index) => (
          <Tab
            key={`${index}- ${n.title}`}
            style={{ color: n.to === localTabIndex ? "#b48465" : "white" }}
            onClick={e => handleClick(e, n)}>
            {n.title}
          </Tab>
        ))}
    </Holder>
  )
}

const Holder = styled.div`
  position: fixed;
  top: 1.5em;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  padding: 0.5em;
  width: 26em;
  display: flex;
  height: 50px;
  z-index: 999;
`

const Tab = styled.div`
  flex: 1;
  padding: 0.7em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
`

//
