import { useStore } from "state/store"
import styled from "styled-components/macro"
import { useLocation, useNavigate } from "react-router-dom"
import BookmarkIcon from "components/Icons/BookmarkIcon"
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/web"

export default function BookmarkButton() {
  const navigate = useNavigate()
  const location = useLocation()
  const handleBookmark = () => {
    location.pathname === "/favourites" ? navigate("/residences") : navigate("/favourites")
  }
  const fullScreenViews = useStore(s => s.fullScreenViews)

  const { opacity } = useSpring({
    opacity: fullScreenViews ? 0 : 1,
  })

  if (location.pathname === "/") {
    return null
  }

  return (
    <Holder style={{ opacity: opacity }} onClick={handleBookmark}>
      <BookmarkIcon color={"#b48465"} />
    </Holder>
  )
}

const Holder = styled(a.div)`
  position: absolute;
  background-color: #000000;
  top: 0;
  right: 1em;
  z-index: 6;
  cursor: pointer;
`
